import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FAQs } from '../faqs/faqs'
import { Location } from '@angular/common'

@Component({
	selector: 'app-faq-detail',
	templateUrl: './faq-detail.component.html',
	styleUrls: ['./faq-detail.component.scss'],
})
export class FaqDetailComponent implements OnInit, OnDestroy {
	index: number
	private sub: any
	public faq = { question: '', answer: [], list: false }

	constructor(private route: ActivatedRoute, private location: Location) {}

	ngOnInit() {
		this.sub = this.route.params.subscribe((params) => {
			this.index = +params['index']
		})
		this.faq = FAQs[this.index]
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

	goBack() {
		this.location.back()
	}
}
