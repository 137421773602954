import { HogarComponent } from './pages/hogar/hogar.component'
import { ModalAyudaIdComponent } from './pages/modal-ayuda-id/modal-ayuda-id.component'
import { ModalIdComponent } from './pages/modal-id/modal-id.component'
import { ModalFotoComponent } from './pages/modal-foto/modal-foto.component'
import { MainComponent } from './pages/main/main.component'
import { ActividadesComponent } from './pages/actividades/actividades.component'
import { SigExperienciaComponent } from './pages/sig-experiencia/sig-experiencia.component'
import { ExperienciaComponent } from './pages/experiencia/experiencia.component'
import { PerfilComponent } from './pages/perfil/perfil.component'
import { VerificarCelComponent } from './pages/verificar-cel/verificar-cel.component'

import { CelularComponent } from './pages/celular/celular.component'
import { RegistroComponent } from './pages/registro/registro.component'

import { MaterialModule } from 'src/app/material.module'
import { TabsPage } from './pages/home/tabs.page'
import { Tab1Page } from './pages/home/tab1/tab1.page'
import { IonicModule } from '@ionic/angular'
import { ScrollingCardsComponent } from './components/scrolling-cards/scrolling-cards'
import { OpcionesComponent } from './components/opciones/opciones.component'
import { HeaderComponent } from './components/header/header'
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component'
import { NgModule } from '@angular/core'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { TabsComponent } from './components/tabs/tabs'
import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ContainerComponent } from './components/container/container'
import { ContainerAppComponent } from './components/container-app/container-app'
import { AdDirective } from './directives/ad.directive'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { InputFloatComponent } from './components/input-float/input-float'
import { ItemListAvatarComponent } from './components/item-list-avatar/item-list-avatar'
import { FabButton } from './components/fab-button/fab-button'
import { MapsComponent } from './components/maps/maps.component'
import { InputegasystemsComponent } from './components/input-egasystems/input-egasystems'
import { ButtonegasystemsComponent } from './components/button-egasystems/button-egasystems'
import { BuscaServcioComponent } from './pages/busca-servcio/busca-servcio.component'
import { LoginMainComponent } from './pages/login-main/login-main.component'
import { ConfigCrmComponent } from './pages/config-crm/config-crm.component'
import { CrmConfiguracionComponent } from './pages/crm-configuracion/crm-configuracion.component'
import { CrmPerfilesComponent } from './pages/crm-perfiles/crm-perfiles.component'
import { CrmEstadosComponent } from './pages/crm-estados/crm-estados.component'
import { CrmHogaresComponent } from './pages/crm-hogares/crm-hogares.component'
import { CrmMunicipioComponent } from './pages/crm-municipio/crm-municipio.component'
import { CrmUsuariosComponent } from './pages/crm-usuarios/crm-usuarios.component'
import { MenuComponent } from './components/menu/menu.component'
import { BackHeaderComponent } from './components/back-header/back-header.component'
import { FaqsComponent } from './pages/faqs/faqs.component'

import { FaqDetailComponent } from './pages/faq-detail/faq-detail.component'
import { HelpComponent } from './pages/help/help.component'
import { ChangePasswordComponent } from './pages/change-password/change-password.component'
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component'
import { SavedComponent } from './pages/saved/saved.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { MessagesComponent } from './pages/messages/messages.component'
import { ChatComponent } from './pages/chat/chat.component'

import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { ProfileMasterComponent } from './pages/profile/master/master.component'
import { ChatProfileComponent } from './pages/chat-profile/chat-profile.component'
import { PaymentComponent } from './pages/payment/payment.component'
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component'
import { UnblockComponent } from './components/unblock/unblock.component'
import { ModalUsuariosComponent } from './pages/modal-usuarios/modal-usuarios.component'
import { CrmPaquetesComponent } from './pages/crm-paquetes/crm-paquetes.component'
import { AdminChatsComponent } from './pages/admin-chats/admin-chats.component'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    AdminChatsComponent,
    ModalUsuariosComponent,
    UnblockComponent,
    PaymentComponent,
    ForgotPasswordComponent,
    ChatProfileComponent,
    ProfileMasterComponent,
    ChatComponent,
    MessagesComponent,
    ProfileComponent,
    SavedComponent,
    SubscriptionsComponent,
    ChangePasswordComponent,
    HelpComponent,
    FaqsComponent,
    FaqDetailComponent,
    FaqsComponent,
    CrmUsuariosComponent,
    CrmMunicipioComponent,
    CrmHogaresComponent,
    CrmEstadosComponent,
    CrmPerfilesComponent,
    CrmConfiguracionComponent,
    ConfigCrmComponent,
    HogarComponent,
    ModalAyudaIdComponent,
    ModalIdComponent,
    ModalFotoComponent,
    MainComponent,
    ActividadesComponent,
    SigExperienciaComponent,
    ExperienciaComponent,
    PerfilComponent,
    VerificarCelComponent,
    CrmPaquetesComponent,
    CelularComponent,
    RegistroComponent,
    LoginMainComponent,
    BuscaServcioComponent,
    SpinnerOverlayComponent,
    SpinnerComponent,
    TabsComponent,
    TabsPage,
    Tab1Page,
    HeaderComponent,
    ContainerComponent,
    ContainerAppComponent,
    OpcionesComponent,
    ScrollingCardsComponent,
    AdDirective,
    InputFloatComponent,
    ItemListAvatarComponent,
    FabButton,
    MapsComponent,
    InputegasystemsComponent,
    ButtonegasystemsComponent,
    MenuComponent,
    BackHeaderComponent,
  ],
  exports: [
    ModalUsuariosComponent,
    PaymentComponent,
    FaqsComponent,
    CrmUsuariosComponent,
    CrmMunicipioComponent,
    CrmHogaresComponent,
    CrmEstadosComponent,
    CrmPerfilesComponent,
    CrmConfiguracionComponent,
    ConfigCrmComponent,
    HogarComponent,
    ModalAyudaIdComponent,
    ModalIdComponent,
    ModalFotoComponent,
    MainComponent,
    ActividadesComponent,
    SigExperienciaComponent,
    ExperienciaComponent,
    PerfilComponent,
    VerificarCelComponent,
    CrmPaquetesComponent,
    CelularComponent,
    RegistroComponent,
    LoginMainComponent,
    BuscaServcioComponent,
    SpinnerOverlayComponent,
    SpinnerComponent,
    TabsComponent,
    TabsPage,
    Tab1Page,
    HeaderComponent,
    ContainerComponent,
    ContainerAppComponent,
    OpcionesComponent,
    ScrollingCardsComponent,
    AdDirective,

    InputFloatComponent,
    ItemListAvatarComponent,
    FabButton,
    MapsComponent,
    InputegasystemsComponent,
    ButtonegasystemsComponent,
    MenuComponent,
    BackHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    MaterialModule,
    CKEditorModule,
    NgxSliderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class ComponentsModule {}
