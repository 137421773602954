export const FAQs = [
  {
    question: '¿CÓMO ENCUENTRO UN EMPLEO EN LA CIUDAD QUE QUIERO TRABAJAR?',
    answer: [
      `Cuando realizas tu registro en la app, en la sección de tu perfil, seleccionas el "Lugar donde quisieras trabajar", donde escoges el Estado y el Municipio de tu elección.`,
      `Después, las personas de dicha ciudad verán tu perfil y podrás ser seleccionado.`,
      `De igual manera, tu puedes seleccionar a la persona con la que quisieras trabajar, pagando tu membresía y mandándole mensaje directamente.`,
    ],

    list: false,
  },
  {
    question: '¿CÓMO PUEDO CONTRATAR A UNA EMPLEADA DOMESTICA?',
    answer: [
      `En el filtro agregas todas las características o habilidades para encontrar un perfil apto a tus necesidades. Todos los perfiles están verificados por nuestro equipo.`,
      `Se te mostrarán todos los perfiles relacionados a tu búsqueda, podrás ver su información y en caso de que te agrade puedes iniciar una conversación adquiriendo un paquete.`,
      `El tiempo en el que puedas tener acceso a los perfiles, dependerá del tipo de paquete adquirido.`,
    ],
    list: false,
  },
  {
    question: '¿QUÉ MÉTODOS DE PAGO ACEPTAN?',
    answer: ['Tarjeta de Crédito/Débito', 'Apple Pay (IOS)'],
    list: true,
  },
  {
    question: '¿CÓMO BLOQUEO A UN CONTACTO?',
    answer: [
      'Si ves que la persona con la que contactaste te hace sentir incomodo o crees que es una identidad falsa, tienes la opción de eliminar perfil, bloquear y reportar, para que nuestra app vuelva a ser un lugar seguro para ti.',
      'Para bloquear/reportar a al contacto basta con ir a su chat, darle click al icono de bote de basura que esta en la parte superior derecha y hacer click en bloquear/reportar.',
    ],
    list: false,
  },
  {
    question: '¿CÓMO PUEDO EDITAR MI INFORMACIÓN?',
    answer: [
      'Para agregar una nueva característica o corregir tu perfil:',
      'Ve al menú en la opción editar perfil, ahí podras actulizar todo la información que desees.',
    ],
    list: false,
  },
  {
    question: '¿CUÁLES SON LOS PAQUETES QUE MANEJAN?',
    answer: [
      {
        title: 'Paquete Light:',
        children: ['1 Semana (20 Perfiles)'],
      },
      {
        title: 'Paquete Gold:',
        children: ['2 Semanas (30 Perfiles)'],
      },
      {
        title: 'Paquete Platinum:',
        children: ['3 Semanas (40 Perfiles)'],
      },
      'Con la contratación de cualquiera de estos paquetes tendrás acceso a los perfiles verificados por nuestro equipo dentro de la aplicación, en el tiempo que dire el paquete. En caso de requerirse se enviaran por correo electrónico los perfiles seleccionados.',
    ],
    list: false,
  },
  {
    question: '¿LOS PERFILES ESTÁN VERIFICADOS?',
    answer: [
      'Todos los perfiles tiene un proceso de registro minucioso, y directamente para poder registrarse tienen que hacer uso de un teléfono celular particular, verificando de esta manera que sean perfiles verídicos.',
      'De igual manera, los perfiles son revisados por nuestros sarrolladores constantemente, buscando brindar el mejor servicio para nuestros usuarios.',
    ],
    list: false,
  },
  {
    question: '¿CÓMO ENCONTRAR TRABAJO SIN PAGAR?',
    answer: [
      `Puedes hacer el registro, completar tu perfil y esperar a que alguien vea tu perfil para iniciar la conversación`,
    ],
    list: false,
  },
]
