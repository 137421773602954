import { HogarComponent } from './pages/hogar/hogar.component'
import { MainComponent } from './pages/main/main.component'
import { ActividadesComponent } from './pages/actividades/actividades.component'
import { SigExperienciaComponent } from './pages/sig-experiencia/sig-experiencia.component'
import { ExperienciaComponent } from './pages/experiencia/experiencia.component'
import { PerfilComponent } from './pages/perfil/perfil.component'
import { VerificarCelComponent } from './pages/verificar-cel/verificar-cel.component'
import { CelularComponent } from './pages/celular/celular.component'
import { RegistroComponent } from './pages/registro/registro.component'
import { LoginMainComponent } from './pages/login-main/login-main.component'
import { BuscaServcioComponent } from './pages/busca-servcio/busca-servcio.component'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { Tab1Page } from './pages/home/tab1/tab1.page'
import { TabsPage } from './pages/home/tabs.page'
import { ConfigCrmComponent } from './pages/config-crm/config-crm.component'
import { CrmConfiguracionComponent } from './pages/crm-configuracion/crm-configuracion.component'
import { CrmPerfilesComponent } from './pages/crm-perfiles/crm-perfiles.component'
import { CrmEstadosComponent } from './pages/crm-estados/crm-estados.component'
import { CrmHogaresComponent } from './pages/crm-hogares/crm-hogares.component'
import { CrmMunicipioComponent } from './pages/crm-municipio/crm-municipio.component'
import { CrmUsuariosComponent } from './pages/crm-usuarios/crm-usuarios.component'
import { AuthGuard } from './guards/auth.guard'
import { SettingsComponent } from './pages/settings/settings.component'
import { InformationComponent } from './pages/information/information.component'
import { NotificationsComponent } from './pages/notifications/notifications.component'
import { FaqsComponent } from './pages/faqs/faqs.component'
import { FaqDetailComponent } from './pages/faq-detail/faq-detail.component'
import { ContactComponent } from './pages/contact/contact.component'
import { HelpComponent } from './pages/help/help.component'
import { ChangePasswordComponent } from './pages/change-password/change-password.component'
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component'
import { SavedComponent } from './pages/saved/saved.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { MessagesComponent } from './pages/messages/messages.component'
import { NoPlanChatComponent } from './pages/no-plan-chat/no-plan-chat.component'
import { ChatComponent } from './pages/chat/chat.component'
import { AdminChatsComponent } from './pages/admin-chats/admin-chats.component'
import { ChatProfileComponent } from './pages/chat-profile/chat-profile.component'
import { ProfileMasterComponent } from './pages/profile/master/master.component'
import { PaymentComponent } from './pages/payment/payment.component'
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component'
import { CrmPaquetesComponent } from './pages/crm-paquetes/crm-paquetes.component'
const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'BuscarServicio',
    component: BuscaServcioComponent,
  },
  {
    path: 'LoginMain',
    component: LoginMainComponent,
  },
  {
    path: 'Registro',
    component: RegistroComponent,
  },
  {
    path: 'Celular',
    component: CelularComponent,
  },
  {
    path: 'Verificar-Celular',
    component: VerificarCelComponent,
  },
  {
    path: 'Perfil',
    component: PerfilComponent,
  },
  {
    path: 'Experiencia',
    component: ExperienciaComponent,
  },
  {
    path: 'SigExperiencia',
    component: SigExperienciaComponent,
  },
  {
    path: 'Actividades',
    component: ActividadesComponent,
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Hogar',
    component: HogarComponent,
  },
  {
    path: 'ConfigCrm',
    component: ConfigCrmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AdminChats',
    component: AdminChatsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ConfiguracionCrm',
    component: CrmConfiguracionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CrmPaquetes',
    component: CrmPaquetesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'PerfilCrm',
    component: CrmPerfilesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'EstadosCrm',
    component: CrmEstadosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'HogaresCrm',
    component: CrmHogaresComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'MunicipiosCrm',
    component: CrmMunicipioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UsuariosCrm/:index',
    component: CrmUsuariosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'information',
    component: InformationComponent,
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },
  {
    path: 'FAQs',
    component: FaqsComponent,
  },
  {
    path: 'FAQ-Answer/:index',
    component: FaqDetailComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'subscription',
    component: SubscriptionsComponent,
  },
  {
    path: 'payments/:plan',
    component: PaymentComponent,
  },
  {
    path: 'saves',
    component: SavedComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'profile-m',
    component: ProfileMasterComponent,
  },
  {
    path: 'messages',
    component: MessagesComponent,
  },
  {
    path: 'no-plan-chat',
    component: NoPlanChatComponent,
  },
  {
    path: 'chat/:id_chat',
    component: ChatComponent,
  },
  {
    path: 'chat-profile/:user_id',
    component: ChatProfileComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'inicio',
    component: TabsPage,
    children: [
      {
        path: 'tab1',
        component: Tab1Page,
      },
    ],
  },
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
