import { Location } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { AlertService } from './../../services/alert.service'
import { UtilService } from 'src/app/services/util.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { ChatService } from 'src/app/services/chat.service'
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  public sendMessage = false
  public b64Image: any = ''
  public message: any = ''
  constructor(
    private router: Router,
    private location: Location,
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilService: UtilService,
    private sqlGenericService: SqlGenericService,
    private chatService: ChatService
  ) {}

  ngOnInit() {}

  nextStep() {
    this.sendMessage = true
  }

  goMain() {
    this.router.navigate(['/main'])
  }
  goBack() {
    this.router.navigate(['/main'])
  }

  async createChat() {
    if (!this.message) {
      return
    }

    const userId = localStorage.getItem('id_Usuario')

    const query = `
		SELECT * FROM usuarios U
		JOIN perfil P ON U.id = P.id_usuario
		WHERE U.id = ${userId}
		`
    const [user] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(query, 'getUser')
        .toPromise()) as any
    )?.parameters

    console.log(user)

    const checkQuery = `
    SELECT * FROM chats
    WHERE (id_usuario_1 = ${userId} AND id_usuario_2 = 11111)
    `
    this.sqlGenericService
      .excecuteQueryStringReference(checkQuery, 'checkChat')
      .subscribe(async (res: any) => {
        if (res.parameters.length > 0) {
          this.chatService.sendMessage(
            res.parameters[0].id,
            userId,
            this.message,
            user.url_foto,
            0
          )
          return this.router.navigate(['chat', 11111])
        }
        const createQuery = `
        INSERT INTO chats
        (id_usuario_1, id_usuario_2) VALUES (${userId}, 11111)
        `
        await this.sqlGenericService
          .excecuteQueryStringReference(createQuery, 'createChat')
          .toPromise()

        const getChatQuery = `
        SELECT * FROM chats
        WHERE (id_usuario_1 = ${userId} AND id_usuario_2 = 11111)
        `
        const [chat] = (
          (await this.sqlGenericService
            .excecuteQueryStringReference(getChatQuery, 'getChat')
            .toPromise()) as any
        )?.parameters

        this.chatService.sendMessage(
          chat.id,
          userId,
          this.message,
          user.url_foto,
          0
        )

        this.router.navigate(['chat', 11111])
      })
  }

  async fileChange(evt) {
    let file: any = evt.target.files[0]
    if (file.size > 2000000) {
      this.alertService.warnAlert('Ooops!', '', () => {
        ////console.log("hola");
      })
    } else if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg' &&
      file.type != 'image/svg+xml'
    ) {
      this.alertService.warnAlert('Ooops!', '', () => {
        ////console.log("hola");
      })
    } else {
      let fileB64 = await this.utilService.toBase64(file)
      this.b64Image = fileB64
    }
  }

  async captureImage(isCamera: boolean = true) {
    // this.loading.show();
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
    })

    this.b64Image = 'data:image/jpeg;base64,' + image.base64String
    let requestFile: any = {
      b64: this.b64Image,
      id: ' this.id_perfil', //usuario de nueva creacion
      extension: 'png',
      table: 'perfil',
    }

    // this.genericService
    //   .sendPostRequest(
    //     `${environment.loadFileOnly}/${this.id_perfil}`,
    //     requestFile
    //   )
    //   .subscribe(

    //     (responseFile: any) => {

    //       this.render.inputFoto.value=responseFile.parameters;
    //       this.render.inputFoto.error=!!responseFile.parameters;
    //       console.log(this.render.inputFoto.value)
    //       this.loading.hide();
    //       this.changeDetectorRef.detectChanges();
    //     },
    //     (err: HttpErrorResponse) => {

    //     }
    //   );
  }
}
