import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'input-egasystems',
  templateUrl: './input-egasystems.html',
  styleUrls: ['./input-egasystems.scss'],
  
  //encapsulation: ViewEncapsulation.None
})
export class InputegasystemsComponent implements OnDestroy, OnInit {
  minDate: Date;
  maxDate: Date;
  @Input() extraClass: string;
  @Input() id: string;
  @Input() dato: string;
  @Input() inputType: string = "text";
  @Input() inputPlaceholder: string = "";
  @Input() inputMaxLength: number = 10;
  @Input() values: any[] = [];
  @Input() withAutocomplete: boolean = false;
  @Input() multipler: boolean = false;



  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Input() egasystemsModel: string = "";
  @Output() egasystemsModelChange = new EventEmitter<any>();
  @Output() multipleSelectionChange = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  



  public seleccion: any[] = [];
  public selected = -1;;

  public filteredOptions: Observable<any[]>;
  public myControl = new FormControl();
  private inputValue: any;
  public isArea: boolean = false;

  constructor(
  ) {
   
  }

  public ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
    if (this.withAutocomplete) {
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    }
  }


  public ngOnDestroy(): void {

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.values.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  /*checkbox change event*/
  onChange(event) {
    console.log("-----------------");
    let index: any = this.seleccion.findIndex(x => x.value === event.value);
    if (index != -1) {
      console.log(index);
      if (this.seleccion.length == 1) {
        this.seleccion = [];
      } else {
        this.seleccion = this.seleccion.slice(index, this.seleccion.length);
      }
    } else {
      this.seleccion.push(event);
    }
    console.log(this.seleccion);


    this.multipleSelectionChange.emit(this.seleccion);
  }
  
  Onclick(event){
    this.clickEvent.emit(event)
   console.log( event);

  }



}
