import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';

declare var $: any;
@Component({
  selector: 'app-crm-hogares',
  templateUrl: './crm-hogares.component.html',
  styleUrls: ['./crm-hogares.component.scss'],
})
export class CrmHogaresComponent implements OnInit {
  public create:boolean=true;
  public datos:any[];
  public hogarRender: any = 
  {
    inputTipoHogar: {
    required: true,
    placeholder: 'TipoHogar.....',
    value: null,
    error: false,
    type: 'text',
    length: 100
    },
    inputDescripcion: {
    required: true,
    placeholder: 'Descripcion.....',
    value: null,
    error: false,
    type: 'text',
    length: 100
    },
    inputId: {
    required: true,
    placeholder: 'Id.....',
    value: null,
    error: false,
    type: 'text',
    length: 100
    },
    
  

  };
  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,


  ) { 
    setTimeout(function () {
      $(function () {
        $('#dt').DataTable(
          {
            'bDestroy': true,

            "bInfo": true,
            "bProcessing": true,
            "bDeferRender": true,
            'iDisplayLength': 10,
            'sPaginationType': 'full_numbers',
            'sDom': '<"top"i> T<"clear">lfrtip',
            'sPageButtonActive': "paginate_active",
            'sPageButtonStaticDisabled': "paginate_button",
            "scrollY": "300px",
            "scrollCollapse": true,
          }
        );
      });
    }, 2000); 
  }

  ngOnInit() {

    this.sqlSelect();
  }
  back() {
    window.history.back();
  }
  sqlSelect(){
    let sqlFind: string = 
    `SELECT * FROM tipo_hogar `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`gettipo_hogares`).subscribe((rsp: any) => {
      this.datos=rsp.parameters
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
  }
  sqlInsert(){
    let sqlFind: string = 
    `INSERT INTO tipo_hogar 
    (name,description)
    VALUES (
    '${this.hogarRender.inputTipoHogar.value}',
    '${this.hogarRender.inputDescripcion.value}'
    )`;
    
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`createtipo_hogar`).subscribe((rsp: any) => {
      this.hogarRender.inputTipoHogar.value='';   
      this.hogarRender.inputDescripcion.value='';
      this.hogarRender.inputId.value='';
     
      this.sqlSelect();
    console.log(rsp.parameters[0].id );
    },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    })
  }

  sqlUpdate(){
    let sqlFind: string = 
    `UPDATE  tipo_hogar SET 
    name= '${this.hogarRender.inputTipoHogar.value}', 
    description= '${this.hogarRender.inputDescripcion.value}' 
    WHERE id = '${this.hogarRender.inputId.value}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`updatetipo_hogar`).subscribe((rsp: any) => {
      this.sqlSelect();
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
  }


  sqlDelete(id){
    let sqlFind: string = 
    `DELETE from tipo_hogar WHERE id = '${id}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`Deleteestados`).subscribe((rsp: any) => {
   // this.datos=rsp.parameters
   //console.log(this.datos)
   this.hogarRender.inputTipoHogar.value='';   
      this.hogarRender.inputDescripcion.value='';
      this.hogarRender.inputId.value='';
   this.sqlSelect();
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
  
  
  }


  cambioestatus(event){
  
    this.create=false
    let sqlFind: string = 
    `SELECT * FROM tipo_hogar where id= ${event} `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getUsuarios`).subscribe((rsp: any) => {
      //console.log(rsp.parameters[0]);
      this.hogarRender.inputTipoHogar.value=rsp.parameters[0].name;
      this.hogarRender.inputDescripcion.value=rsp.parameters[0].description;
      this.hogarRender.inputId.value=rsp.parameters[0].id;
      
      
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
    
    
    }
    update(){

      if(this.create==false){
        this.sqlUpdate();
      }else{
        this.sqlInsert();
    
      }
    
      
    
    }

}
