import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-crm-perfiles',
  templateUrl: './crm-perfiles.component.html',
  styleUrls: ['./crm-perfiles.component.scss'],
})
export class CrmPerfilesComponent implements OnInit {

  constructor() {
    setTimeout(function () {
      $(function () {
        $('#dt').DataTable(
          {
            'bDestroy': true,

            "bInfo": true,
            "bProcessing": true,
            "bDeferRender": true,
            'iDisplayLength': 10,
            'sPaginationType': 'full_numbers',
            'sDom': '<"top"i> T<"clear">lfrtip',
            'sPageButtonActive': "paginate_active",
            'sPageButtonStaticDisabled': "paginate_button",
            "scrollY": "300px",
            "scrollCollapse": true,
          }
        );
      });
    }, 100); 
   }

  ngOnInit() {}
  back() {
    window.history.back();
  }
}
