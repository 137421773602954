import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Options } from "@angular-slider/ngx-slider";
import { SqlGenericService } from "src/app/services/sqlGenericService";
import { LoaderService } from "src/app/services/loading-service";
import { AlertService } from "src/app/services/alert.service";
interface SliderOptions {
  value: number;
  highValue: number;
  options: Options;
}

type State = {
  id: string;
  name: string;
  description: string;
};

type Municiple = {
  id: string;
  name: string;
  id_estados: string;
  description: string;
};

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  public isMaid: boolean = false
  public total: number;
  public people: any[];
  public estado: string = "";
  public name: any;
  public b64: any;
  @Input() userData: any = {};
  @Input() imgUrl: string = "";
  @Output() setResults = new EventEmitter<Array<any>>();

  public serviceType: string = "";

  ngOnInit() {
    const id = localStorage.getItem("id_Usuario");
    this.serviceType = localStorage.getItem("Servicio");
    this.userNumber = Number(id).toString().padStart(5, "0");
    this.isMaid = localStorage.getItem('Servicio') === 'maid'
    if (this.serviceType === "maid") {
      this.getStatesAndMuniciples().then((res) => {
        this.states = res.states;
        this.municiples = res.municiples;
      });
      this.getHomeTypes().then((res) => {
        this.homeTypes = res;
      });
    }

    let sqlFind: string = `SELECT * FROM perfil where id_usuario=${id} `;
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getStateperson`)
      .subscribe((rsp: any) => {
        this.estado = rsp.parameters[0].estado;
      });
    //this.getPeople();
  }

  public salaryOptions: SliderOptions = {
    value: 0,
    highValue: 10000,
    options: {
      showTicks: true,
      floor: 0,
      ceil: 10000,
      step: 100,
      translate: (value: number): string => {
        return new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value);
      },
    },
  };

  public states: State[] = [];
  public municiples: Municiple[] = [];
  public availableMuniciples: Municiple[] = [];
  public selectedState: number | string = 0;
  public selectedMuniciple: number | string = 0;

  public userNumber: string = "";

  public ageOptions: SliderOptions = {
    value: 18,
    highValue: +65,
    options: {
      floor: 18,
      ceil: 65,
      step: 1,
      showTicks: true,
    },
  };

  public isMenuOpen: boolean = false;
  public isFiltersOpen: boolean = false;

  public results: [] = [];
  public homeTypes: any[] = [];

  public filters = {
    experience: false,
    reference: false,
    anotherCity: false,
    onlyInMyState: false,
    frecuence: false,
    homeType: {
      house: false,
      apartment: false,
      countryEstate: false,
    },
  };

  constructor(
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private loading: LoaderService,
    private alertService: AlertService
  ) {}

  toggleMenu() {
    this.loading.show();
    this.isMenuOpen = !this.isMenuOpen;
    let sqlFind: string = `SELECT * FROM perfil where id_usuario=${localStorage.getItem(
      "id_Usuario"
    )} `;
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `gettablamenu`)
      .subscribe((rsp: any) => {
        this.b64 = rsp.parameters[0].url_foto;
        this.name = rsp.parameters[0].nombre + " " + rsp.parameters[0].apellido;
        this.loading.hide();
      });
  }

  toggleFilters() {
    this.isFiltersOpen = !this.isFiltersOpen;
  }

  goSettings() {
    this.router.navigate(["/settings"]);
  }
  goSubscriptions() {
    this.router.navigate(["/subscription"]);
  }
  goSaves() {
    this.router.navigate(["/saves"]);
  }
  goProfile() {
    if (this.serviceType === "maid") {
      this.router.navigate(["/profile"]);
    } else {
      this.router.navigate(["/profile-m"]);
    }
    this.isMenuOpen = false;
  }
  goMessages() {
    this.router.navigate(["/messages"]);
  }
  removeSession() {
    localStorage.removeItem("userSession");
    this.router.navigate(["/", "BuscarServicio"]);
  }
  sqlDelete() {
    this.alertService.confirmTrashAlert(
      () => {
        let sqlFind: string = `DELETE from usuarios WHERE id = '${localStorage.getItem(
          "id_Usuario"
        )}'`;
        this.sqlGenericService
          .excecuteQueryStringReference(sqlFind, `DeleteUser`)
          .subscribe((rsp: any) => {
            localStorage.removeItem("userSession");
            this.router.navigate(["/", "BuscarServicio"]);
          });
      },
      "Eliminar Usuario",
      "Seguro que quiere eliminar el usuario",
      "Aceptar"
    );
  }

  async getStatesAndMuniciples() {
    const states = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM estados ORDER BY description ASC`,
        `getStates`
      )
      .toPromise();

    const municiples = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM municipios ORDER BY name ASC`,
        `getMuniciples`
      )
      .toPromise();

    return {
      states: (states as any)?.parameters,
      municiples: (municiples as any)?.parameters,
    };
  }

  async onStateChange(stateId: string | number) {
    this.availableMuniciples = this.municiples.filter(
      (municiple) => municiple.id_estados === stateId
    );
  }

  async getHomeTypes() {
    const homeTypes = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM tipo_hogar ORDER BY name ASC`,
        `getHomeTypes`
      )
      .toPromise();

    return (homeTypes as any)?.parameters;
  }

  calculateAge(_birthdate: string): number {
    const today = new Date()
    const birthDate = new Date(_birthdate)
    return today.getFullYear() - birthDate.getFullYear()
  }

  async clearFilters() {
    this.filters = {
      experience: false,
      reference: false,
      anotherCity: false,
      onlyInMyState: false,
      frecuence: false,
      homeType: {
        house: false,
        apartment: false,
        countryEstate: false,
      },
    }
    this.selectedState = 0
    this.selectedMuniciple = 0
    this.salaryOptions = {
      value: 0,
      highValue: 10000,
      options: {
        showTicks: true,
        floor: 0,
        ceil: 10000,
        step: 100,
        translate: (value: number): string => {
          return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value)
        },
      },
    }
    this.ageOptions = {
      value: 18,
      highValue: +65,
      options: {
        floor: 18,
        ceil: 65,
        step: 1,
        showTicks: true,
      },
    }

    const userId = localStorage.getItem('id_Usuario')

    const query: string = this.isMaid
      ? `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', P.ninos as 'ninos',P.adultos as 'adultos',
      O.otra as 'another_city',P.mascotas as 'mascotas',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.dias as 'dias',
      E.name as 'state', M.name as 'municiple',
      TH.name as 'tipo_hogar',H.habitaciones as 'habitaciones',
      H.banos as 'banos',H.jardin as 'jardin',H.terraza as 'terraza',
      P.fecha_nacimiento as 'birthdate',P.conexion as'conexion', P.url_foto as 'imageURL'
			
      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN hogar H ON U.id = H.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
      JOIN tipo_hogar TH ON TH.id = H.tipo_hogar
      WHERE U.tipo_usuario = 2
      `
      : `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', O.otra as 'another_city',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.L as 'L', O.Ma as 'Ma', O.Mi as 'Mi', O.J as 'J',
      O.V as 'V', O.S as 'S', O.D as 'D',Ex.experiencia_anos as 'experience',
      Ex.referencias as 'reference', E.name as 'state',
      M.name as 'municiple', TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) as 'birthdate',
			P.url_foto as 'imageURL', A.limpieza as 'cleaning',P.conexion as'conexion',
      A.laba as 'washing', A.cocinar as 'cooking',
      A.ninos as 'children', A.adultos as 'adults'
      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN experiencia Ex ON U.id = Ex.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
			JOIN actividades A ON A.id_usuario = P.id_usuario
      WHERE U.tipo_usuario = 1
      `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getPeople')
      .subscribe(async (res: any) => {
        const savesQuery = `
        SELECT * FROM guardados
        WHERE id_usuario = ${userId}
        `

        const savesRes = (await this.sqlGenericService
          .excecuteQueryStringReference(savesQuery, 'getSaves')
          .toPromise()) as any

        this.setResults.emit(res.parameters)

        res.parameters.map((person) => {
          let days = ''

          if (Number(person.L)) days += 'Lu '
          if (Number(person.Ma)) days += 'Ma '
          if (Number(person.Mi)) days += 'Mi '
          if (Number(person.J)) days += 'Ju '
          if (Number(person.V)) days += 'Vi '
          if (Number(person.S)) days += 'Sa '
          if (Number(person.D)) days += 'Do '
          days = days.trim()
          //console.log(days)

          return {
            ...person,
            age: this.calculateAge(person.birthdate),
            days,
            saved: savesRes.parameters.some(
              (save) => save.id_guardado === person.user_id
            ),
          }
        })
      })
  }

  filter() {
    if (this.serviceType === "master") {
      const query: string = `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', O.otra as 'another_city',P.conexion as 'conexion',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.L as 'L', O.Ma as 'Ma', O.Mi as 'Mi', O.J as 'J',  O.dias,
      O.V as 'V', O.S as 'S', O.D as 'D',Ex.experiencia_anos as 'experience',
      Ex.referencias as 'reference', E.name as 'state',
      M.name as 'municiple', TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) as 'birthdate',
			P.url_foto as 'imageURL', A.limpieza as 'cleaning',
      A.laba as 'washing', A.cocinar as 'cooking',
      A.ninos as 'children', A.adultos as 'adults'

      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN experiencia Ex ON U.id = Ex.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
			JOIN actividades A ON A.id_usuario = P.id_usuario
      
      WHERE O.salario BETWEEN ${this.salaryOptions.value} AND ${
        this.salaryOptions.highValue
      }
      AND TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) BETWEEN ${
        this.ageOptions.value
      } AND ${this.ageOptions.highValue}
      ${this.filters.experience ? "AND Ex.experiencia_anos >= 1" : ""}
      ${this.filters.reference ? "AND Ex.referencias = 1" : ""}
      ${this.filters.anotherCity ? "AND O.otra = 1" : ""}
      ${this.filters.onlyInMyState ? `AND P.estado = ${this.estado}` : ""}
      AND O.frecuencia = ${this.filters.frecuence ? 1 : 0}
      AND U.tipo_usuario = 1
      `;
      console.log(query);
      this.sqlGenericService
        .excecuteQueryStringReference(query, "getTabla")
        .subscribe((res: any) => {
          console.log(res.parameters);
          this.setResults.emit(res.parameters);
        });
    } else {
      const query: string = `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', P.ninos as 'ninos',P.adultos as 'adultos',P.conexion as 'conexion',
      O.otra as 'another_city',P.mascotas as 'mascotas',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.dias as 'dias',
      E.name as 'state', M.name as 'municiple',
      TH.name as 'tipo_hogar',H.habitaciones as 'habitaciones',
      H.banos as 'banos',H.jardin as 'jardin',H.terraza as 'terraza',
      P.fecha_nacimiento as 'birthdate', P.url_foto as 'imageURL'
			
      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN hogar H ON U.id = H.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
      JOIN tipo_hogar TH ON TH.id = H.tipo_hogar


      WHERE U.tipo_usuario = 2
      AND O.salario BETWEEN ${this.salaryOptions.value}
      AND ${this.salaryOptions.highValue}
      AND O.frecuencia = ${this.filters.frecuence ? 1 : 0}
      ${this.selectedState !== 0 ? `AND E.id = ${this.selectedState}` : ""}
      ${
        this.selectedMuniciple !== 0
          ? `AND M.id = ${this.selectedMuniciple}`
          : ""
      }
     
      ${
        this.filters.homeType.house &&
        !this.filters.homeType.apartment &&
        !this.filters.homeType.countryEstate
          ? " AND H.tipo_hogar = 11"
          : ""
      }
      ${
        this.filters.homeType.apartment &&
        !this.filters.homeType.house &&
        !this.filters.homeType.countryEstate
          ? "AND H.tipo_hogar = 1"
          : ""
      }
      ${
        this.filters.homeType.countryEstate &&
        !this.filters.homeType.apartment &&
        !this.filters.homeType.house
          ? "AND H.tipo_hogar = 13"
          : ""
      }
      ${
        this.filters.homeType.house &&
        this.filters.homeType.apartment &&
        !this.filters.homeType.countryEstate
          ? " AND H.tipo_hogar = 11 OR H.tipo_hogar = 1"
          : ""
      }
      ${
        this.filters.homeType.house &&
        this.filters.homeType.apartment &&
        this.filters.homeType.countryEstate
          ? " AND H.tipo_hogar = 11 OR H.tipo_hogar = 1 OR H.tipo_hogar = 13"
          : ""
      }
      ${
        !this.filters.homeType.house &&
        this.filters.homeType.apartment &&
        this.filters.homeType.countryEstate
          ? " AND H.tipo_hogar = 13 OR H.tipo_hogar = 1"
          : ""
      }
      ${
        this.filters.homeType.house &&
        !this.filters.homeType.apartment &&
        this.filters.homeType.countryEstate
          ? " AND H.tipo_hogar = 13 OR H.tipo_hogar = 11"
          : ""
      }
      
      `;
      console.log(this.selectedState);
      console.log(query);
      this.sqlGenericService
        .excecuteQueryStringReference(query, "getTabla")
        .subscribe((res: any) => {
          console.log(res.parameters);
          this.setResults.emit(res.parameters);
        });
    }
  }
}
