import { Component, OnInit } from '@angular/core'
import { ModalController, NavParams } from '@ionic/angular'
import { ReportProfileComponent } from '../report-profile/report-profile.component'
import { BlockProfileComponent } from '../block-profile/block-profile.component'
import { AlertService } from 'src/app/services/alert.service'
import { Router } from '@angular/router'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.scss'],
})
export class DeleteProfileComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private navParams: NavParams,
    private sqlGenericService: SqlGenericService,
    private router: Router
  ) {}

  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss()
  }
  async openReportModal() {
    this.modalCtrl.dismiss()
    const modalConfig = {
      component: ReportProfileComponent,
      backdropDismiss: false,
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }
  async openBlockModal() {
    this.modalCtrl.dismiss()
    const modalConfig = {
      component: BlockProfileComponent,
      backdropDismiss: false,
      componentProps: {
        userId: this.navParams.get('userId'),
      }
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }
  async deleteProfile() {
    const chatId = this.navParams.get('chatId')
    const query = `DELETE FROM chats WHERE id = ${chatId}`

    await this.sqlGenericService
      .excecuteQueryStringReference(query, 'deleteChat')
      .toPromise()

    this.modalCtrl.dismiss()
    this.router.navigate(['/main'])
    this.alertService.successAlert(
      'Perfil eliminado',
      'El perfil ha sido eliminado correctamente'
    )
  }
}
