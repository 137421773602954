import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SqlGenericService} from '../../services/sqlGenericService';
import { AlertService } from "./../../services/alert.service";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { LoaderService } from 'src/app/services/loading-service';
import { twilio } from 'src/environments/environment.prod'
@Component({
  selector: 'app-verificar-cel',
  templateUrl: './verificar-cel.component.html',
  styleUrls: ['./verificar-cel.component.scss'],
})
export class VerificarCelComponent implements OnInit {
 public numero:any
 public dataRender: any = {

  inputCel1: {
  required: true,
  placeholder: 'Cel1.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },

  inputCel2: {
  required: true,
  placeholder: 'Cel2.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },

  inputCel3: {
  required: true,
  placeholder: 'Cel3.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },

  inputCel4: {
  required: true,
  placeholder: 'Cel4.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },

 };
 
  constructor(
    private loading:LoaderService,
    private alertService: AlertService,
    private router: Router,
    private sqlGenericService: SqlGenericService
  ) { }

  ngOnInit() {

    let sqlFind: string = 
    `SELECT * FROM perfil WHERE id_usuario = '${localStorage.getItem('id_Usuario')}'`;
     this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getperfil`).subscribe((rsp: any) => {
    
    
    this.numero=rsp.parameters[0].celular ;
    console.log(this.numero)
    },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );



  }
  goInicio() {
   
   this.send()
   //
    
  }

  back() {
    window.history.back();
  }
validacion(){

}

send(){
  
  let verificar
  verificar=this.dataRender.inputCel1.value+this.dataRender.inputCel2.value+this.dataRender.inputCel3.value+this.dataRender.inputCel4.value
    if(localStorage.getItem('codigoSms')==verificar){
      this.router.navigateByUrl('/Perfil');
    }
    else{
      this.alertService.errorAlert('Codigo Ingresado',"No es Correcto");
    }
    ;
}


async recodigoSms(){
this.loading.show();
  const res =  await (
    await  fetch(
      'https://api.twilio.com/2010-04-01/Accounts/'+twilio.TWILIO_AUTH_TOKEN+'/Messages.json',
      {
        method: 'POST',
        headers: {
          Authorization:
            'Basic ' +
            btoa(
              twilio.TWILIO_ACCOUNT_SID
            ),
        },
        body: new URLSearchParams({
          MessagingServiceSid: twilio.MessagingServiceSid,
        'To': '+52'+this.numero ,
       // 'From': '+17816507846',
        'Body': 'Mensaje Sms Muchachapp tu Numero de Registro es: '+localStorage.getItem('codigoSms')
    })
    
})).json();
console.log(res.status)
if(res.status='accepted'){
  this.alertService.successAlert('SMS','Mensaje Enviado')
  this.loading.hide();
}
}

regresar(){
  this.router.navigateByUrl('/Celular');
}

moveToNext(event) {
   
   let a = document.getElementById("1");
   let b = document.getElementById("2");
   let c = document.getElementById("3");
   let d = document.getElementById("4");
   const  controls=[]
   //console.log(event.target.id)
   let selected = document.getElementById(event.target.id)
   
   let next 
   if(selected===a){
   next=b
 //  console.log(next)
   if (this.dataRender.inputCel1.value!=null) 
   next.focus(); 
   }
   if(selected===b){
     next=c
   //  console.log(next)  
     next.focus();
     }

     if(selected===c){
       next=d
     //  console.log(next)  
       next.focus();
       }
       if(event.key==="Backspace"){
        //console.log("Evento Correcto",event.key)
        this.dataRender.inputCel1.value=null
        this.dataRender.inputCel2.value=null
        this.dataRender.inputCel3.value=null
        this.dataRender.inputCel4.value=null
        let a = document.getElementById("1");
        a.focus();
      }

       


}

backSms(event){
  let d = document.getElementById("4");
  console.log(event);
  let selected = document.getElementById(event.target.id)
  if(event.key==="Backspace"){
    //console.log("Evento Correcto",event.key)
    this.dataRender.inputCel1.value=null
    this.dataRender.inputCel2.value=null
    this.dataRender.inputCel3.value=null
    this.dataRender.inputCel4.value=null
    let a = document.getElementById("1");
    a.focus();
  }
  if(selected===d){
    this.send();}
  }

  onChange(field: string, value: string) {
    this[field] = value
   
    if (field === 'inputCel1') {
      this.dataRender.inputCel1.error = !!value
    }
    if (field === 'inputCel2') {
      this.dataRender.inputCel2.error = !!value
    }
    if (field === 'inputCel3') {
      this.dataRender.inputCel3.error = !!value
    }
    if (field === 'inputCel4') {
      this.dataRender.inputCel4.error = !!value
    }
  }

}



