import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SqlGenericService } from '../../services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import { twilio } from 'src/environments/environment.prod'
@Component({
  selector: 'app-celular',
  templateUrl: './celular.component.html',
  styleUrls: ['./celular.component.scss'],
})
export class CelularComponent implements OnInit {
  public numero: any
  public updateData: boolean = false
  public data: any = {
    telefono: {
      error: false,
      value: '',
    },
  }
  constructor(
    private alertService: AlertService,
    private router: Router,
    private sqlGenericService: SqlGenericService
  ) {}

  ngOnInit() {}
  goInicio() {
    this.guardarTelefono()
  }
  back() {
    window.history.back()
  }

  guardarTelefono() {
    var val = Math.floor(1000 + Math.random() * 9000)
    console.log(val)

    let Servicio
    let sqlInsert: string = `INSERT INTO perfil (id_usuario,celular,verificacion) VALUES (
      ' ${localStorage.getItem('id_Usuario')}',
      '${this.data.telefono.value}',
      '${val}')`

    this.sqlGenericService
      .excecuteQueryStringReference(sqlInsert, `InsertTelefono`)
      .subscribe(
        (rsp22: any) => {
          //Verificacion Sms

          let sqlFind: string = `SELECT * FROM perfil WHERE id_usuario = '${localStorage.getItem(
            'id_Usuario'
          )}'`
          this.sqlGenericService
            .excecuteQueryStringReference(sqlFind, `CodigoSms`)
            .subscribe(
              (rsp: any) => {
                localStorage.setItem('codigoSmsUpdate', 'true')
                localStorage.setItem(
                  'codigoSms',
                  rsp.parameters[0].verificacion
                )
                this.numero

                fetch(
                  'https://api.twilio.com/2010-04-01/Accounts/'+twilio.TWILIO_AUTH_TOKEN+'/Messages.json',
                  {
                    method: 'POST',
                    headers: {
                      Authorization:
                        'Basic ' +
                        btoa(
                          twilio.TWILIO_ACCOUNT_SID
                        ),
                    },
                    body: new URLSearchParams({
                      MessagingServiceSid: twilio.MessagingServiceSid,
                      To: '+52' + rsp.parameters[0].celular,
                      // 'From': '+17816507846',
                      Body:
                        'Mensaje Sms Muchachapp tu Numero de Registro es: ' +
                        localStorage.getItem('codigoSms'),
                    }),
                  }
                )
              },
              (error: HttpErrorResponse) => {
                if (error.status != 200) {
                  this.alertService.errorAlert(
                    'Opps....',
                    'Tenemos un Problema'
                  )
                }
              }
            )

          this.router.navigateByUrl('/Verificar-Celular')
        },
        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  updateTelefono() {
    var val = Math.floor(1000 + Math.random() * 9000)
    console.log(val)

    let Servicio
    let sqlInsert: string = `UPDATE  perfil SET 
    celular= '${this.data.telefono.value}'  
    WHERE id_Usuario = ' ${localStorage.getItem('id_Usuario')}'`

    this.sqlGenericService
      .excecuteQueryStringReference(sqlInsert, `InsertTelefono`)
      .subscribe(
        (rsp22: any) => {
          //Verificacion Sms

          let sqlFind: string = `SELECT * FROM perfil WHERE id_usuario = '${localStorage.getItem(
            'id_Usuario'
          )}'`
          this.sqlGenericService
            .excecuteQueryStringReference(sqlFind, `CodigoSms`)
            .subscribe(
              (rsp: any) => {
                localStorage.setItem(
                  'codigoSms',
                  rsp.parameters[0].verificacion
                )
                this.numero

                fetch(
                  'https://api.twilio.com/2010-04-01/Accounts/'+twilio.TWILIO_AUTH_TOKEN+'/Messages.json',
                  {
                    method: 'POST',
                    headers: {
                      Authorization:
                        'Basic ' +
                        btoa(
                          twilio.TWILIO_ACCOUNT_SID
                        ),
                    },
                    body: new URLSearchParams({
                      MessagingServiceSid: twilio.MessagingServiceSid,
                      To: '+52' + rsp.parameters[0].celular,
                      // 'From': '+17816507846',
                      Body:
                        'Mensaje Sms Muchachapp tu Numero de Registro es: ' +
                        localStorage.getItem('codigoSms'),
                    }),
                  }
                )
              },
              (error: HttpErrorResponse) => {
                if (error.status != 200) {
                  this.alertService.errorAlert(
                    'Opps....',
                    'Tenemos un Problema'
                  )
                }
              }
            )

          this.router.navigateByUrl('/Verificar-Celular')
        },
        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  actualizardatos() {
    if (localStorage.getItem('codigoSmsUpdate') == 'true') {
      this.updateData = true
      //console.log(this.updateData)
    }

    if (this.validarDatos()) {
      if (this.updateData) {
        this.updateTelefono()
        localStorage.setItem('celular', this.data.telefono.value)
      } else {
        this.guardarTelefono()
        localStorage.setItem('celular', this.data.telefono.value)
      }
    }
  }

  validarDatos() {
    let isValid = true
    // console.log(this.dataRender[1].fieldset[1].defaultValue)

    if (this.data.telefono.value == '') {
      isValid = false
      this.alertService.successAlert('SMS', 'Campos Requeridos Sin llenar')
    }
    console.log(isValid)

    return isValid
  }

  onChange(field: string, value: string) {
    this[field] = value

    if (field === 'cel') {
      this.data.telefono.error = !!value
    }
  }
}
