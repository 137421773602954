import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from './../../services/alert.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public status: boolean

  constructor(
    private location: Location,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getNotificatioStatus()
  }

  getNotificatioStatus() {
    const userId = localStorage.getItem('id_Usuario')
    const query = `
      SELECT notificar FROM perfil WHERE id_usuario = ${userId}
    `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getNotificatioStatus')
      .subscribe((res: any) => {
        this.status = !!Number(res.parameters[0].notificar || 0)
      })
  }

  changeStatus(value: boolean) {
    const userId = localStorage.getItem('id_Usuario')
    const query = `
      UPDATE perfil SET notificar = ${Number(
        value
      )} WHERE id_usuario = ${userId}
    `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'changeStatus')
      .subscribe((res: any) => {
        this.alertService.successAlert(
          'Configuración actualizada',
          'Se ha actualizado la configuración de notificaciones'
        )

        this.getNotificatioStatus()
      })
  }

  goBack() {
    this.location.back()
  }
}
