import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
@Component({
  selector: 'app-experiencia',
  templateUrl: './experiencia.component.html',
  styleUrls: ['./experiencia.component.scss'],
})
export class ExperienciaComponent implements OnInit {
  public suma:any={
    total:0,
    validar:false
  }
  public datos_estados:any[];
 public datos_municipios:any[];
  public render: any = {
    inputExpanos: {
    required: true,
    placeholder: 'Expanos.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputLimpieza: {
    required: true,
    placeholder: 'Limpieza.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    
    inputLaba: {
      required: true,
      placeholder: 'Laba.....',
      value:0,
      error: false,
      type: 'text',
      length: 100
      },
      inputCocinar: {
      required: true,
      placeholder: 'Cocinar.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100
      },
      inputNinos: {
      required: true,
      placeholder: 'Ninos.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100
      },
      inputAdultos: {
      required: true,
      placeholder: 'Adultos.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100
      },
      inputEstado: {
      required: true,
      placeholder: 'Estado.....',
      value: 'Mi Estado',
      error: false,
      type: 'text',
      length: 100
      },
      inputMunicipio: {
      required: true,
      placeholder: 'Municipio.....',
      value: 'Mi Municipio',
      error: false,
      type: 'text',
      length: 100
      },
      inputReferencias: {
      required: true,
      placeholder: 'Referencias.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100
      },
      
      
      


  };
  constructor(
    
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.sqlSelectEstados();
    
  }
  goInicio() {
    if(this.validarDatos()){
   this.sql();
    this.router.navigateByUrl('/SigExperiencia');
  }
  }

  back() {
    window.history.back();
  }
  datos(event:any,ngmodel){

    if(event.target.checked==true && ngmodel==1){
     this.render.inputLimpieza.value=1
     this.suma.total = this.suma.total+1
    // console.log("Limpieza:",this.render.inputLimpieza.value);
    }
    if(event.target.checked==false && ngmodel==1){
     this.render.inputLimpieza.value=0
     this.suma.total = this.suma.total-1
    // console.log("Limpieza:",this.render.inputLimpieza.value);
    }
 
 
    if(event.target.checked==true && ngmodel==2){
     this.render.inputLaba.value=1
     this.suma.total = this.suma.total+1
    // console.log("Laba:",this.render.inputLaba.value);
    }
    if(event.target.checked==false && ngmodel==2){
     this.render.inputLaba.value=0
     this.suma.total = this.suma.total-1
   //  console.log("Laba:",this.render.inputLaba.value);
    }



    if(event.target.checked==true && ngmodel==3){
      this.render.inputCocinar.value=1
      this.suma.total = this.suma.total+1
     // console.log("Cocinar:",this.render.inputCocinar.value);
     }
     if(event.target.checked==false && ngmodel==3){
      this.render.inputCocinar.value=0
      this.suma.total = this.suma.total-1
     // console.log("Cocinar:",this.render.inputCocinar.value);
     }


     if(event.target.checked==true && ngmodel==4){
      this.render.inputNinos.value=1
      this.suma.total = this.suma.total+1
      //console.log("Ninos:",this.render.inputNinos.value);
     }
     if(event.target.checked==false && ngmodel==4){
      this.render.inputNinos.value=0
      this.suma.total = this.suma.total-1
      //console.log("Ninos:",this.render.inputNinos.value);
     }

     if(event.target.checked==true && ngmodel==5){
      this.render.inputAdultos.value=1
      this.suma.total = this.suma.total+1
     // console.log("Adultos:",this.render.inputAdultos.value);
     }
     if(event.target.checked==false && ngmodel==5){
      this.render.inputAdultos.value=0
      this.suma.total = this.suma.total-1
      //console.log("Adultos:",this.render.inputAdultos.value);
     }


     if(event.target.checked==true && ngmodel==6){
      this.render.inputReferencias.value=1
      console.log("Ref:",this.render.inputReferencias.value);
     }
     if(event.target.checked==false && ngmodel==6){
      this.render.inputReferencias.value=0
      console.log("Ref:",this.render.inputReferencias.value);
     }
     console.log(this.suma.total)
     console.log(event.target.checked,ngmodel,this.suma.total)
     
      
      if(this.suma.total>1&&this.suma.total!=0)
      this.suma.validar=!!this.suma.total


      if(this.suma.total<=1&&this.suma.total!=0)
      this.suma.validar=!this.suma.total
     
   }
  
addNumeros(ngmodel){
  
    if(ngmodel=== 1){
      this.render.inputExpanos.value=this.render.inputExpanos.value+1;
      this.render.inputExpanos.error=true
      }
      
  }
  removeNumeros(ngmodel){
    if(this.render.inputExpanos.value!=0){
    console.log(this.render.inputExpanos.value)
    this.render.inputExpanos.error=false}
    if(this.render.inputExpanos.value>0 && ngmodel==1){
      this.render.inputExpanos.value=this.render.inputExpanos.value-1;
      }
    
  }


  sql(){

let sqlFind: string = 
`INSERT INTO experiencia 
(experiencia_anos,limpieza,laba,cocinar,ninos,adultos,estado,municipio,referencias,id_usuario)
VALUES (
'${ this.render.inputExpanos.value}',
'${ this.render.inputLimpieza.value}',
'${ this.render.inputLaba.value}',
'${ this.render.inputCocinar.value}',
'${ this.render.inputNinos.value}',
'${ this.render.inputAdultos.value}',
'${ this.render.inputEstado.value}',
'${ this.render.inputMunicipio.value}',
'${ this.render.inputReferencias.value}',
'${ localStorage.getItem('id_Usuario')}'
)`;

this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getexperiencia`).subscribe((rsp: any) => {


console.log(rsp.parameters[0].id );
},

(error: HttpErrorResponse) => {
if (error.status != 200) {
this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

}
})

  }

  validarDatos(){

    let isValid=true
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
  
   if(this.render.inputExpanos.value==''|| this.render.inputEstado.value=='Mi Estado'
   || this.render.inputMunicipio.value=='Mi Municipio' ){
    isValid = false;
    this.alertService.successAlert('Datos','Por favor llena todos los campos')

   }
   console.log(isValid)
   console.log(this.render.inputExpanos.value)
   return isValid;
  }



  sqlSelectEstados(){
    let sqlFind: string = 
    `SELECT * FROM estados `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
    this.datos_estados=rsp.parameters
   console.log(this.datos_estados)
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
   
     }

     sqlSelectMunicipios(texto){
      let sqlFind: string = 
      ` SELECT
         
      M.id as 'id',
      M.name as 'name',
      M.description as 'description',
        
      E.name as 'estado'
    
     
     
      FROM municipios M
      INNER JOIN estados E
      ON E.id =M.id_estados
      WHERE E.id = ${texto}
      `;
      this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getmunicipios`).subscribe((rsp: any) => {
        this.datos_municipios=rsp.parameters
        console.log(this.datos_municipios)
      
        },
      
      (error: HttpErrorResponse) => {
      if (error.status != 200) {
      this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
      
      }
      }
      );
  
  
    }
    moveToNext(event:any) {
   
      
      //console.log(event.target.selectedIndex )
      let index= event.target.selectedIndex

      console.log(event.target.options[index].innerHTML);
      console.log(event.target.options[index].value);
    this.sqlSelectMunicipios(event.target.options[index].value);
   }


   onChange(field: string, value: string) {
    this[field] = value
   
    if (field === 'Mi Estado') {
      this.render.inputEstado.error = !!value
    }
    if (field === 'Mi Municipio') {
      this.render.inputMunicipio.error = !!value
    }
   
  }
}


