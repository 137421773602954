import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SqlGenericService } from "src/app/services/sqlGenericService";
import { AlertService } from "./../../services/alert.service";
import { ModalUsuariosComponent } from "./../modal-usuarios/modal-usuarios.component";
import {
  ActionSheetController,
  MenuController,
  ModalController,
} from "@ionic/angular";
import { UtilService } from "src/app/services/util.service";
declare var $: any;
declare var ExcelJS;
declare var saveAs;
@Component({
  selector: "app-crm-usuarios",
  templateUrl: "./crm-usuarios.component.html",
  styleUrls: ["./crm-usuarios.component.scss"],
})
export class CrmUsuariosComponent implements OnInit {
  public create: boolean = true;
  public datos: any[];
  public datos_tipoUsuarios: any[];
  public dato_usuario: any;
  public isMaid: boolean = false;
  public inputsUsuarios: any[];
  modelData: any;
  public usuariosRender: any = {
    inputEmail: {
      required: true,
      placeholder: "email.....",
      value: "test@mail.com",
      error: false,
      type: "text",
      length: 100,
    },
    inputContrasena: {
      required: true,
      placeholder: "contrasena.....",
      value: "123456",
      error: false,
      type: "text",
      length: 100,
    },
    inputTipo_usuario: {
      required: true,
      placeholder: "tipo_usuario.....",
      value: "Tipos de Usuarios",
      error: false,
      type: "text",
      length: 100,
    },

    inputId: {
      required: true,
      placeholder: "id.....",
      value: null,
      error: false,
      type: "text",
      length: 100,
    },
  };
  constructor(
    private sqlGenericService: SqlGenericService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const userId = params["index"];
      if (userId == "true") {
        this.isMaid = true;
      }
      //=userId;
    });
    setTimeout(() => {
      $(function () {
        $("#dt").DataTable({
          responsive: true,
          bDestroy: true,
          retrieve: true,
          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 50,
          scrollX: true,
          sPaginationType: "full_numbers",
          Dom: '<"top"i> T<"clear">bfrtip',
          PageButtonActive: "paginate_active",
          PageButtonStaticDisabled: "paginate_button",
          scrollY: "550px",
          scrollCollapse: true,
          stateSave: true,
        });
      });
    }, 5000);
    //  this.sqlSelect();
    this.sqlSelectUsuarios();
    this.sqlColumnas();
    // this.selectAllusuarios();
  }
  back() {
    window.history.back();
  }

  async OpenModal() {
    const modal = await this.modalCtrl.create({
      component: ModalUsuariosComponent,
      cssClass: "customModal",
      componentProps: {
        model_data: this.dato_usuario,
        model_inputs: this.inputsUsuarios,
      },
    });
    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        console.log("Modal Data : " + modelData.data);
      }
    });
    return await modal.present();
  }

  async sqlSelect() {
    let sqlFind: string = ` SELECT
			 
    U.id as 'Id_Usuario',
    U.email as 'email',
    U.contrasena as 'contrasena',
      
    T.tipo as 'tipo_usuario'
  
   
   
    FROM usuarios U 
    INNER JOIN tipos_usuarios T
    ON T.id = tipo_usuario`;
    let rsp: any = await this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getusuarios`)
      .toPromise();

    this.datos = rsp.parameters;
    // .subscribe((rsp: any) => {
    // this.datos=rsp.parameters
    // console.log(this.datos)

    //   },

    // (error: HttpErrorResponse) => {
    // if (error.status != 200) {
    // this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

    // }
    // }
    // );
  }

  async sqlSelectUsuarios() {
    let sqlFind: string = `SELECT * FROM  tipos_usuarios`;
    let rsp: any = await this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getTiposUsuarios`)
      .toPromise();
    this.datos_tipoUsuarios = rsp.parameters;
  }

  sqlInsert() {
    if (this.usuariosRender.inputTipo_usuario.value != "Tipos de Usuarios") {
      let sqlFind: string = `INSERT INTO usuarios 
    (email,contrasena,tipo_usuario)
    VALUES (
    '${this.usuariosRender.inputEmail.value}',
    SHA2(MD5(UNHEX(SHA2('${this.usuariosRender.inputContrasena.value}',512))),224),
    ${this.usuariosRender.inputTipo_usuario.value}
    )`;

      this.sqlGenericService
        .excecuteQueryStringReference(sqlFind, `createusuarios`)
        .subscribe(
          (rsp: any) => {
            console.log(rsp.parameters[0].id);
            this.sqlSelect();
          },

          (error: HttpErrorResponse) => {
            if (error.status != 200) {
              this.alertService.errorAlert("Opps....", "Tenemos un Problema");
            }
          }
        );
    } else {
      this.alertService.errorAlert(
        "Opps....",
        "Tenemos un Problema Seleciona el Tipo de Usuario"
      );
    }
  }

  sqlDelete(id) {
    let sqlFind: string = `DELETE from usuarios WHERE id = '${id}'`;
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `Deleteestados`)
      .subscribe(
        (rsp: any) => {
          // this.datos=rsp.parameters
          //console.log(this.datos)
          this.selectAllusuarios();
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert("Opps....", "Tenemos un Problema");
          }
        }
      );
  }

  sqlUpdate() {
    let sqlFind: string = `UPDATE  usuarios SET 
    email = '${this.usuariosRender.inputEmail.value}', 
    contrasena= SHA2(MD5(UNHEX(SHA2('${this.usuariosRender.inputContrasena.value}',512))),224),
    tipo_usuario= ${this.usuariosRender.inputTipo_usuario.value}
   
    WHERE id = ' ${this.usuariosRender.inputId.value}'`;
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `updateusuarios`)
      .subscribe(
        (rsp: any) => {
          this.usuariosRender.inputEmail.value = "";
          this.usuariosRender.inputContrasena.value = "";
          this.usuariosRender.inputId.value = "";
          this.create = true;
          (
            document.getElementById(
              rsp.parameters[0].tipo_usuario
            ) as HTMLInputElement
          ).value = rsp.parameters[0].tipo_usuario;
          this.usuariosRender.inputTipo_usuario.value =
            rsp.parameters[0].tipo_usuario;
          this.sqlSelect();
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert("Opps....", "Tenemos un Problema");
          }
        }
      );
  }

  update(a?: boolean) {
    this.router.navigate(["/UsuariosCrm/true"]);
    // if(this.create==false){
    //   this.sqlUpdate();
    // }else{
    //   this.sqlInsert();

    // }
  }

  async cambioestatus(event) {
    this.create = false;
    let sqlFind: string = `SELECT * FROM usuarios where id= ${event} `;

    const query: string = this.isMaid
      ? `
    SELECT
    U.id as 'perfil.id_usuario',U.email as 'usuarios.email',U.contrasena 'usuarios.contrasena',U.tipo_usuario 'usuarios.tipo_usuario', P.nombre as 'perfil.nombre',
    P.apellido as 'perfil.apellido', P.ninos as 'perfil.ninos',P.adultos as 'perfil.adultos',
    O.otra as 'oferta_laboral.otra',P.mascotas as 'perfil.mascotas',
    O.salario as 'oferta_laboral.salario', 
    O.L as 'oferta_laboral.L', O.Ma as 'oferta_laboral.Ma', O.Mi as 'oferta_laboral.Mi', O.J as 'oferta_laboral.J',
    O.V as 'oferta_laboral.V', O.S as 'oferta_laboral.S', O.D as 'oferta_laboral.D',
    E.name as 'perfil.estado', M.name as 'perfil.municipio',
    TH.name as 'hogar.tipo_hogar',H.habitaciones as 'hogar.habitaciones',
    H.banos as 'hogar.banos',H.jardin as 'hogar.jardin',H.terraza as 'hogar.terraza',
    P.conexion as'perfil.conexion', P.url_foto as 'perfil.url_foto', P.sexo as 'perfil.sexo', P.estado_civil as 'perfil.estado_civil'
    , P.con_ninos as 'perfil.con_ninos', P.fecha_inicial as 'perfil.fecha_inicial'
    FROM usuarios U
    JOIN perfil P ON U.id = P.id_usuario
    JOIN hogar H ON U.id = H.id_usuario
    JOIN oferta_laboral O ON U.id = O.id_usuario
    JOIN estados E ON E.id = P.estado
    JOIN municipios M ON M.id = P.municipio
    JOIN tipo_hogar TH ON TH.id = H.tipo_hogar
  
  
    WHERE U.tipo_usuario = 2 and  U.id= ${event}
    `
      : `
    SELECT
    U.id as 'perfil.id_usuario',U.email as 'usuarios.email',U.contrasena 'usuarios.contrasena',U.tipo_usuario 'usuarios.tipo_usuario', P.nombre as 'perfil.nombre',
    P.apellido as 'perfil.apellido', O.otra as 'oferta_laboral.otra',
    O.salario as 'oferta_laboral.salario', O.frecuencia as 'oferta_laboral.frecuencia',
    O.L as 'oferta_laboral.L', O.Ma as 'oferta_laboral.Ma', O.Mi as 'oferta_laboral.Mi', O.J as 'oferta_laboral.J',
    O.V as 'oferta_laboral.V', O.S as 'oferta_laboral.S', O.D as 'oferta_laboral.D',Ex.experiencia_anos as 'experiencia.experiencia_anos',
    Ex.referencias as 'experiencia.referencias', E.name as 'perfil.estado',Ex.limpieza 'experiencia.limpieza',Ex.laba 'experiencia.laba',
    Ex.cocinar 'experiencia.cocinar',Ex.ninos 'experiencia.ninos',Ex.adultos 'experiencia.adultos',M.name as 'perfil.municipio', TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) as 'perfil.fecha_nacimiento',
    P.url_foto as 'perfil.url_foto', A.limpieza as 'actividades.limpieza',P.conexion as'perfil.conexion',
    P.identificacion as 'perfil.identificacion',A.laba as 'actividades.laba', A.cocinar as 'actividades.cocinar', P.sexo as 'perfil.sexo', P.estado_civil as 'perfil.estado_civil',
    A.ninos as 'actividades.ninos', A.adultos as 'actividades.adultos', P.fecha_inicial as 'perfil.fecha_inicial'
  
    FROM usuarios U
    JOIN perfil P ON U.id = P.id_usuario
    JOIN oferta_laboral O ON U.id = O.id_usuario
    JOIN experiencia Ex ON U.id = Ex.id_usuario
    JOIN estados E ON E.id = P.estado
    JOIN municipios M ON M.id = P.municipio
    JOIN actividades A ON A.id_usuario = P.id_usuario
    WHERE U.tipo_usuario = 1 and  U.id= ${event}
    `;

    let rsp: any = await this.sqlGenericService
      .excecuteQueryStringReference(query, `getUsuarios`)
      .toPromise();
    this.dato_usuario = rsp.parameters;
    setTimeout(() => {
      this.OpenModal();
    }, 100);

    // .subscribe((rsp: any) => {
    //

    //   //console.log(rsp.parameters[0]);
    //   // this.usuariosRender.inputEmail.value=rsp.parameters[0].email;
    //   // this.usuariosRender.inputContrasena.value=rsp.parameters[0].contrasena;
    //   // this.usuariosRender.inputId.value=rsp.parameters[0].id;
    //   // (document.getElementById(rsp.parameters[0].tipo_usuario) as HTMLInputElement).value=rsp.parameters[0].tipo_usuario
    //   // this.usuariosRender.inputTipo_usuario.value=rsp.parameters[0].tipo_usuario
    //   //console.log(rsp.parameters[0].tipo_usuario)

    //   },

    // (error: HttpErrorResponse) => {
    // if (error.status != 200) {
    // this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

    // }
    // }
    // );
  }

  async sqlColumnas() {
    const columnas = [];
    let sqlFind: string = this.isMaid
      ? `SELECT table_name as tabla ,COLUMN_NAME as Field
      FROM INFORMATION_SCHEMA.COLUMNS 
      WHERE table_name = 'usuarios' and COLUMN_NAME <> 'id'
      OR table_name = 'perfil' and COLUMN_NAME <> 'id'  and COLUMN_NAME <> 'verificacion' and COLUMN_NAME <> 'bool_verificado'
      and COLUMN_NAME <> 'notificar'  and COLUMN_NAME <> 'test' and COLUMN_NAME <> 'notificar_token' and COLUMN_NAME <> 'fecha_nacimiento' and COLUMN_NAME <> 'identificacion' 
      OR table_name = 'hogar' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'id_usuario'
      OR table_name = 'oferta_laboral' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'id_usuario'and COLUMN_NAME <> 'frecuencia' and COLUMN_NAME <> 'dias'
      ;`
      : `SELECT table_name as tabla ,COLUMN_NAME as Field
     FROM INFORMATION_SCHEMA.COLUMNS 
     WHERE table_name = 'usuarios' and COLUMN_NAME <> 'id'
     OR table_name = 'perfil' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'verificacion' and COLUMN_NAME <> 'bool_verificado'
     and COLUMN_NAME <> 'notificar'  and COLUMN_NAME <> 'test' and COLUMN_NAME <> 'notificar_token' and COLUMN_NAME <> 'ninos' and COLUMN_NAME <> 'mascotas'and COLUMN_NAME <> 'adultos'and COLUMN_NAME <> 'con_ninos'
     OR table_name = 'oferta_laboral' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'id_usuario'and COLUMN_NAME <> 'dias'
     OR table_name = 'experiencia' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'id_usuario' and COLUMN_NAME <> 'estado'and COLUMN_NAME <> 'municipio'
     OR table_name = 'actividades' and COLUMN_NAME <> 'id' and COLUMN_NAME <> 'id_usuario'
     ;`;

    let rsp: any = await this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getColumnasCatalog`)
      .toPromise();

    // .subscribe(
    //   (rsp: any) => {

    rsp.parameters.map((item) => {
      //  console.log(item.Field)

      const myInputsDatos = {
        id: item.Field,
        formControl: item.tabla + "." + item.Field,
        placeholder: item.tabla + "." + item.Field,
        tabla: item.tabla,
        required: true,
        defaultValue: "",
        type: "text",
        withError: false,
        dato: [],
      };
      columnas.push(myInputsDatos);
    });

    this.inputsUsuarios = columnas;
    console.log(this.inputsUsuarios)
    //   });
    this.selectAllusuarios();
  }

  async selectAllusuarios() {
    const query: string = this.isMaid
      ? `
  SELECT
  U.id as 'perfil.id_usuario',U.email as 'usuarios.email',U.contrasena 'usuarios.contrasena',U.tipo_usuario 'usuarios.tipo_usuario', P.nombre as 'perfil.nombre',
  P.apellido as 'perfil.apellido', P.ninos as 'perfil.ninos',P.adultos as 'perfil.adultos',
  O.otra as 'oferta_laboral.otra',P.mascotas as 'perfil.mascotas',
  O.salario as 'oferta_laboral.salario', 
  O.L as 'oferta_laboral.L', O.Ma as 'oferta_laboral.Ma', O.Mi as 'oferta_laboral.Mi', O.J as 'oferta_laboral.J',
  O.V as 'oferta_laboral.V', O.S as 'oferta_laboral.S', O.D as 'oferta_laboral.D',
  E.name as 'perfil.estado', M.name as 'perfil.municipio',
  TH.name as 'hogar.tipo_hogar',H.habitaciones as 'hogar.habitaciones',
  H.banos as 'hogar.banos',H.jardin as 'hogar.jardin',H.terraza as 'hogar.terraza',P.celular as'perfil.celular',
  P.conexion as'perfil.conexion', P.url_foto as 'perfil.url_foto', P.sexo as 'perfil.sexo', P.estado_civil as 'perfil.estado_civil'
  , P.con_ninos as 'perfil.con_ninos', P.fecha_inicial as 'perfil.fecha_inicial'
  FROM usuarios U
  JOIN perfil P ON U.id = P.id_usuario
  JOIN hogar H ON U.id = H.id_usuario
  JOIN oferta_laboral O ON U.id = O.id_usuario
  JOIN estados E ON E.id = P.estado
  JOIN municipios M ON M.id = P.municipio
  JOIN tipo_hogar TH ON TH.id = H.tipo_hogar


  WHERE U.tipo_usuario = 2
  `
      : `
  SELECT
  U.id as 'perfil.id_usuario',U.email as 'usuarios.email',U.contrasena 'usuarios.contrasena',U.tipo_usuario 'usuarios.tipo_usuario', P.nombre as 'perfil.nombre',
  P.apellido as 'perfil.apellido', O.otra as 'oferta_laboral.otra',
  O.salario as 'oferta_laboral.salario', O.frecuencia as 'oferta_laboral.frecuencia',
  O.L as 'oferta_laboral.L', O.Ma as 'oferta_laboral.Ma', O.Mi as 'oferta_laboral.Mi', O.J as 'oferta_laboral.J',
  O.V as 'oferta_laboral.V', O.S as 'oferta_laboral.S', O.D as 'oferta_laboral.D',Ex.experiencia_anos as 'experiencia.experiencia_anos',
  Ex.referencias as 'experiencia.referencias', E.name as 'perfil.estado',Ex.limpieza 'experiencia.limpieza',Ex.laba 'experiencia.laba',
  Ex.cocinar 'experiencia.cocinar',Ex.ninos 'experiencia.ninos',Ex.adultos 'experiencia.adultos',M.name as 'perfil.municipio', TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) as 'perfil.fecha_nacimiento',
  P.url_foto as 'perfil.url_foto', A.limpieza as 'actividades.limpieza',P.conexion as'perfil.conexion',P.celular as'perfil.celular',
  P.identificacion as 'perfil.identificacion',A.laba as 'actividades.laba', A.cocinar as 'actividades.cocinar', P.sexo as 'perfil.sexo', P.estado_civil as 'perfil.estado_civil',
  A.ninos as 'actividades.ninos', A.adultos as 'actividades.adultos', P.fecha_inicial as 'perfil.fecha_inicial'

  FROM usuarios U
  JOIN perfil P ON U.id = P.id_usuario
  JOIN oferta_laboral O ON U.id = O.id_usuario
  JOIN experiencia Ex ON U.id = Ex.id_usuario
  JOIN estados E ON E.id = P.estado
  JOIN municipios M ON M.id = P.municipio
  JOIN actividades A ON A.id_usuario = P.id_usuario
  WHERE U.tipo_usuario = 1
  `;

    let rsp: any = await this.sqlGenericService
      .excecuteQueryStringReference(query, `getselectAllusuarios`)
      .toPromise();

    console.log(this.inputsUsuarios)
    this.datos = rsp.parameters;

   
  }
  ExportMaid(){
    let wb = new ExcelJS.Workbook();
    let workbookName = "temp.xlsx";
    let worksheetName = "Paquetes";
    let ws = wb.addWorksheet(worksheetName, {
      properties: {
        tabColor: { argb: "FFFF0000" },
      },
    });
    ws.columns = [
      { header: "perfil.id_usuario", key: "v", width: 25 },
      { header: "usuarios.email", key: "CLIENTE", width: 50 },
      { header: "usuarios.contrasena", key: "SERVICIO", width: 65, outlineLevel: 1 },
      { header: "usuarios.tipo_usuario", key: "PLAN", width: 25, outlineLevel: 1 },
      { header: "perfil.nombre ", key: "COMISION", width: 23, outlineLevel: 1 },
      { header: "perfil.apellido ", key: "COMISION", width: 23, outlineLevel: 1 },
      { header: "oferta_laboral.otra", key: "v", width: 20 },
      { header: "oferta_laboral.salario", key: "CLIENTE", width: 20 },
      { header: "oferta_laboral.frecuencia", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.L", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.Ma ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.Mi", key: "v", width: 20 },
      { header: "oferta_laboral.J", key: "CLIENTE", width: 20 },
      { header: "oferta_laboral.V", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.S", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.D ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "experiencia_anos", key: "v", width: 20 },
      { header: "experiencia.referencias", key: "CLIENTE", width: 20 },
      { header: "perfil.estado", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "experiencia.limpieza", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "experiencia.laba ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "experiencia.cocinar", key: "CLIENTE", width: 20 },
      { header: "experiencia.ninos", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "experiencia.adultos", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "perfil.municipio ", key: "COMISION", width: 24, outlineLevel: 1 },
      { header: "perfil.fecha_nacimiento", key: "v", width: 25 },
      { header: "perfil.url_foto", key: "CLIENTE", width: 100 },
      { header: "actividades.limpieza", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "perfil.conexion", key: "PLAN", width: 80, outlineLevel: 1 },
      { header: "perfil.identificacion ", key: "COMISION", width: 80, outlineLevel: 1 },
      { header: "actividades.laba", key: "v", width: 20 },
      { header: "actividades.cocinar", key: "CLIENTE", width: 20 },
      { header: "perfil.sexo", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "perfil.estado_civil", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "actividades.ninos ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "actividades.adultos ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "perfil.fecha_inicial", key: "v", width: 25 },
   
    ];
    let arrayCell = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];
    arrayCell.forEach((element, index) => {
      //  this.inputsUsuarios.forEach((col: any, indexData) => {
      this.datos.forEach((itm: any, indexData) => {
        switch (element) {
          case "A":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.id_usuario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "B":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.email";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "C":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.contrasena";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "D":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.tipo_usuario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "E":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.nombre";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "F":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.apellido";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "G":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.otra";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "H":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.salario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "I":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.frecuencia";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "J":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.L";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "K":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.Ma";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "L":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.Mi";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "M":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.J";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "N":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.V";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "O":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.S";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "P":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.D";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Q":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.experiencia_anos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "R":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.referencias";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "S":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.estado";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "T":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.limpieza";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "U":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.laba";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "V":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.cocinar";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "W":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.ninos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "X":
            ws.getCell(`${element}${indexData }`).value =
              "experiencia.adultos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Y":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.municipio";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "Z":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.fecha_nacimiento";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;

          case "AA":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.url_foto";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AB":
            ws.getCell(`${element}${indexData }`).value =
              "actividades.limpieza";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AC":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.conexion";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AD":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.identificacion";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AE":
            ws.getCell(`${element}${indexData }`).value =
              "actividades.laba";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "AF":
            ws.getCell(`${element}${indexData }`).value =
              "actividades.cocinar";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AG":
            ws.getCell(`${element}${indexData }`).value = "perfil.sexo";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AH":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.estado_civil";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AI":
            ws.getCell(`${element}${indexData }`).value =
              "actividades.ninos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AJ":
            ws.getCell(`${element}${indexData }`).value =
              "actividades.adultos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "AK":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.fecha_inicial";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
        }
      });
    });

    arrayCell.forEach((element, index) => {
      //  this.inputsUsuarios.forEach((col: any, indexData) => {
      this.datos.forEach((itm: any, indexData) => {
        switch (element) {
          case "A":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.id_usuario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "B":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.email"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "C":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.contrasena"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "D":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.tipo_usuario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "E":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.nombre"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "F":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.apellido"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "G":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.otra"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "H":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.salario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "I":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.frecuencia"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "J":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.L"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "K":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.Ma"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "L":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.Mi"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "M":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.J"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "N":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.V"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "O":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.S"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "P":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.D"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Q":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.experiencia_anos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "R":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.referencias"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "S":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.estado"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "T":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.limpieza"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "U":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.laba"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "V":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.cocinar"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "W":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.ninos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "X":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["experiencia.adultos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Y":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.municipio"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "Z":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.fecha_nacimiento"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;

          case "AA":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.url_foto"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AB":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["actividades.limpieza"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AC":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.conexion"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AD":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.identificacion"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AE":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["actividades.laba"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "AF":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["actividades.cocinar"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AG":
            ws.getCell(`${element}${indexData + 2}`).value = itm["perfil.sexo"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AH":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.estado_civil"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AI":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["actividades.ninos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AJ":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["actividades.adultos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "AK":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.fecha_inicial"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
        }
      });
    });
    console.log(ws);
    wb.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        workbookName
      );
    });

  }

  ExportMaster(){
    let wb = new ExcelJS.Workbook();
    let workbookName = "temp.xlsx";
    let worksheetName = "Paquetes";
    let ws = wb.addWorksheet(worksheetName, {
      properties: {
        tabColor: { argb: "FFFF0000" },
      },
    });
    ws.columns = [
      { header: "perfil.id_usuario", key: "v", width: 25 },
      { header: "usuarios.email", key: "CLIENTE", width: 50 },
      { header: "usuarios.contrasena", key: "SERVICIO", width: 65, outlineLevel: 1 },
      { header: "usuarios.tipo_usuario", key: "PLAN", width: 25, outlineLevel: 1 },
      { header: "perfil.nombre ", key: "COMISION", width: 23, outlineLevel: 1 },
      { header: "perfil.apellido ", key: "COMISION", width: 23, outlineLevel: 1 },
      { header: "oferta_laboral.otra", key: "v", width: 20 },
      { header: "oferta_laboral.salario", key: "CLIENTE", width: 20 },
      { header: "oferta_laboral.frecuencia", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.L", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.Ma ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.Mi", key: "v", width: 20 },
      { header: "oferta_laboral.J", key: "CLIENTE", width: 20 },
      { header: "oferta_laboral.V", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.S", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "oferta_laboral.D ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "experiencia_anos", key: "v", width: 20 },
      { header: "experiencia.referencias", key: "CLIENTE", width: 20 },
      { header: "perfil.estado", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "experiencia.limpieza", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "experiencia.laba ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "experiencia.cocinar", key: "CLIENTE", width: 20 },
      { header: "experiencia.ninos", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "experiencia.adultos", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "perfil.municipio ", key: "COMISION", width: 24, outlineLevel: 1 },
      { header: "perfil.fecha_nacimiento", key: "v", width: 80 },
      { header: "perfil.url_foto", key: "CLIENTE", width: 100 },
      { header: "actividades.limpieza", key: "SERVICIO", width: 20, outlineLevel: 1 },
      { header: "perfil.conexion", key: "PLAN", width: 20, outlineLevel: 1 },
      { header: "perfil.identificacion ", key: "COMISION", width: 20, outlineLevel: 1 },
      { header: "actividades.laba", key: "v", width: 20 },
     
    ];
    let arrayCell = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      
    ];
    arrayCell.forEach((element, index) => {
      //  this.inputsUsuarios.forEach((col: any, indexData) => {
      this.datos.forEach((itm: any, indexData) => {
        switch (element) {
          case "A":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.id_usuario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "B":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.email";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "C":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.contrasena";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "D":
            ws.getCell(`${element}${indexData }`).value =
              "usuarios.tipo_usuario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "E":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.nombre";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "F":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.apellido";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "G":
            ws.getCell(`${element}${indexData }`).value =
            "perfil.ninos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "H":
            ws.getCell(`${element}${indexData }`).value =
            "perfil.adultos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "I":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.otra";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "J":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.L";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "K":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.Ma";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "L":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.Mi";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "M":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.J";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "N":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.V";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "O":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.S";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "P":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.D";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Q":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.mascotas";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "R":
            ws.getCell(`${element}${indexData }`).value =
              "oferta_laboral.salario";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "S":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.estado";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "T":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.municipio";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "U":
            ws.getCell(`${element}${indexData }`).value =
              "hogar.tipo_hogar";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "V":
            ws.getCell(`${element}${indexData }`).value =
              "hogar.habitaciones";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "W":
            ws.getCell(`${element}${indexData }`).value =
              "hogar.banos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "X":
            ws.getCell(`${element}${indexData }`).value =
              "hogar.jardin";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Y":
            ws.getCell(`${element}${indexData }`).value =
              "hogar.terraza";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "Z":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.conexion";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;

          case "AA":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.url_foto";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AB":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.sexo";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AC":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.estado_civil";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AD":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.con_ninos";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AE":
            ws.getCell(`${element}${indexData }`).value =
              "perfil.fecha_inicial";
            ws.getCell(`${element}${indexData }`).style =
              this.utilService.excelJSCommonColumn();

            break;
         
        }
      });
    });

    arrayCell.forEach((element, index) => {
      //  this.inputsUsuarios.forEach((col: any, indexData) => {
      this.datos.forEach((itm: any, indexData) => {
        switch (element) {
          case "A":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.id_usuario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "B":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.email"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "C":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.contrasena"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "D":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["usuarios.tipo_usuario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "E":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.nombre"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "F":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.apellido"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "G":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.ninos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "H":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.adultos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "I":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.otra"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "J":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.L"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "K":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.Ma"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "L":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.Mi"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "M":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.J"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "N":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.V"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "O":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.S"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "P":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.D"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Q":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.mascotas"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "R":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["oferta_laboral.salario"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "S":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.estado"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "T":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.municipio"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "U":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["hogar.tipo_hogar"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "V":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["hogar.habitaciones"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "W":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["hogar.banos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "X":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["hogar.jardin"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "Y":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["hogar.terraza"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          case "Z":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.conexion"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;

          case "AA":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.url_foto"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AB":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.sexo"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AC":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.estado_civil"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AD":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.con_ninos"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case "AE":
            ws.getCell(`${element}${indexData + 2}`).value =
              itm["perfil.fecha_inicial"];
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();

            break;
          
          
        }
      });
    });
    console.log(ws);
    wb.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        workbookName
      );
    });

  }


}
