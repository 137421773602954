//export const path:string = "http://localhost/transfo-backend/educacion/";
//export const path:string = "http://localhost/api_educacion/educacion/";
export const path:string = "https://inventory-app.egasystems.com/api_ecommerce/ecommerce/";

export const pathSettlementsCity = "https://olam-systems.com.mx/api_suite_home/settlements/getMunicipios";

export const emulado = false;

export const idEmpresa = 188;

export const catalogoSabias = 149;
export const remastered = false;

export const environment = { 
  production: true,
  //Services path
  genericQuerie: `${path}petition`,
  fcm: `${path}fcm`,// push notifications
  mail: `${path}email`,
  loadBlob:`${path}load-blob`,
  load:`${path}load`,
  loadPDF:`${path}load-pdf`,
  getImagenIndividual: `${path}image/`,
  getPDF: `${path}pdf/`,
  loadBlobOnly:`${path}load-blob-only`,
  loadFile:`${path}load-file`,
  loadFileOnly:`${path}load-only-file`,
  st:
  {
/**DEV */
 //keyPublic: 'pk_test_51NI0jhH6xZRKwgWZKfQGghBaJ5THm73AZsiUQscNtqAal15RKyGsbDYFdhjmEGsb2fu54wWEkWnwK8J6bH3jZwus00sUUTTt5e',
/**DEV */
  //privateKey: "sk_test_51NI0jhH6xZRKwgWZ1ystWnCzSTe4IPpxFqKVLdLdNe8RzSO1gnhYNb7qkm74sY30wd74t2buoeq2TKQPxlVLND4e00MYlS459E",


/**PROD */
keyPublic: 'pk_live_51IOpHuF8PhJ1w2R8a69w03kh7tphAssS8xrFPtk1Hd2dixyNDA2p2u8Hsqhdirf0o64iuzg6GM1lId1pEfKumM7e003WubrX7L',
/**PROD */
privateKey: "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",
  }
};

export const firebaseConfig = {
  apiKey: "AIzaSyDPCiJ9zo74CldPPVOhbNolU2RMd02pL9M",
  authDomain: "muchachapp-3aac9.firebaseapp.com",
  projectId: "muchachapp-3aac9",
  storageBucket: "muchachapp-3aac9.appspot.com",
  messagingSenderId: "1030105610609",
  appId: "1:1030105610609:web:fb5d3ca947259c7cda1ed0",
  measurementId: "G-4BPVSTJSX7"
};

export const twilio={
  TWILIO_ACCOUNT_SID:'ACba01abf8da3da7c5f4fbf76860d68d57:9c7768ab9b0058af2ecf37eedf30126b',
  TWILIO_AUTH_TOKEN:'ACba01abf8da3da7c5f4fbf76860d68d57',
  MessagingServiceSid:'MG88c9b058cb559ac29e1b03ec20bfbf49',

}