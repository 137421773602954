import { Component, OnInit,Input  } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { SqlGenericService } from "src/app/services/sqlGenericService";
@Component({
  selector: 'app-modal-usuarios',
  templateUrl: './modal-usuarios.component.html',
  styleUrls: ['./modal-usuarios.component.scss'],
})
export class ModalUsuariosComponent implements OnInit {
  @Input() model_inputs: any[]
  @Input() model_data: any[]
  public inputsUsuarios:any [] 
  public dataUsuario:any [] 

  constructor(
    private modalCtrl: ModalController,
    private sqlGenericService: SqlGenericService,
  ) {

   }

  ngOnInit() {
    this.inputsUsuarios=this.model_inputs
    this.dataUsuario=this.model_data
    console.log( this.dataUsuario[0]['perfil.url_foto'])
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }


  sqlUpdatePerfil(){
    let sqlFind: string = 
    `UPDATE  perfil SET 
    url_foto= '' 
    WHERE id_usuario = '${this.dataUsuario[0]['perfil.id_usuario']}'`;

    console.log(sqlFind)

   this.sqlGenericService.excecuteQueryStringReference(sqlFind,`delete`).subscribe((rsp: any) => {
   
      },

    
    );
  }

  sqlUpdateidentificacion(){
    let sqlFind: string = 
    `UPDATE  perfil SET 
    identificacion= '' 
    WHERE id_usuario = '${this.dataUsuario[0]['perfil.id_usuario']}'`;

    console.log(sqlFind)

   this.sqlGenericService.excecuteQueryStringReference(sqlFind,`delete`).subscribe((rsp: any) => {
   
      },

    
    );
  }
    
    
}
