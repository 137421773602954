import { Directive, ViewContainerRef, TemplateRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[adHost]"
})
export class AdDirective implements OnChanges
{

    @Input() adHost !: any
    constructor(
        public viewContainerRef: ViewContainerRef,
        public templateRef:TemplateRef<any>,
    ){
    this.viewContainerRef.createEmbeddedView(templateRef);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['adHost']){
            this.viewContainerRef.clear();
            this.viewContainerRef.createEmbeddedView(this.templateRef)
        }
        
    }
}