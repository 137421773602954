import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'button-egasystems',
  templateUrl: './button-egasystems.html',
  styleUrls: ['./button-egasystems.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ButtonegasystemsComponent implements OnDestroy, OnInit {

  @Input() extraClass: string;
  @Input() onTap: Function;
  @Input() id: string;
  @Input() disabled: boolean;

  constructor(
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {

  }

}
