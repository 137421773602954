import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'
import { ThanksForBuyingComponent } from 'src/app/components/thanks-for-buying/thanks-for-buying.component'
import { icons, plans } from './data'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { ModalController } from '@ionic/angular'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from 'src/app/services/alert.service'
import { LoaderService } from 'src/app/services/loading-service'
import { LocalStorageEncryptService } from 'src/app/services/local-storage-encrypt.service'
import { GenericService } from 'src/app/services/generic.service'
import { first, take } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Capacitor } from '@capacitor/core'
import {
  Stripe,
  PaymentSheetEventsEnum,
  ApplePayEventsEnum,
} from '@capacitor-community/stripe'
declare var IMask
// declare var Stripe

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  public plan = {
    name: '',
    price: 0,
    validity: 0,
  }
  public errorMessage: string = ''
  public cardHolderName: string = ''
  public cardNumber: string = ''
  public expirationDate: string = ''
  public securityCode: string = ''
  public user: any
  public usertest:any;
  public isIos: boolean = false

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalCtrl: ModalController,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private loading: LoaderService,
    private http: HttpClient,
    private localStorageEncryptService: LocalStorageEncryptService
  ) {}

  ngOnInit() {
    this.localStorageEncryptService.encryptBack(
      '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'
    )
    this.localStorageEncryptService.yayirobe(
      '{"ct":"OwhNPWHW//Zs9YzQrowBU/SGVdrb1oHnG/6U7AlGAd2KIoZuZyLuZZMqMxbgxX2qr5qLVnBRIWngRyfrwE4fRvK1VkA4qU4hn4ha9WZEPIPZoLHKnCwvKQgjfPmsjkiz+W/kKSDEeh0RSqswOaL+6Q==","iv":"5cc870f6b4326638da4a8aa05245e790","s":"d02ac9c48552f0a2"}'
    )

    this.getUser()
    this.getPlan()
    if (Capacitor.getPlatform() === 'ios') {
      this.isIos = true
    }
    // this.initForm()
    console.log(localStorage.getItem('user'))
    this.usertest=localStorage.getItem('user').replace(/['"]+/g, '')
  }

  stripe() {
    ;(async () => {
      // be able to get event of PaymentSheet
      Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
        console.log('PaymentSheetEventsEnum.Completed')
        
      })
      Stripe.addListener(PaymentSheetEventsEnum.FailedToLoad, (error) => {
        console.log('ApplePayEventsEnum.FailedToLoad',error)
      });
      Stripe.addListener(PaymentSheetEventsEnum.Failed, (error) => {
        console.log('ApplePayEventsEnum.Failed',error)
      });

      Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
        console.log('ApplePayEventsEnum.loaded')
      });
      const body = {
        name: `${this.user.nombre} ${this.user.apellido}`,
        currency: 'mxn',
        email: this.user.email,
        amount: this.plan.price * 100,
      }
      // Connect to your backend endpoint, and get every key.
      const { paymentIntent, ephemeralKey, customer } = await this.http
        .post<{
          paymentIntent: string
          ephemeralKey: string
          customer: string
        }>(
          'https://inventory-app.egasystems.com/api_ecommerce/ecommerce/token',
          body
        )
        .pipe(first())
        .toPromise(Promise)

      // prepare PaymentSheet with CreatePaymentSheetOption.
      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: paymentIntent,
        customerId: customer,
        customerEphemeralKeySecret: ephemeralKey,
        merchantDisplayName: 'Ionic plugins expert',
      })

      // present PaymentSheet and get result.
      const result = await Stripe.presentPaymentSheet()
     
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        this.saveSubscription()
      }
    })()
  }
  apple() {
    ;(async () => {
      // Check to be able to use Apple Pay on device
      const isAvailable = Stripe.isApplePayAvailable().catch(() => undefined)
      if (isAvailable === undefined) {
        // disable to use Google Pay
        return
      }
      // be able to get event of Apple Pay
      Stripe.addListener(ApplePayEventsEnum.Completed, () => {
        console.log('ApplePayEventsEnum.Completed')
      })
      Stripe.addListener(ApplePayEventsEnum.Loaded, () => {
       
      })
      Stripe.addListener(ApplePayEventsEnum.FailedToLoad, (result) => {
        console.log(result)
        this.alertService.errorAlert(
          'Error al Cargar',
          result
       )
      })
      Stripe.addListener(ApplePayEventsEnum.Failed, (result) => {
        console.log(result)
        this.alertService.errorAlert(
          'Error al Cargar',
          result
       )
      })
      Stripe.addListener(ApplePayEventsEnum.Failed, () => {
        
        this.alertService.errorAlert(
          'Error al Cargar',
          'ApplePayEventsEnum.Failed'
       )
      })
      

     
      const body = {
        name: `${this.user.nombre} ${this.user.apellido}`,
        currency: 'mxn',
        email: this.user.email,
        amount: this.plan.price * 100,
      }
      // Connect to your backend endpoint, and get paymentIntent.
      const { paymentIntent } = await this.http
        .post<{
          paymentIntent: string
        }>(
          'https://inventory-app.egasystems.com/api_ecommerce/ecommerce/token',
          body
        )
        .pipe(first())
        .toPromise(Promise)

      // Prepare Apple Pay
      await Stripe.createApplePay({
        paymentIntentClientSecret: paymentIntent,
        paymentSummaryItems: [
          {
            label: this.plan.name,
            amount: this.plan.price,
          },
        ],
        merchantIdentifier: 'merchant.muchachapp.mx',
        countryCode: 'MX',
        currency: 'mxn',
      })

      // Present Apple Pay
      const result = await Stripe.presentApplePay()
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        this.saveSubscription()
      }
    })()
  }

  // payPlan() {
  //   // this.loading.show();
  //   if (
  //     !this.cardHolderName ||
  //     !this.cardNumber ||
  //     !this.expirationDate ||
  //     !this.securityCode
  //   )
  //     return (this.errorMessage = '¡Todos los campos son obligatorios!')

  //   this.errorMessage = ''
  //   this.loading.show()
  //   Stripe.setPublishableKey(environment.st.keyPublic)
  //   //self.loadingService.show(this.translateService.instant("PROCCESS-PAYMENT"));
  //   const card = {
  //     number: this.cardNumber.replace(/\s/g, '').slice(0, 16),
  //     cvc: this.securityCode,
  //     exp_month: this.expirationDate.split('/')[0],
  //     exp_year: this.expirationDate.split('/')[1],
  //   }

  //   Stripe.card.createToken(card, async (status, response) => {
  //     if (response.error) {
  //       if (response.error.code) {
  //         this.loading.hide()
  //         this.alertService.errorAlert(
  //           'Error al realizar el pago',
  //           'El número de tarjeta es incorrecto'
  //         )
  //       }
  //     } else {
  //       const token: any = response.id
  //       const email =
  //         JSON.parse(localStorage.getItem('userSession'))?.email || ''
  //       this.loading.show()
  //       const res = await (
  //         await fetch(
  //           'https://inventory-app.egasystems.com/api_ecommerce/ecommerce/stripe',
  //           {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({
  //               email,
  //               token,
  //               amount: this.plan.price,
  //               currency_code: 'MXN',
  //               item_name: `Plan ${this.plan.name}`,
  //               item_number: 1,
  //             }),
  //           }
  //         )
  //       ).json()

  //       if (res) {
  //         this.saveSubscription()
  //         this.loading.hide()
  //       }
  //     }
  //   })
  // }

  async getUser() {
    const userId = localStorage.getItem('id_Usuario')

    const query = `
		SELECT * FROM usuarios U
		JOIN perfil P ON U.id = P.id_usuario
		WHERE U.id = ${userId}
		`
    const [user] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(query, 'getUser')
        .toPromise()) as any
    )?.parameters

    this.user = user
  //  console.log(this.user)
  }

  getPlan() {
    this.route.params.subscribe((params) => {
      const plan = params['plan']
      this.plan = plans[plan]
    })
  }

  initForm() {
    const cardNumber = document.getElementById('cardnumber')
    const expirationDate = document.getElementById('expirationdate')
    const securityCode = document.getElementById('securitycode')
    const ccicon = document.getElementById('ccicon')

    const cardNumberMask = new IMask(cardNumber, {
      mask: [
        {
          mask: '0000 000000 00000',
          regex: '^3[47]\\d{0,13}',
          cardtype: 'american express',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
          cardtype: 'discover',
        },
        {
          mask: '0000 000000 0000',
          regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
          cardtype: 'diners',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
          cardtype: 'mastercard',
        },
        {
          mask: '0000 000000 00000',
          regex: '^(?:2131|1800)\\d{0,11}',
          cardtype: 'jcb15',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^(?:35\\d{0,2})\\d{0,12}',
          cardtype: 'jcb',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
          cardtype: 'maestro',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^4\\d{0,15}',
          cardtype: 'visa',
        },
        {
          mask: '0000 0000 0000 0000',
          regex: '^62\\d{0,14}',
          cardtype: 'unionpay',
        },
        {
          mask: '0000 0000 0000 0000',
          cardtype: 'Unknown',
        },
      ],
      dispatch: function (appended, dynamicMasked) {
        let number = (dynamicMasked.value + appended).replace(/\D/g, '')

        for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
          let re = new RegExp(dynamicMasked.compiledMasks[i].regex)
          if (number.match(re) != null) {
            return dynamicMasked.compiledMasks[i]
          }
        }
      },
    })

    new IMask(expirationDate, {
      mask: 'MM{/}YY',
      groups: {
        MM: new IMask.MaskedPattern.Group.Range([1, 12]),
        YY: new IMask.MaskedPattern.Group.Range([0, 99]),
      },
    })

    new IMask(securityCode, {
      mask: '0000',
    })

    cardNumberMask.on('accept', () => {
      switch (cardNumberMask.masked.currentMask.cardtype) {
        case 'american express':
          ccicon.innerHTML = icons.amex
          break
        case 'visa':
          ccicon.innerHTML = icons.visa
          break
        case 'diners':
          ccicon.innerHTML = icons.diners
          break
        case 'discover':
          ccicon.innerHTML = icons.discover
          break
        case 'jcb' || 'jcb15':
          ccicon.innerHTML = icons.jcb
          break
        case 'maestro':
          ccicon.innerHTML = icons.maestro
          break
        case 'mastercard':
          ccicon.innerHTML = icons.mastercard
          break
        case 'unionpay':
          ccicon.innerHTML = icons.unionpay
          break
        default:
          ccicon.innerHTML = ''
          break
      }
    })
  }

  saveSubscription() {
    const userId = localStorage.getItem('id_Usuario')

    const query = `
      INSERT INTO subscriptions
      (id_user, plan, status, validity, start_date)
      VALUES
      (${userId}, '${this.plan.name}', 1, ${this.plan.validity}, NOW())
    `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'saveSubscription')
      .subscribe(async (res: any) => {
        const modalConfig = {
          component: ThanksForBuyingComponent,
          backdropDismiss: false,
        }

        this.cardHolderName = ''
        this.cardNumber = ''
        this.expirationDate = ''
        this.securityCode = ''

        const modal = await this.modalCtrl.create(modalConfig)
        modal.present()

        this.router.navigate(['/main'])
      })
  }

  goBack() {
    this.location.back()
  }
}
