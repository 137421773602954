import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { DeleteProfileComponent } from 'src/app/components/delete-profile/delete-profile.component'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { LoaderService } from 'src/app/services/loading-service';
@Component({
  selector: 'app-chat-profile',
  templateUrl: './chat-profile.component.html',
  styleUrls: ['./chat-profile.component.scss'],
})
export class ChatProfileComponent implements OnInit {
  public isMaid: boolean = false
  public userInfo: any = {}

  constructor(
    private location: Location,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private sqlGenericService: SqlGenericService,
    private loading:LoaderService,
  ) {}

  ngOnInit() {
    this.isMaid = localStorage.getItem('Servicio') === 'maid'

    this.getProfileInfo()
  }

  async getProfileInfo() {
    this.loading.show();
    const { states, municiples } = await this.getStatesAndMuniciples()

    this.route.params.subscribe((params) => {
      const userId = params['user_id']

      if (this.isMaid) {
        const query = `SELECT

          U.id as 'Id_Usuario',
          U.email as 'Email',
        
          P.nombre as 'name', P.apellido as 'lastname',
          P.celular as 'phone', P.url_foto as 'imageUrl',
          P.adultos as 'adultsN', P.ninos as 'kidsN',
          P.mascotas as 'petsN',
          E.name as 'state', M.name as 'municiple',
          h.habitaciones as 'rooms', h.banos as 'bathrooms',
          h.jardin as 'garden', h.terraza as 'terrace',
          O.salario as 'salary', O.frecuencia as 'frecuence',
          A.limpieza as 'cleaning', A.laba as 'washing',
          A.cocinar as 'cooking', A.ninos as 'kids',
          A.adultos as 'adults', th.name as 'homeType'
          
        FROM usuarios U 
        INNER JOIN perfil P
        ON U.id = P.id_usuario
        INNER JOIN estados E
        ON E.id = P.estado
        INNER JOIN municipios M
        ON M.id = P.municipio
        INNER JOIN hogar h
        ON h.id_usuario = P.id_usuario
        INNER JOIN tipo_hogar th
        ON th.id = h.tipo_hogar
        INNER JOIN oferta_laboral O
        ON O.id_usuario = P.id_usuario
        INNER JOIN actividades A
        ON A.id_usuario = P.id_usuario

        WHERE U.id = ${userId}
        `

        this.sqlGenericService
          .excecuteQueryStringReference(query, 'getChatInfo')
          .subscribe((res: any) => {
            const response = res.parameters[0]

            this.userInfo = response
            this.loading.hide();
          })
      } else {
        const query = `SELECT

        U.id as 'Id_Usuario',
        U.email as 'Email',
      
        P.nombre as 'name', P.apellido as 'lastname',
        P.celular as 'phone', P.url_foto as 'imageUrl',
        E.name as 'state', M.name as 'municiple',
        O.salario as 'salary', O.otra as 'anotherCity',
        O.frecuencia as 'frecuence',
        A.limpieza as 'cleaning', A.laba as 'washing',
        A.cocinar as 'cooking', A.ninos as 'kids',
        A.adultos as 'adults',
        Ex.limpieza as 'cleaningEx', Ex.laba as 'washingEx',
        Ex.cocinar as 'cookingEx', Ex.ninos as 'kidsEx',
        Ex.adultos as 'adultsEx',
        Ex.estado as 'workState', Ex.municipio as 'workMuniciple',
        Ex.referencias as 'reference',Ex.experiencia_anos as 'experience'
          
        FROM usuarios U 
        INNER JOIN perfil P
        ON U.id = P.id_usuario
        INNER JOIN estados E
        ON E.id = P.estado
        INNER JOIN municipios M
        ON M.id = P.municipio
        INNER JOIN experiencia Ex
        ON Ex.id_usuario = P.id_usuario
        INNER JOIN oferta_laboral O
        ON O.id_usuario = P.id_usuario
        INNER JOIN actividades A
        ON A.id_usuario = P.id_usuario

        WHERE U.id = ${userId}
        `

        this.sqlGenericService
          .excecuteQueryStringReference(query, 'getChatInfo')
          .subscribe((res: any) => {
            const response = res.parameters[0]
            console.log(response.workState, states)
            this.userInfo = {
              ...response,
              workState: states.find(
                (state) => Number(state.id) === Number(response.workState)
              )?.name,
              workMuniciple: municiples.find(
                (municiple) =>
                  Number(municiple.id) === Number(response.workMuniciple)
              )?.name,
            }
            this.loading.hide();
          })
      }
    })
  }

  async getStatesAndMuniciples() {
    const statesQuery = `SELECT * FROM estados`
    const municiplesQuery = `SELECT * FROM municipios`

    const states = await this.sqlGenericService
      .excecuteQueryStringReference(statesQuery, 'getStates')
      .toPromise()

    const municiples = await this.sqlGenericService
      .excecuteQueryStringReference(municiplesQuery, 'getMuniciples')
      .toPromise()

    return {
      states: (states as any)?.parameters,
      municiples: (municiples as any)?.parameters,
    }
  }

  goBack() {
    this.location.back()
  }

  async openDeleteProfile() {
    const modalConfig = {
      component: DeleteProfileComponent,
      backdropDismiss: false,
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }
}
