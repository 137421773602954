import { Component, OnInit } from '@angular/core'
import { ChatService } from 'src/app/services/chat.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-admin-chats',
  templateUrl: './admin-chats.component.html',
  styleUrls: ['./admin-chats.component.scss'],
})
export class AdminChatsComponent implements OnInit {
  constructor(
    private sqlGenericService: SqlGenericService,
    private chatService: ChatService
  ) {}

  public chats = []
  public chat = null
  public messages = []
  public message = ''

  ngOnInit() {
    this.getChats()
  }

  getMessages(chatId) {
    this.chatService.getMessages(chatId).subscribe((messages) => {
      this.messages = messages.map((message) => ({
        text: message['text'],
        createdAt: message['createdAt'],
        type: message['id_usuario'] === '11111' ? 'own' : 'other',
        status: message['status'],
        isImage: message['isImage'],
        imagenUser: message['imagenUser'],
      }))
    })
  }

  getChats() {
    const query = `
      SELECT
      C.id, C.id_usuario_1,
      P1.nombre as 'name_1', P1.apellido as 'lastname_1',
      P1.url_foto as 'image_1'
      FROM chats C
      JOIN perfil P1 ON P1.id_usuario = C.id_usuario_1
      WHERE C.id_usuario_2 = ${11111}
      `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getChats')
      .subscribe(async (res: any) => {
        const response = res.parameters

        const chats = response.map((chat) => {
          return {
            id: chat.id,
            name: `${chat.name_1} ${chat.lastname_1}`,
            idUser: chat.id_usuario_2,
            image: chat.image_1,
            body: chat.body,
            isImage: !!Number(chat.isImage),
          }
        })

        this.chats = chats
      })
  }

  getChat(chatId) {
    const query = `
				SELECT 
				C.id, C.id_usuario_1, P1.nombre as 'name_1',
        P1.apellido as 'lastname_1', P1.url_foto as 'image_1',
        P1.notificar_token as 'token1'
				FROM chats C
				JOIN perfil P1 ON P1.id_usuario = C.id_usuario_1
				WHERE (C.id = ${chatId})
				`

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getChatInfo')
      .subscribe((res: any) => {
        const response = res.parameters[0]
        this.chat = {
          id: response.id,
          name: response.name_1,
          lastname: response.lastname_1,
          image: response.image_1,
          userId: response.id_usuario_1,
          token: response.token1,
        }
        this.getMessages(this.chat.id)
      })
  }

  onChange(value: string) {
    console.log(value)
    this.message = value
  }

  async sendMessage() {
    if (this.message) {
      this.chatService.sendMessage(
        this.chat.id,
        '11111',
        this.message,
        'https://cdn-icons-png.flaticon.com/512/2304/2304226.png',
        0
      )
      this.notificar(this.message)
      this.message = ''

      this.getMessages(this.chat.id)
    }
  }
  async notificar(mensaje) {
    console.log(mensaje)
    const res = await (
      await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization:
            'key=AAAA79cUOXE:APA91bF75vf9l69lW5fqOlHCMXqzdfrKBvANwCzd5n_BBpAk06eeeSydyfe7I8fy_eDUYFXCDk3kp6qh15K1tW81cvYp63l6oeq96PRtCoeyLp-amC-gZX_Y2Ca6gTnk5vil1iPPp6Gq',
        },
        body: JSON.stringify({
          notification: {
            title: 'Has Recibido Un Mesaje ',
            body: mensaje,
            // icon:"stock_ticker_update",
            // color:"#7e55c3",
          },
          to: this.chat.token,
          direct_boot_ok: true,
        }),
      })
    ).json()
  }
}
