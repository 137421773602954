import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
@Component({
  selector: 'app-hogar',
  templateUrl: './hogar.component.html',
  styleUrls: ['./hogar.component.scss'],
})
export class HogarComponent implements OnInit {
  public datos_estados:any[];
   public render: any = {
    inputHogar: {
    required: true,
    placeholder: 'Hogar.....',
    value:'Tipo de Hogar',
    error: false,
    type: 'text',
    length: 100
    },
    inputHabitaciones: {
    required: true,
    placeholder: 'Habitaciones.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputBanos: {
    required: true,
    placeholder: 'Banos.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputJardin: {
    required: true,
    placeholder: 'Jardin.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputTerraza: {
    required: true,
    placeholder: 'Terraza.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    
   };
  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.sqlSelectHogar()
  }

  back() {
    window.history.back();
  }


  goInicio() {
    if(this.validarDatos()){
    this,this.SQL();
    this.router.navigateByUrl('/Actividades');

    
    }
    
  
    
    
  }
  addNumeros(ngmodel){
  
    if(ngmodel=== 2){
      this.render.inputBanos.value=this.render.inputBanos.value+1;
      }
      if(ngmodel=== 1){
        this.render.inputHabitaciones.value=this.render.inputHabitaciones.value+1;
        }
  }
  removeNumeros(ngmodel){
    
    if(this.render.inputBanos.value>0 && ngmodel==2){
      this.render.inputBanos.value=this.render.inputBanos.value-1;
      }
    if(this.render.inputHabitaciones.value>0 && ngmodel==1){
      this.render.inputHabitaciones.value=this.render.inputHabitaciones.value-1;
      }  
  }
  SQL(){

    let sqlFind: string = 
    `INSERT INTO hogar 
    (tipo_hogar,habitaciones,banos,jardin,terraza,id_usuario)
    VALUES (
    '${this.render.inputHogar.value}',
    '${this.render.inputHabitaciones.value}',
    '${this.render.inputBanos.value}',
    '${this.render.inputJardin.value}',
    '${this.render.inputTerraza.value}',
    '${localStorage.getItem('id_Usuario')}'
    )`;
    
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`gethogar`).subscribe((rsp: any) => {
    
    
    console.log(rsp.parameters[0].id );
    },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    })
  }

  datos(event:any,ngmodel){
   if(event.target.checked==true && ngmodel==1){
    this.render.inputJardin.value=1
    console.log("Jardin:",this.render.inputJardin.value);
   }
   if(event.target.checked==false && ngmodel==1){
    this.render.inputJardin.value=0
    console.log("Jardin:",this.render.inputJardin.value);
   }


   if(event.target.checked==true && ngmodel==2){
    this.render.inputTerraza.value=1
    console.log("Terraza:",this.render.inputTerraza.value);
   }
   if(event.target.checked==false && ngmodel==2){
    this.render.inputTerraza.value=0
    console.log("Terraza:",this.render.inputTerraza.value);
   }

    
  }
  validarDatos(){

    let isValid=true
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
  
   if(this.render.inputHogar.value=='Tipo de Hogar'){
    isValid = false;
    this.alertService.successAlert('Datos','Por favor llena todos los campos')

   }
   console.log(isValid)
 //  console.log(this.render.inputNombre.value)
   return isValid;
  }

  sqlSelectHogar(){
    let sqlFind: string = 
    `SELECT * FROM tipo_hogar `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
    this.datos_estados=rsp.parameters
   console.log(this.datos_estados)
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
   
     }

     onChange(field: string, value: string) {
      this[field] = value
     
      if (field === 'Tipo de Hogar') {
        this.render.inputHogar.error = !!value
      }
      if (field === 'Habitaciones') {
        console.log(value)
        this.render.inputHabitaciones.error = !!value
      }
     
    }

}
