import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-crm-configuracion',
  templateUrl: './crm-configuracion.component.html',
  styleUrls: ['./crm-configuracion.component.scss'],
})
export class CrmConfiguracionComponent implements OnInit {

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {}


GoPage(event){

  if(event==1){
    this.router.navigateByUrl('/PerfilCrm');

  }
  if(event==2){
    this.router.navigateByUrl('/HogaresCrm');

  }
  if(event==3){
    this.router.navigateByUrl('/EstadosCrm');

  }
  if(event==4){
    this.router.navigateByUrl('/MunicipiosCrm');

  }


}
back() {
  window.history.back();
}
}
