import { Location } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ActionSheetController, ModalController } from '@ionic/angular'
import { DeleteProfileComponent } from 'src/app/components/delete-profile/delete-profile.component'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { environment } from 'src/environments/environment.prod'
import { UtilService } from 'src/app/services/util.service'
import { GenericService } from 'src/app/services/generic.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from 'src/app/services/alert.service'
import { ChatService } from 'src/app/services/chat.service'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  public messages: any[] = []
  public chatInfo: any = {}
  public userInfo: any = {}
  public message: string = ''
  public url: any
  public b64Image: string = ''
  public userId: string
  public type: any

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private genericService: GenericService,
    private sqlGenericService: SqlGenericService,
    private actionSheetController: ActionSheetController,
    private changeDetectorRef: ChangeDetectorRef,
    private utilService: UtilService,
    private alertService: AlertService,
    private chatService: ChatService
  ) {}

  ngOnInit() {
    this.getChatInfo()
    this.chatService.getMessages(this.chatInfo.id).subscribe((messages) => {
      this.messages = messages.map((message) => ({
        text: message['text'],
        createdAt: message['createdAt'],
        type: message['id_usuario'] === this.userInfo.id ? 'own' : 'other',
        status: message['status'],
        isImage: message['isImage'],
        imagenUser: message['imagenUser'],
      }))
      setTimeout(function () {
        var objDiv = document.getElementById('mensajes')
        objDiv.scrollTop = objDiv.scrollHeight
      }, 500)
    })
  }

  async getChatInfo() {
    this.route.params.subscribe(async (params) => {
      const id = params['id_chat']
      const userId = localStorage.getItem('id_Usuario')

      if (id === '11111') {
        const query = `
				SELECT 
				C.id, C.id_usuario_1, P1.nombre as 'name_1',
        P1.apellido as 'lastname_1', P1.url_foto as 'image_1',
        P1.notificar_token as 'token1'
				FROM chats C
				JOIN perfil P1 ON P1.id_usuario = C.id_usuario_1
				WHERE (C.id_usuario_1 = ${userId} AND C.id_usuario_2 = '11111')
				`
        this.sqlGenericService
          .excecuteQueryStringReference(query, 'getChatInfo')
          .subscribe((res: any) => {
            const response = res.parameters[0]
            console.log(response)
            this.chatInfo = {
              id: response.id,
              name: 'Administración',
              lastname: 'Muchachapp',
              image: 'https://cdn-icons-png.flaticon.com/512/2304/2304226.png',
              userId: '11111',
            }

            this.userInfo = {
              id: userId,
              name: response.name_1,
              lastname: response.lastname_1,
              image: response.image_1,
              token: response.token1,
            }

            this.chatService
              .getMessages(this.chatInfo.id)
              .subscribe((messages) => {
                this.messages = messages.map((message) => ({
                  text: message['text'],
                  createdAt: message['createdAt'],
                  type:
                    message['id_usuario'] === this.userInfo.id
                      ? 'own'
                      : 'other',
                  status: message['status'],
                  isImage: message['isImage'],
                  imagenUser: message['imagenUser'],
                }))
              })
          })
      } else {
        const query = `
				SELECT 
				C.id, C.id_usuario_1, C.id_usuario_2,
    		P1.nombre as 'name_1', P1.apellido as 'lastname_1',
				P2.nombre as 'name_2', P2.apellido as 'lastname_2',
				P1.url_foto as 'image_1', P2.url_foto as 'image_2',
        P1.notificar_token as 'token1', P2.notificar_token as 'token2'
				FROM chats C
				JOIN perfil P1 ON P1.id_usuario = C.id_usuario_1
				JOIN perfil P2 ON P2.id_usuario = C.id_usuario_2
				WHERE (C.id = ${id})
				OR (C.id_usuario_1 = ${userId} AND C.id_usuario_2 = ${id})
				OR (C.id_usuario_2 = ${userId} AND C.id_usuario_1 = ${id})
				`
        this.sqlGenericService
          .excecuteQueryStringReference(query, 'getChatInfo')
          .subscribe((res: any) => {
            const response = res.parameters[0]
            const isUser1 = response.id_usuario_1 === userId

            this.chatInfo = {
              id: response.id,
              name: isUser1 ? response.name_2 : response.name_1,
              lastname: isUser1 ? response.lastname_2 : response.lastname_1,
              image: isUser1 ? response.image_2 : response.image_1,
              userId: isUser1 ? response.id_usuario_2 : response.id_usuario_1,
            }

            this.userInfo = {
              id: userId,
              name: isUser1 ? response.name_1 : response.name_2,
              lastname: isUser1 ? response.lastname_1 : response.lastname_2,
              image: isUser1 ? response.image_1 : response.image_2,
              token: isUser1 ? response.token2 : response.token1,
            }

            this.chatService
              .getMessages(this.chatInfo.id)
              .subscribe((messages) => {
                this.messages = messages.map((message) => ({
                  text: message['text'],
                  createdAt: message['createdAt'],
                  type:
                    message['id_usuario'] === this.userInfo.id
                      ? 'own'
                      : 'other',
                  status: message['status'],
                  isImage: message['isImage'],
                  imagenUser: message['imagenUser'],
                }))
              })
          })
      }
    })
  }

  async sendMessage() {
    if (this.message) {
      this.chatService.sendMessage(
        this.chatInfo.id,
        this.userInfo.id,
        this.message,
        this.userInfo.image,
        0
      )
      this.notificar(this.message)
      this.message = ''

      this.chatService.getMessages(this.chatInfo.id).subscribe((messages) => {
        this.messages = messages.map((message) => ({
          text: message['text'],
          createdAt: message['createdAt'],
          type: message['id_usuario'] === this.userInfo.id ? 'own' : 'other',
          status: message['status'] === this.userInfo.id ? 'send' : 'read',
          isImage: message['isImage'],
          imagenUser: message['imagenUser'],
        }))
        setTimeout(function () {
          var objDiv = document.getElementById('mensajes')
          objDiv.scrollTop = objDiv.scrollHeight
        }, 400)
      })
      //console.log(this.messages)
    }
  }
  async notificar(mensaje) {
    console.log(mensaje)
    const res = await (
      await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization:
            'key=AAAA79cUOXE:APA91bF75vf9l69lW5fqOlHCMXqzdfrKBvANwCzd5n_BBpAk06eeeSydyfe7I8fy_eDUYFXCDk3kp6qh15K1tW81cvYp63l6oeq96PRtCoeyLp-amC-gZX_Y2Ca6gTnk5vil1iPPp6Gq',
        },
        body: JSON.stringify({
          notification: {
            title: 'Has Recibido Un Mesaje ',
            body: mensaje,
            // icon:"stock_ticker_update",
            // color:"#7e55c3",
          },
          to: this.userInfo.token,
          direct_boot_ok: true,
        }),
      })
    ).json()
  }

  goBack() {
    this.location.back()
  }
  goChatProfile(userId: number) {
    this.router.navigate(['/chat-profile', userId])
  }
  async openDeleteProfile() {
    const modalConfig = {
      component: DeleteProfileComponent,
      backdropDismiss: false,
      componentProps: {
        userId: this.chatInfo.userId,
        chatId: this.chatInfo.id,
      },
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }

  async openSheet(): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: 'Imagenes',
      cssClass: 'action-sheets-olam',
      buttons: [
        {
          text: 'Tomar Foto',
          handler: () => {
            this.captureImage(true)
          },
        },
        {
          text: 'Elegir de la Biblioteca',
          handler: () => {
            this.captureImage(false)
          },
        },

        {
          text: 'delete',
          role: 'destructive',
          handler: () => {
            this.b64Image = ''
          },
        },
        {
          text: 'cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    })

    await actionSheet.present()

    await actionSheet.onDidDismiss()
  }
  async fileChange(evt) {
    let file: any = evt.target.files[0]
    if (file.size > 2000000) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg' &&
      file.type != 'image/svg+xml'
    ) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else {
      let fileB64 = await this.utilService.toBase64(file)
      this.b64Image = fileB64 as string
    }
  }

  async captureImage(isCamera: boolean = true) {
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
    })

    const fileName = `${this.userInfo.id}_${new Date().getTime()}`

    this.b64Image = 'data:image/jpeg;base64,' + image.base64String
    let requestFile: any = {
      b64: this.b64Image,
      id: this.userInfo.id,
      extension: 'png',
      table: 'perfil',
    }

    this.genericService
      .sendPostRequest(`${environment.loadFileOnly}/${fileName}`, requestFile)
      .subscribe(
        async (responseFile: any) => {
          const query = `
            INSERT INTO mensajes
            (id_chat, id_usuario, text, is_image)
            VALUES (${this.chatInfo.id}, ${this.userInfo.id}, '${responseFile.parameters}', 1)
            `
          this.chatService.sendMessage(
            this.chatInfo.id,
            this.userInfo.id,
            responseFile.parameters,
            this.userInfo.image,
            '1'
          )
          this.message = ''

          await this.sqlGenericService
            .excecuteQueryStringReference(query, 'sendMessage')
            .toPromise()

          this.chatService
            .getMessages(this.chatInfo.id)
            .subscribe((messages) => {
              this.messages = messages.map((message) => ({
                text: message['text'],
                createdAt: message['createdAt'],
                type:
                  message['id_usuario'] === this.userInfo.id ? 'own' : 'other',
                status: message['status'],
                isImage: message['isImage'],
                imagenUser: message['imagenUser'],
              }))
            })
        },
        (err: HttpErrorResponse) => {}
      )
    this.changeDetectorRef.detectChanges()
  }
}
