import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
declare var $: any;
@Component({
  selector: 'app-crm-municipio',
  templateUrl: './crm-municipio.component.html',
  styleUrls: ['./crm-municipio.component.scss'],
})
export class CrmMunicipioComponent implements OnInit {
public create:boolean=true;
public datos_estados:any[];
public datos:any[];
public municipioRender: any = 
{
inputEstado: {
required: true,
placeholder: 'Estado.....',
value: "Estados",
error: false,
type: 'text',
length: 100
},
inputMunicipio: {
required: true,
placeholder: 'Municipio.....',
value: null,
error: false,
type: 'text',
length: 100
},
inputDescripcion: {
required: true,
placeholder: 'Descripcion.....',
value: null,
error: false,
type: 'text',
length: 100
},
inputId: {
required: true,
placeholder: 'Id.....',
value: null,
error: false,
type: 'text',
length: 100
},

};


  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,
  ) { 
    setTimeout(function () {
      $(function () {
        $('#dt').DataTable(
          {
            'bDestroy': true,

            "bInfo": true,
            "bProcessing": true,
            "bDeferRender": true,
            'iDisplayLength': 10,
            'sPaginationType': 'full_numbers',
            'sDom': '<"top"i> T<"clear">lfrtip',
            'sPageButtonActive': "paginate_active",
            'sPageButtonStaticDisabled': "paginate_button",
            "scrollY": "200px",
            "scrollCollapse": true,
          }
        );
      });
    }, 1000); 
  }

  ngOnInit() {
    this.sqlSelectEstados();
    this.sqlSelectMunicipios();
  }
  back() {
    window.history.back();
  }

  sqlSelectEstados(){
    let sqlFind: string = 
    `SELECT * FROM estados `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
    this.datos_estados=rsp.parameters
    console.log(this.datos_estados)
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
  }
  sqlSelectMunicipios(){
    let sqlFind: string = 
    ` SELECT
			 
    M.id as 'id',
    M.name as 'name',
    M.description as 'description',
      
    E.name as 'estado'
  
   
   
    FROM municipios M
    INNER JOIN estados E
    ON E.id =M.id_estados`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getmunicipios`).subscribe((rsp: any) => {
      this.datos=rsp.parameters
      console.log(this.datos)
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );


  }
  sqlInsert(){
let sqlFind: string = 
`INSERT INTO municipios 
(name, description,id_estados)
VALUES (
'${this.municipioRender.inputMunicipio.value}',
'${this.municipioRender.inputDescripcion.value}',
${this.municipioRender.inputEstado.value}
)`;

this.sqlGenericService.excecuteQueryStringReference(sqlFind,`createmunicipios`).subscribe((rsp: any) => {

  this.municipioRender.inputMunicipio.value='';
  this.municipioRender.inputDescripcion.value='';
  this.municipioRender.inputId.value='';
  (document.getElementById(rsp.parameters[0].id_estados) as HTMLInputElement).value='Estados';
  this.municipioRender.inputEstado.value='Estados';
console.log(rsp.parameters[0].id );
this.sqlSelectMunicipios()
},

(error: HttpErrorResponse) => {
if (error.status != 200) {
this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

}
})

  }

  sqlUpdate(){
    let sqlFind: string = 
    `UPDATE  municipios SET 
    name = '${this.municipioRender.inputMunicipio.value}', 
    description= '${this.municipioRender.inputDescripcion.value}', 
    id_estados= '${this.municipioRender.inputEstado.value}'
    WHERE id = '${this.municipioRender.inputId.value}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`updatemunicipios`).subscribe((rsp: any) => {
      this.sqlSelectMunicipios()
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );


  }


  sqlDelete(id){
    let sqlFind: string = 
    `DELETE from municipios WHERE id = '${id}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`Deleteestados`).subscribe((rsp: any) => {
   // this.datos=rsp.parameters
   //console.log(this.datos)
   this.sqlSelectMunicipios();
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
  
  
  }




  cambioestatus(event){
  
    this.create=false
    let sqlFind: string = 
    `SELECT * FROM municipios where id= ${event} `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getUsuarios`).subscribe((rsp: any) => {
      //console.log(rsp.parameters[0]);
      this.municipioRender.inputMunicipio.value=rsp.parameters[0].name;
      this.municipioRender.inputDescripcion.value=rsp.parameters[0].description;
      this.municipioRender.inputId.value=rsp.parameters[0].id;
      (document.getElementById(rsp.parameters[0].id_estados) as HTMLInputElement).value=rsp.parameters[0].id_estados
      this.municipioRender.inputEstado.value=rsp.parameters[0].id_estados
      console.log(rsp.parameters[0].id_estados)
    
      
    
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
    
    
    }


    update(){

      if(this.create==false){
        this.sqlUpdate();
      }else{
        this.sqlInsert();
    
      }
    
      
    
    }


    

}
