import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/compat/database'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private db: AngularFireDatabase) {}

  getMessages(id_chat) {
    const messages = this.db.list('/' + id_chat).valueChanges()

    return messages
  }

  sendMessage(id_chat, id_usuario, text, imagenUser, isImage?) {
    this.db.list('/' + id_chat).push({
      id_usuario,
      id_chat,
      text,
      status: 'send',
      createdAt: new Date().toString(),
      isImage,
      imagenUser,
    })
  }
}

// id: message.id,
// text: message.text,
// createdAt: new Date(`${message.create_time} UTC`),
// type: message.id_usuario === this.userInfo.id ? 'own' : 'other',
// status: 'send',
// isImage:
