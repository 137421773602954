import { Location } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { AlertService } from 'src/app/services/alert.service'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { environment } from 'src/environments/environment.prod'
import { UtilService } from 'src/app/services/util.service'
import { GenericService } from 'src/app/services/generic.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { Router } from '@angular/router'
import { LoaderService } from 'src/app/services/loading-service';

type State = {
  id: string
  name: string
  description: string
}

type Municiple = {
  id: string
  name: string
  id_estados: string
  description: string
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public url: any
  public b64Image: string = ''
  public userId: string

  public salaryOptions = {
    value: 0,
    options: {
      floor: 0,
      ceil: 6000,
      step: 100,
      showTicks: true,
      translate: (value: number): string => {
        return new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value)
      },
    },
  }

  public weekDays = {
    1: {
      label: 'L',
      value: false,
    },
    2: {
      label: 'Ma',
      value: false,
    },
    3: {
      label: 'Mi',
      value: false,
    },
    4: {
      label: 'J',
      value: false,
    },
    5: {
      label: 'V',
      value: false,
    },
    6: {
      label: 'S',
      value: false,
    },
    7: {
      label: 'D',
      value: false,
    },
  }

  public states: State[] = []
  public municiples: Municiple[] = []
  public availableMuniciples: Municiple[] = []
  public availableMuniciplesWork: Municiple[] = []

  public selectedState: number | string = 0
  public selectedMuniciple: number | string = 0
  public selectedStateWork: number | string = 0
  public selectedMunicipleWork: number | string = 0

  public datos_usuario: any = {
    inputNombre: '',
    inputApellido: '',
    inputEstado: '',
    inputMunicipio: '',
  }

  public rednder: any = {
    inputExp: '',
    inputReferecencias: '',
    inputFrecuencia: '',
    inputDias: '',
    inputSalario: '',
    inputOtra: '',
    inputFoto: '',
    inputTelefono: '',
    adultosMayores: '',
    ninos: '',
    limpieza: '',
    cocinar: '',
    lavar: '',
    fechaNacimiento: '',
    sexo: '',
    ex_limpieza: '',
    ex_cocinar: '',
    ex_lavar: '',
    ex_ninos: '',
    ex_adultos: '',
  }

  constructor(
    private location: Location,
    private genericService: GenericService,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private actionSheetController: ActionSheetController,
    private changeDetectorRef: ChangeDetectorRef,
    private utilService: UtilService,
    private router: Router,
    private loading:LoaderService,
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem('id_Usuario')
    this.sql()
  }

  async sql() {
    this.loading.show();
    const { states, municiples } = await this.getStatesAndMuniciples()
    let sqlFind: string = `SELECT

  U.id as 'Id_Usuario',

  P.nombre as 'Nombre',
  P.apellido as 'Apellido',
  P.celular as 'Telefono',
  P.url_foto as 'url_foto',
  P.fecha_nacimiento as 'Fecha_nacimiento',
  P.sexo as 'sexo',
  P.estado as 'Estado_id',
  P.municipio as 'Municipio_id',
  
  E.name as 'Estado',
  M.name as 'Municipio',
  
  O.dias as 'Dias',
  O.salario as 'Salario',
  O.otra as 'Otra_cuidad',
  O.frecuencia as 'Frecuencia',
  O.L as 'L',
  O.Ma as 'Ma',
  O.Mi as 'Mi',
  O.J as 'J',
  O.V as 'V',
  O.S as 'S',
  O.D as 'D',
    
  A.limpieza as 'Si-Limpieza',
  A.laba as 'Si-Laba',
  A.cocinar as 'Si-Cocinar',
  A.ninos as 'Si-Ninos',
  A.adultos as 'Si-Adultos',

  Ex.limpieza as 'Si-Limpieza-Ex',
  Ex.laba as 'Si-Laba-Ex',
  Ex.cocinar as 'Si-Cocinar-Ex',
  Ex.ninos as 'Si-Ninos-Ex',
  Ex.adultos as 'Si-Adultos-Ex',
  Ex.estado as 'Estado_id_work',
  Ex.municipio as 'Municipio_id_work',
  Ex.referencias as 'Referencias'
  
FROM usuarios U 
INNER JOIN perfil P
ON U.id = P.id_usuario

INNER JOIN estados E
ON E.id = P.estado

INNER JOIN municipios M
ON M.id = P.municipio

INNER JOIN experiencia Ex
ON Ex.id_usuario = P.id_usuario

INNER JOIN oferta_laboral O
ON O.id_usuario = P.id_usuario

INNER JOIN actividades A
ON A.id_usuario = P.id_usuario
WHERE U.id = ${localStorage.getItem('id_Usuario')}
`

    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getTabla`)
      .subscribe(
        (rsp: any) => {
          this.datos_usuario.inputNombre = rsp.parameters[0].Nombre

          this.datos_usuario.inputApellido = rsp.parameters[0].Apellido
          this.datos_usuario.inputEstado = rsp.parameters[0].Estado
          this.datos_usuario.inputMunicipio = rsp.parameters[0].Municipio

          this.rednder.inputExp = rsp.parameters[0].Experiencia
          this.rednder.inputDias = rsp.parameters[0].Dias
          this.rednder.inputSalario = rsp.parameters[0].Salario
          this.salaryOptions.value = Number(rsp.parameters[0].Salario)
          this.rednder.inputFoto = rsp.parameters[0].url_foto
          this.url = this.rednder.inputFoto
          this.rednder.inputReferecencias = !!rsp.parameters[0].Referencias
          this.rednder.inputFrecuencia = !!Number(rsp.parameters[0].Frecuencia)
          this.rednder.inputOtra = !!Number(rsp.parameters[0].Otra_cuidad)
          this.rednder.inputTelefono = rsp.parameters[0].Telefono
          this.rednder.limpieza = !!Number(rsp.parameters[0]['Si-Limpieza'])
          this.rednder.lavar = !!Number(rsp.parameters[0]['Si-Laba'])
          this.rednder.cocinar = !!Number(rsp.parameters[0]['Si-Cocinar'])
          this.rednder.ninos = !!Number(rsp.parameters[0]['Si-Ninos'])
          this.rednder.adultosMayores = !!Number(
            rsp.parameters[0]['Si-Adultos']
          )
          this.rednder.ex_limpieza = !!Number(
            rsp.parameters[0]['Si-Limpieza-Ex']
          )
          this.rednder.ex_lavar = !!Number(rsp.parameters[0]['Si-Laba-Ex'])
          this.rednder.ex_cocinar = !!Number(rsp.parameters[0]['Si-Cocinar-Ex'])
          this.rednder.ex_ninos = !!Number(rsp.parameters[0]['Si-Ninos-Ex'])
          this.rednder.ex_adultos = !!Number(rsp.parameters[0]['Si-Adultos-Ex'])
          this.rednder.sexo = rsp.parameters[0].sexo === 'Mujer'
          var dateObject = new Date(rsp.parameters[0].Fecha_nacimiento);
         
          console.log(dateObject.toISOString().slice(0, 10))
          this.rednder.fechaNacimiento = dateObject.toISOString().slice(0, 10)
          this.selectedState = rsp.parameters[0].Estado_id
          this.selectedMuniciple = rsp.parameters[0].Municipio_id
          this.selectedStateWork = rsp.parameters[0].Estado_id_work
          this.selectedMunicipleWork = rsp.parameters[0].Municipio_id_work

          this.states = states
          this.municiples = municiples

          this.weekDays[1].value = !!Number(rsp.parameters[0].L)
          this.weekDays[2].value = !!Number(rsp.parameters[0].Ma)
          this.weekDays[3].value = !!Number(rsp.parameters[0].Mi)
          this.weekDays[4].value = !!Number(rsp.parameters[0].J)
          this.weekDays[5].value = !!Number(rsp.parameters[0].V)
          this.weekDays[6].value = !!Number(rsp.parameters[0].S)
          this.weekDays[7].value = !!Number(rsp.parameters[0].D)

          this.onStateChange(this.selectedState)
          this.onStateChange(this.selectedStateWork, true)
          this.loading.hide();
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  async getStatesAndMuniciples() {
    const states = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM estados ORDER BY description ASC`,
        `getStates`
      )
      .toPromise()

    const municiples = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM municipios ORDER BY name ASC`,
        `getMuniciples`
      )
      .toPromise()

    return {
      states: (states as any)?.parameters,
      municiples: (municiples as any)?.parameters,
    }
  }

  async onStateChange(stateId: string | number, isWork: boolean = false) {
    if (isWork) {
      return (this.availableMuniciplesWork = this.municiples.filter(
        (municiple) => municiple.id_estados === stateId
      ))
    }

    this.availableMuniciples = this.municiples.filter(
      (municiple) => municiple.id_estados === stateId
    )
  }

  saveProfile() {
    const data = {
      ...this.datos_usuario,
      ...this.rednder,
      sexo: this.rednder.sexo ? 'Mujer' : 'Hombre',
      inputSalario: this.salaryOptions.value,
      inputEstado: this.states.find(
        (state: State) => state.id === this.selectedState
      )?.id,
      inputMunicipio: this.municiples.find(
        (municiple: Municiple) => municiple.id === this.selectedMuniciple
      )?.id,
      estadoTrabajo: this.selectedStateWork,
      municipioTrabajo: this.selectedMunicipleWork,
      inputFrecuencia: this.rednder.inputFrecuencia ? 1 : 0,
      inputOtra: this.rednder.inputOtra ? 1 : 0,
      inputReferecencias: this.rednder.inputReferecencias ? 1 : 0,
      lavar: this.rednder.lavar ? 1 : 0,
      limpieza: this.rednder.limpieza ? 1 : 0,
      cocinar: this.rednder.cocinar ? 1 : 0,
      ninos: this.rednder.ninos ? 1 : 0,
      adultosMayores: this.rednder.adultosMayores ? 1 : 0,
      ex_adultos: this.rednder.ex_adultos ? 1 : 0,
      ex_cocinar: this.rednder.ex_cocinar ? 1 : 0,
      ex_lavar: this.rednder.ex_lavar ? 1 : 0,
      ex_limpieza: this.rednder.ex_limpieza ? 1 : 0,
      ex_ninos: this.rednder.ex_ninos ? 1 : 0,
      L: this.weekDays[1].value ? 1 : 0,
      Ma: this.weekDays[2].value ? 1 : 0,
      Mi: this.weekDays[3].value ? 1 : 0,
      J: this.weekDays[4].value ? 1 : 0,
      V: this.weekDays[5].value ? 1 : 0,
      S: this.weekDays[6].value ? 1 : 0,
      D: this.weekDays[7].value ? 1 : 0,
    }

    const query = `
      UPDATE usuarios u, perfil p, experiencia e, oferta_laboral o, actividades a
      SET
      p.nombre = '${data.inputNombre}', p.apellido = '${data.inputApellido}',
      p.celular = '${data.inputTelefono}', 
      p.fecha_nacimiento = '${data.fechaNacimiento}', p.sexo = '${
      data.sexo
    }', p.estado = '${data.inputEstado}', p.municipio = '${
      data.inputMunicipio
    }',
      e.estado = '${data.estadoTrabajo}', e.municipio = '${
      data.municipioTrabajo
    }',
      o.dias = '${data.inputDias}', o.salario = '${
      data.inputSalario
    }', o.otra = '${data.inputOtra}',
      o.frecuencia = '${data.inputFrecuencia}', o.L = '${data.L}', o.Ma = '${
      data.Ma
    }',
      o.Mi = '${data.Mi}', o.J = '${data.J}', o.V = '${data.V}', o.S = '${
      data.S
    }',
      o.D = '${data.D}', a.limpieza = '${data.limpieza}', a.laba = '${
      data.lavar
    }',
      a.cocinar = '${data.cocinar}', a.ninos = '${data.ninos}', a.adultos = '${
      data.adultosMayores
    }',
      e.limpieza = '${data.ex_limpieza}', e.laba = '${data.ex_lavar}',
      e.cocinar = '${data.ex_cocinar}', e.ninos = '${
      data.ex_ninos
    }', e.adultos = '${data.ex_adultos}',
      e.referencias = '${data.inputReferecencias}'

      WHERE u.id = ${localStorage.getItem('id_Usuario')}
      AND p.id_usuario = u.id
      AND e.id_usuario = u.id
      AND o.id_usuario = u.id
      AND a.id_usuario = u.id
    `

    console.log(query)

    this.sqlGenericService
      .excecuteQueryStringReference(query, `update`)
      .subscribe(
        (rsp: any) => {
          this.alertService.successAlert('¡Bien hecho!', 'Perfil Actualizado')
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  goBack() {
    this.location.back()
  }

  async openSheet(): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: 'Imagenes',
      cssClass: 'action-sheets-olam',
      buttons: [
        {
          text: 'Tomar Foto',
          handler: () => {
            this.captureImage(true)
            
          },
        },
        {
          text: 'Elegir de la Biblioteca',
          handler: () => {
            this.captureImage(false)
          },
        },

        {
          text: 'delete',
          role: 'destructive',
          handler: () => {
            this.b64Image = ''
          },
        },
        {
          text: 'cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    })

    await actionSheet.present()

    await actionSheet.onDidDismiss()
  }
  async fileChange(evt) {
    let file: any = evt.target.files[0]
    if (file.size > 2000000) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg' &&
      file.type != 'image/svg+xml'
    ) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else {
      let fileB64 = await this.utilService.toBase64(file)
      this.b64Image = fileB64 as string
    }
  }

  async captureImage(isCamera: boolean = true) {
    
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
    })

    const fileName = `${this.userId}_${new Date().getTime()}`

    this.b64Image = 'data:image/jpeg;base64,' + image.base64String
    let requestFile: any = {
      b64: this.b64Image,
      id: this.userId,
      extension: 'png',
      table: 'perfil',
    }
this.url=this.b64Image
    this.genericService
      .sendPostRequest(`${environment.loadFileOnly}/${fileName}`, requestFile)
      .subscribe(
        async (responseFile: any) => {
          this.loading.show();
          await this.sqlGenericService
            .excecuteQueryStringReference(
              `
            UPDATE perfil
            SET url_foto = '${responseFile.parameters}'
            WHERE id_usuario = ${this.userId}
            `,
              'Update Image'
            )
            
            .subscribe((rsp: any)=>{
             if(rsp.description=='SUCCESSFUL'){
              setInterval(() => {
                this.loading.hide();
                this.changeDetectorRef.detectChanges()
              }, 1000);
             
             }
            
            })
            
        },
        
        (err: HttpErrorResponse) => {}
        
      )
   
    
  }


  sqlDelete(){


    
       let sqlFind: string = 
    `DELETE from usuarios WHERE id = '${localStorage.getItem('id_Usuario')}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`DeleteUser`).subscribe((rsp: any) => {
      localStorage.removeItem('userSession')
      this.router.navigate(['/', 'LoginMain'])
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
      

  
   
  
  
  }
}
