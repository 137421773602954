import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from './../../services/alert.service'

@Component({
  selector: 'app-saved',
  templateUrl: './saved.component.html',
  styleUrls: ['./saved.component.scss'],
})
export class SavedComponent implements OnInit {
  public saves = []

  constructor(
    private location: Location,
    private sqlGenericService: SqlGenericService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getSaves()
  }

  goBack() {
    this.location.back()
  }

  async getSaves() {
    const userId = localStorage.getItem('id_Usuario')
    const query = `
		SELECT
		P.id_usuario as id,
		P.nombre as name,
		P.apellido as lastname,
		P.url_foto as image,
		E.name as state,
		M.name as municiple
		FROM guardados G
		JOIN perfil P ON G.id_guardado = P.id_usuario
		JOIN estados E ON E.id = P.estado
    JOIN municipios M ON M.id = P.municipio
		WHERE G.id_usuario = ${userId}`

    const { parameters } = (await this.sqlGenericService
      .excecuteQueryStringReference(query, 'getSaves')
      .toPromise()) as any

    const blockedsQuery = `
      SELECT * FROM bloqueados
      WHERE id_usuario = ${userId}
    `

    const blockeds = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(blockedsQuery, 'getSaves')
        .toPromise()) as any
    ).parameters

    this.saves = parameters.map((save) => ({
      name: `${save.name} ${save.lastname}`,
      city: `${save.municiple}, ${save.state}`,
      image: save.image,
      user_id: save.id,
      blocked: !!blockeds.find((blocked) => blocked.id_bloqueado === save.id),
    }))
  }

  async deletePerson(person: { user_id: number }) {
    const query = `
      DELETE FROM guardados
      WHERE id_usuario = ${localStorage.getItem('id_Usuario')}
      AND id_guardado = ${person.user_id}
      `

    await this.sqlGenericService
      .excecuteQueryStringReference(query, 'deleteSave')
      .toPromise()

    this.alertService.successAlert(
      'Eliminado correctamente',
      'Se ha eliminado correctamente de tus guardados'
    )

    this.getSaves()
  }

  async createChat(person: { user_id: number }) {
    const userId = localStorage.getItem('id_Usuario')

    const subscriptionQuery = `
      SELECT * FROM subscriptions
      WHERE id_user = ${userId} AND
      status = 1
    `

    const subs = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(subscriptionQuery, 'checkSubscription')
        .toPromise()) as any
    ).parameters

    const checkQuery = `
    SELECT * FROM chats
    WHERE (id_usuario_1 = ${userId} AND id_usuario_2 = ${person.user_id})
    OR (id_usuario_1 = ${person.user_id} AND id_usuario_2 = ${userId})
    `

    this.sqlGenericService
      .excecuteQueryStringReference(checkQuery, 'checkChat')
      .subscribe(async (res: any) => {
        if (res.parameters.length > 0) {
          if (!subs.length) {
            window.sessionStorage.setItem('chat', JSON.stringify(person))
            return this.router.navigate(['subscription'])
          }
          this.router.navigate(['chat', person.user_id])
          return
        }
        const createQuery = `
        INSERT INTO chats
        (id_usuario_1, id_usuario_2) VALUES (${userId}, ${person.user_id})
        `

        await this.sqlGenericService
          .excecuteQueryStringReference(createQuery, 'createChat')
          .toPromise()

        if (!subs.length) {
          window.sessionStorage.setItem('chat', JSON.stringify(person))
          return this.router.navigate(['subscription'])
        }

        this.router.navigate(['chat', person.user_id])
      })
  }

  async unblockProfile(person) {
    const currentUserId = localStorage.getItem('id_Usuario')
    const blockedUserId = person.user_id

    const query = `
      DELETE FROM bloqueados WHERE id_usuario = ${currentUserId} AND id_bloqueado = ${blockedUserId}
    `

    await this.sqlGenericService
      .excecuteQueryStringReference(query, 'unblockProfile')
      .toPromise()
    this.getSaves()
    this.alertService.successAlert(
      'Perfil desbloqueado',
      'El perfil ha sido desbloqueado correctamente'
    )
  }
}
