import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController, NavParams } from '@ionic/angular'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-block-profile',
  templateUrl: './block-profile.component.html',
  styleUrls: ['./block-profile.component.scss'],
})
export class BlockProfileComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private navParams: NavParams,
    private sqlGenericService: SqlGenericService,
    private router: Router
  ) {}

  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss()
  }
  async blockProfile() {
    const currentUserId = localStorage.getItem('id_Usuario')
    const blockedUserId = this.navParams.get('userId')

    const query = `
      INSERT INTO bloqueados (id_usuario, id_bloqueado) VALUES (${currentUserId}, ${blockedUserId})
    `

    await this.sqlGenericService
      .excecuteQueryStringReference(query, 'blockProfile')
      .toPromise()

    this.modalCtrl.dismiss()
    this.router.navigateByUrl('/main')
    this.alertService.successAlert(
      'Perfil bloqueado',
      'El perfil ha sido bloqueado correctamente'
    )
  }
}
