import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-thanks-for-buying',
  templateUrl: './thanks-for-buying.component.html',
  styleUrls: ['./thanks-for-buying.component.scss'],
})
export class ThanksForBuyingComponent implements OnInit {
  constructor(private modalCtrl: ModalController, private router: Router) {}

  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss()
  }
  goChat() {
    this.modalCtrl.dismiss()
    const destinationChat = JSON.parse(window.sessionStorage.getItem('chat'))

    if (!destinationChat) {
      return this.router.navigate(['messages'])
    }
    this.router.navigate(['chat', destinationChat.user_id])
  }
}
