import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
@Component({
  selector: 'app-modal-ayuda-id',
  templateUrl: './modal-ayuda-id.component.html',
  styleUrls: ['./modal-ayuda-id.component.scss'],
})
export class ModalAyudaIdComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
