import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SqlGenericService } from '../../services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { Capacitor } from '@capacitor/core'
import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx'
import { signInWithCredential, signInWithPopup } from '@angular/fire/auth'
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'
import { LoaderService } from '../../services/loading-service'
@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  private regExp = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s])*$/g)
  public visto: boolean = true
  public visto2: boolean = true
  public platform: boolean
  public data: any = {
    email: {
      error: false,
      value: '',
    },
    pass: {
      error: false,
      value: '',
    },
    repass: {
      error: false,
      value: '',
    },
  }
  constructor(
    private alertService: AlertService,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private afAuth: AngularFireAuth,
    private googlePlus: GooglePlus,
    private fb: Facebook,
    private loadingService: LoaderService
  ) {}

  ngOnInit() {
    // if (localStorage.getItem('reload') == '1') {
    //   this.loadingService.show("Cargando...");
    //   location.reload()
    //   localStorage.setItem('reload', '2')
    //   this.loadingService.hide();
    // }
    if (Capacitor.getPlatform() === 'ios') {
      this.platform = true
    }
  }

  goInicio() {
    if (this.validarDatos()) {
      this.agregarUsuario()
    }
  }

  back() {
    window.history.back()
  }

  agregarUsuario() {
    let Servicio

    if (localStorage.getItem('Servicio') == 'master') {
      Servicio = 2
    } else {
      Servicio = 1
    }
    let email
    email = this.data.email.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
    let contrasena
    contrasena = this.data.pass.value.match(this.regExp)

    if (
      this.data.email.value == '' ||
      this.data.pass.value == '' ||
      this.data.repass.value == ''
    ) {
      this.alertService.errorAlert(
        'Opss....',
        'Existen Datos Vacios o Datos incorrectos'
      )
    } else {
      if (
        email != null &&
        contrasena != null &&
        this.data.pass.value == this.data.repass.value
      ) {
        let sqlInsert: string = `INSERT INTO usuarios (email,contrasena,tipo_usuario) VALUES (
      '${this.data.email.value}',
      SHA2(MD5(UNHEX(SHA2('${this.data.pass.value}',512))),
      224),
      '${Servicio}')`

        this.sqlGenericService
          .excecuteQueryStringReference(sqlInsert, `getEstados`)
          .subscribe(
            (rsp22: any) => {
              console.log(rsp22.parameters)
              if (rsp22.status == true) {
                // this.alertService.successAlert("Bien!", "Te hemos registrado con éxito");
                localStorage.setItem('id_Usuario', rsp22.parameters)
                localStorage.setItem('id_password', this.data.pass.value)
                this.router.navigateByUrl('/Celular')
              }
              if (rsp22.status == false) {
                // console.log(rsp22.parameters);
                this.data.email.error = rsp22.parameters
              }
            },
            (error: HttpErrorResponse) => {
              if (error.status != 200) {
                let sentences
                sentences = error.error.parameters
                let errorDuplicar
                errorDuplicar = sentences.includes('Duplicate')
                if (errorDuplicar) {
                  this.alertService.errorAlert(
                    'Opss....',
                    'Email ya Registrado'
                  )
                }
              }
            }
          )
      } else {
        this.alertService.errorAlert(
          'Opss....',
          'Datos incorrectos, Verifica tu Email o Password'
        )
      }
    }
  }

  change(dato) {
    //
    let id: any = document.getElementById('passo') as HTMLInputElement
    let Span: any = document.getElementById('iconpasso') as HTMLInputElement

    if (this.visto == true && dato == 1) {
      id.type = 'text'
      Span.innerText = 'visibility'
      this.visto = false
    } else {
      id.type = 'password'
      Span.innerText = 'visibility_off'
      this.visto = true
    }
    let id1: any = document.getElementById('repass') as HTMLInputElement
    let Span1: any = document.getElementById('iconpass1') as HTMLInputElement

    if (this.visto2 == true && dato == 2) {
      id1.type = 'text'
      Span1.innerText = 'visibility'
      this.visto2 = false
    } else {
      id1.type = 'password'
      Span1.innerText = 'visibility_off'
      this.visto2 = true
    }
  }
  async loginGoogle() {}

  async loginIos() {
    if (Capacitor.getPlatform() === 'web') {
      const appleProvider = new firebase.auth.OAuthProvider('apple.com')
      const res = await this.afAuth.signInWithPopup(appleProvider)
      const user = res.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken

      this.agregarUsuario()
    } else {
      let options: SignInWithAppleOptions = {
        clientId: 'mx.com.muchachapp',
        redirectURI: 'https://muchachapp-3aac9.firebaseapp.com/__/auth/handler',
        scopes: 'email name',
        state: '12345',
      }

      SignInWithApple.authorize(options)
        .then(async (result: SignInWithAppleResponse) => {
          const appleProvider = new firebase.auth.OAuthProvider('apple.com')
          const credential = appleProvider.credential({
            idToken: result.response.identityToken,
          })

          const userCredential = await this.afAuth.signInWithCredential(
            credential
          )

          const user = userCredential.user

          this.data.email.value = (user as any)._delegate.email
          this.data.pass.value = (user as any)._delegate.accessToken
          this.data.repass.value = (user as any)._delegate.accessToken

          this.agregarUsuario()
        })
        .catch((error) => {
          // Handle error
        })
    }
  }

  async loginFacebookAndroid() {
    if (Capacitor.getPlatform() === 'web') {
      const FaceBookProvider = new firebase.auth.FacebookAuthProvider()
      const res = await this.afAuth.signInWithPopup(FaceBookProvider)
      const user = res.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken

      this.agregarUsuario()
    } else {
      const res: FacebookLoginResponse = await this.fb.login([
        'public_profile',
        'email',
      ])

      const facebookCredential = firebase.auth.FacebookAuthProvider.credential(
        res.authResponse.accessToken
      )

      const resConfirmed = await this.afAuth.signInWithCredential(
        facebookCredential
      )

      const user = resConfirmed.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken

      this.agregarUsuario()
    }
  }

  async loginGoogleAndroid() {
    if (Capacitor.getPlatform() === 'web') {
      const res = await this.afAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
      const user = res.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken

      this.agregarUsuario()
    } else {
      const res = await this.googlePlus.login({
        webClientId:
          '1030105610609-h541c1i2j3fpap7ntpbhr9pf20h6d2f7.apps.googleusercontent.com',
        offline: true,
      })
      const resConfirmed = await this.afAuth.signInWithCredential(
        firebase.auth.GoogleAuthProvider.credential(res.idToken)
      )
      const user = resConfirmed.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken

      this.agregarUsuario()
    }
  }
  validarDatos() {
    let isValid = true
    // console.log(this.dataRender[1].fieldset[1].defaultValue)
    const terminos = document.getElementById('term') as HTMLInputElement
    console.log(terminos)
    if (terminos.checked == false) {
      isValid = false
      this.alertService.successAlert(
        'Datos',
        'No has Leido Terminos y Condiciones'
      )
    }
    console.log(isValid)
    console.log(terminos.checked)
    return isValid
  }
}
