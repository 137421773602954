import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { UnblockComponent } from 'src/app/components/unblock/unblock.component'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  public chats = []

  constructor(
    private location: Location,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.getChats()
  }

  getChats() {
    const userId = localStorage.getItem('id_Usuario')

    const query = `
      SELECT
      C.id, C.id_usuario_1, C.id_usuario_2,
      P1.nombre as 'name_1', P1.apellido as 'lastname_1',
      P2.nombre as 'name_2', P2.apellido as 'lastname_2',
      P1.url_foto as 'image_1', P2.url_foto as 'image_2'
      FROM chats C
      JOIN perfil P1 ON P1.id_usuario = C.id_usuario_1
      JOIN perfil P2 ON P2.id_usuario = C.id_usuario_2
      WHERE (C.id_usuario_1 = ${userId} OR C.id_usuario_2 = ${userId})
      `

    const blockedsQuery = `
      SELECT * FROM bloqueados
      WHERE id_usuario = ${userId}
    `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getChats')
      .subscribe(async (res: any) => {
        const response = res.parameters

        const blockeds = (
          (await this.sqlGenericService
            .excecuteQueryStringReference(blockedsQuery, 'getChats')
            .toPromise()) as any
        ).parameters

        this.chats = response.map((chat) => {
          const isUser1 = chat.id_usuario_1 === userId

          return {
            id: chat.id,
            name: isUser1
              ? `${chat.name_2} ${chat.lastname_2}`
              : `${chat.name_1} ${chat.lastname_1}`,
            idUser: isUser1 ? chat.id_usuario_2 : chat.id_usuario_1,
            image: isUser1 ? chat.image_2 : chat.image_1,
            body: chat.body,
            isImage: !!Number(chat.isImage),
            blocked: !!blockeds.find(
              (blocked) =>
                blocked.id_bloqueado ===
                (isUser1 ? chat.id_usuario_2 : chat.id_usuario_1)
            ),
          }
        })
      })
  }

  goBack() {
    this.location.back()
  }
  async goChat(chat) {
    if (chat.blocked) {
      const modalConfig = {
        component: UnblockComponent,
        backdropDismiss: false,
        componentProps: {
          userId: chat.idUser,
          getData: () => this.getChats(),
        },
      }

      const modal = await this.modalCtrl.create(modalConfig)
      modal.present()
      return
    }

    const userId = localStorage.getItem('id_Usuario')

    const subscriptionQuery = `
      SELECT * FROM subscriptions
      WHERE id_user = ${userId} AND
      status = 1
    `

    const subs = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(subscriptionQuery, 'checkSubscription')
        .toPromise()) as any
    ).parameters

    if (!subs.length) {
      this.router.navigate(['chat', chat?.id])
      return
    }

    this.router.navigate(['chat', chat?.id])
  }
}
