import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
import { ModalUsuariosComponent } from './../modal-usuarios/modal-usuarios.component';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
declare var $: any;
declare var ExcelJS;
declare var saveAs;
@Component({
  selector: 'app-crm-paquetes',
  templateUrl: './crm-paquetes.component.html',
  styleUrls: ['./crm-paquetes.component.scss'],
})
export class CrmPaquetesComponent implements OnInit {
public datosPaquetes:any[]
public supscriptionRender: any = 
{
  inputPlan: {
  required: true,
  placeholder: '.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },
  inputStatus: {
  required: true,
  placeholder: 'Descripcion.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },
  inputId: {
  required: true,
  placeholder: 'Id.....',
  value: null,
  error: false,
  type: 'text',
  length: 100
  },
  


};



  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private utilService: UtilService,
  ) { 
    setTimeout(function () {
      $(function () {
        $('#dt').DataTable(
          {
            'bDestroy': true,

            "bInfo": true,
            "bProcessing": true,
            "bDeferRender": true,
            'iDisplayLength': 10,
            'sPaginationType': 'full_numbers',
            'sDom': '<"top"i> T<"clear">lfrtip',
            'sPageButtonActive': "paginate_active",
            'sPageButtonStaticDisabled': "paginate_button",
            "scrollY": "300px",
            "scrollCollapse": true,
          }
        );
      });
    }, 2000); 
  }

  ngOnInit() {

this.sqlSelectUsuarios()

  }
  async sqlSelectUsuarios(){
    let sqlFind: string = 
    `SELECT *  
    FROM  subscriptions S 
    JOIN perfil P ON S.id_user = P.id_usuario
    `;

    let response: any = await this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getAllSupcriptions`).toPromise();
    this.datosPaquetes=response.parameters


  }

  exportXls(){

    let wb = new ExcelJS.Workbook();
    let workbookName = 'temp.xlsx';
    let worksheetName = 'Paquetes';
    let ws = wb.addWorksheet(worksheetName, {
      properties: {
        tabColor: { argb: 'FFFF0000' },
      },
    });
    ws.columns = [
      { header: 'FECHA', key: 'v', width: 25 },
      { header: 'CLIENTE', key: 'CLIENTE', width: 50 },
      { header: 'PLAN', key: 'SERVICIO', width: 65, outlineLevel: 1 },
      { header: 'DIAS', key: 'PLAN', width: 65, outlineLevel: 1 },
      { header: 'STATUS ', key: 'COMISION', width: 24, outlineLevel: 1 },  
    ];
    ['A', 'B', 'C', 'D', 'E'].forEach((element, index) => {
      this.datosPaquetes.forEach((itm: any, indexData) => {
        switch (element) {
          case 'A':
            ws.getCell(`${element}1`).value = 'FECHA VENTA';
            ws.getCell(`${element}1`).style =
              this.utilService.excelJSCommonHeader();
            break;
          case 'B':
            ws.getCell(`${element}1`).value = 'CLIENTE';
            ws.getCell(`${element}1`).style =
              this.utilService.excelJSCommonHeader();
            break;
          case 'C':
            ws.getCell(`${element}1`).value = 'SERVICIO';
            ws.getCell(`${element}1`).style =
              this.utilService.excelJSCommonHeader();
            break;
          case 'D':
            ws.getCell(`${element}1`).value = 'PLAN';
            ws.getCell(`${element}1`).style =
              this.utilService.excelJSCommonHeader();
            break;
          case 'E':
            ws.getCell(`${element}1`).value = 'STATUS';
            ws.getCell(`${element}1`).style =
              this.utilService.excelJSCommonHeader();
            break;
         
        }
    });
    });

    ['A', 'B', 'C', 'D', 'E'].forEach((element, index) => {
      this.datosPaquetes.forEach((itm: any, indexData) => {
        switch (element) {
          case 'A':
            ws.getCell(`${element}${indexData + 2}`).value = itm.start_date;
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case 'B':
            ws.getCell(`${element}${indexData + 2}`).value = itm.nombre;
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case 'C':
            ws.getCell(`${element}${indexData + 2}`).value = itm.plan;
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case 'D':
            ws.getCell(`${element}${indexData + 2}`).value = itm.validity;
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
            break;
          case 'E':
            ws.getCell(`${element}${indexData + 2}`).value = itm.status ;
            ws.getCell(`${element}${indexData + 2}`).style =
              this.utilService.excelJSCommonColumn();
           
            break;
         
        }
      });
    });
    console.log(ws)
    wb.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        workbookName
      );
    });
  }
  back() {
    window.history.back();
  }
}
