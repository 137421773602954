import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable(
    {
        providedIn: "root"
    }
)
export class AuthGuard implements CanActivate, CanActivateChild {
    public dato: boolean=true;
    constructor(
        private router: Router,

    ) {

    }

    canActivateChild() {
        return this.check();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.check()==true) {
            return true;
        }
            else{
            return this.router.navigate(["/","BuscarServicio"]);}
    }

    check() {
        let userSession: any = JSON.parse(localStorage.getItem("userSession"));
        if (userSession) {
            return true;
        } else {
            let recordar: any =document.getElementById('flexSwitchCheckChecked')
            console.log(recordar)
            if (recordar==undefined){
                return false
            }
            if(!recordar.checked){
                return true;
            }
            
        }
        let recordar: any =document.getElementById('flexSwitchCheckChecked')
            console.log(recordar)
            if (recordar==undefined){
                return false
            }
            if(recordar){
                return true;
            }
    }

}