import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SqlGenericService } from '../../services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
import { HttpErrorResponse, HttpParams } from '@angular/common/http'
@Component({
  selector: 'app-config-crm',
  templateUrl: './config-crm.component.html',
  styleUrls: ['./config-crm.component.scss'],
})
export class ConfigCrmComponent implements OnInit {
  public isMenuOpen: boolean = true
  constructor(
    private alertService: AlertService,
    private router: Router,
    private sqlGenericService: SqlGenericService
  ) {}

  ngOnInit() {}

  goConfigCrm() {
    this.router.navigateByUrl('/ConfiguracionCrm')
  }

  goConfigCrmPaquetes() {
    this.router.navigateByUrl('/CrmPaquetes')
  }

  goUsuarios(bool) {
    if (bool == 'true') {
      this.router.navigateByUrl('/UsuariosCrm/true')
    } else this.router.navigateByUrl('/UsuariosCrm/false')
  }
  removeSession() {
    localStorage.removeItem('userSession')
    this.router.navigate(['/', 'BuscarServicio'])
  }

  goChats() {
    this.router.navigateByUrl('/AdminChats')
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }
}
