import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController, NavParams } from '@ionic/angular'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-unblock',
  templateUrl: './unblock.component.html',
  styleUrls: ['./unblock.component.scss'],
})
export class UnblockComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private navParams: NavParams,
    private sqlGenericService: SqlGenericService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  async unblockProfile() {
    const currentUserId = localStorage.getItem('id_Usuario')
    const blockedUserId = this.navParams.get('userId')
    const getData = this.navParams.get('getData')

    const query = `
      DELETE FROM bloqueados WHERE id_usuario = ${currentUserId} AND id_bloqueado = ${blockedUserId}
    `

    await this.sqlGenericService
      .excecuteQueryStringReference(query, 'unblockProfile')
      .toPromise()

    this.modalCtrl.dismiss()
    getData()
    this.alertService.successAlert(
      'Perfil desbloqueado',
      'El perfil ha sido desbloqueado correctamente'
    )
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }
}
