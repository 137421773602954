import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FAQs } from './faqs'
import { Router } from '@angular/router'

@Component({
	selector: 'app-faqs',
	templateUrl: './faqs.component.html',
	styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
	public faqs = FAQs

	constructor(private location: Location, private router: Router) {}

	ngOnInit() {}

	goBack() {
		this.location.back()
	}

	getFaqDetail(index: number) {
		this.router.navigate(['/FAQ-Answer', index])
	}
}
