import { Location } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { ActionSheetController } from '@ionic/angular'
import { environment } from 'src/environments/environment.prod'
import { UtilService } from 'src/app/services/util.service'
import { GenericService } from 'src/app/services/generic.service'
import { Router } from '@angular/router'
import { LoaderService } from 'src/app/services/loading-service';
type State = {
  id: string
  name: string
  description: string
}

type Municiple = {
  id: string
  name: string
  id_estados: string
  description: string
}

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class ProfileMasterComponent implements OnInit {
  public url: any
  public b64Image: any = ''

  public salaryOptions = {
    value: 0,
    options: {
      floor: 0,
      ceil: 6000,
      step: 100,
      showTicks: true,
      translate: (value: number): string => {
        return new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value)
      },
    },
  }

  public weekDays = {
    1: {
      label: 'L',
      value: false,
    },
    2: {
      label: 'Ma',
      value: false,
    },
    3: {
      label: 'Mi',
      value: false,
    },
    4: {
      label: 'J',
      value: false,
    },
    5: {
      label: 'V',
      value: false,
    },
    6: {
      label: 'S',
      value: false,
    },
    7: {
      label: 'D',
      value: false,
    },
  }

  public states: State[] = []
  public municiples: Municiple[] = []
  public availableMuniciples: Municiple[] = []

  public selectedState: number | string = 0
  public selectedMuniciple: number | string = 0

  public datos_usuario: any = {
    inputNombre: '',
    inputApellido: '',
    inputEstado: '',
    inputMunicipio: '',
  }

  public rednder: any = {
    inputExp: '',
    inputReferecencias: '',
    inputFrecuencia: '',
    inputDias: '',
    inputSalario: '',
    inputOtra: '',
    inputFoto: '',
    inputNoNinos: '',
    inputNoAdultos: '',
    inputNoMascota: '',
    inputhabitaciones: '',
    inputbanos: '',
    inputJardin: '',
    inputTerraza: '',
    inputTelefono: '',
    adultosMayores: '',
    ninos: '',
    limpieza: '',
    cocinar: '',
    lavar: '',
  }

  public userId: string = ''

  constructor(
    private location: Location,
    private genericService: GenericService,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private actionSheetController: ActionSheetController,
    private changeDetectorRef: ChangeDetectorRef,
    private utilService: UtilService,
    private router: Router,
    private loading:LoaderService,
  ) {}
  ngOnDestroy() {
    console.log('Items destroyed');
    this.router.navigateByUrl('/main',)
  }
  ngOnInit() {
    this.userId = localStorage.getItem('id_Usuario')
    this.sql()
  }

  async sql() {
    this.loading.show();
    const { states, municiples } = await this.getStatesAndMuniciples()
    let sqlFind: string = `SELECT
  U.id as 'Id_Usuario',

  P.nombre as 'Nombre',
  P.apellido as 'Apellido',
  P.celular as 'Telefono',
  P.url_foto as 'url_foto',
  P.adultos as 'Adultos',
  P.ninos as 'Ninos',
  P.mascotas as 'mascotas',
  P.sexo as 'sexo',
  P.estado as 'Estado_id',
  P.municipio as 'Municipio_id',
  
  E.name as 'Estado',
  M.name as 'Municipio',
  
  h.habitaciones as 'habitaciones',
  h.banos as 'banos',
  h.jardin as 'jardin',
  h.terraza as 'terraza',
  
  O.dias as 'Dias',
  O.salario as 'Salario',
  O.otra as 'Otra_cuidad',
  O.frecuencia as 'Frecuencia',
  O.L as 'L',
  O.Ma as 'Ma',
  O.Mi as 'Mi',
  O.J as 'J',
  O.V as 'V',
  O.S as 'S',
  O.D as 'D',
    
  A.limpieza as 'Si-Limpieza',
  A.laba as 'Si-Laba',
  A.cocinar as 'Si-Cocinar',
  A.ninos as 'Si-Ninos',
  A.adultos as 'Si-Adultos'
  
FROM usuarios U 
INNER JOIN perfil P
ON U.id = P.id_usuario

INNER JOIN estados E
ON E.id = P.estado

INNER JOIN municipios M
ON M.id = P.municipio

INNER JOIN hogar h
ON h.id_usuario = P.id_usuario

INNER JOIN oferta_laboral O
ON O.id_usuario = P.id_usuario

INNER JOIN actividades A
ON A.id_usuario = P.id_usuario
WHERE U.id = ${localStorage.getItem('id_Usuario')}
`

    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getTabla`)
      .subscribe(
        (rsp: any) => {
          this.datos_usuario.inputNombre = rsp.parameters[0].Nombre

          this.datos_usuario.inputApellido = rsp.parameters[0].Apellido
          this.datos_usuario.inputEstado = rsp.parameters[0].Estado
          this.datos_usuario.inputMunicipio = rsp.parameters[0].Municipio

          this.rednder.inputTerraza = !!Number(rsp.parameters[0].terraza)
          this.rednder.inputJardin = !!Number(rsp.parameters[0].jardin)
          this.rednder.inputbanos = rsp.parameters[0].banos
          this.rednder.inputhabitaciones = rsp.parameters[0].habitaciones
          this.rednder.inputNoMascota = rsp.parameters[0].mascotas
          this.rednder.inputNoAdultos = rsp.parameters[0].Adultos
          this.rednder.inputNoNinos = rsp.parameters[0].Ninos
          this.rednder.inputDias = rsp.parameters[0].Dias
          this.rednder.inputSalario = rsp.parameters[0].Salario
          this.salaryOptions.value = Number(rsp.parameters[0].Salario)
          this.rednder.inputFoto = rsp.parameters[0].url_foto
          this.url = this.rednder.inputFoto
          this.rednder.inputReferecencias = !!rsp.parameters[0].Referencias
          this.rednder.inputFrecuencia = !!Number(rsp.parameters[0].Frecuencia)
          this.rednder.inputOtra = !!Number(rsp.parameters[0].Otra_cuidad)
          this.rednder.inputTelefono = rsp.parameters[0].Telefono
          this.rednder.limpieza = !!Number(rsp.parameters[0]['Si-Limpieza'])
          this.rednder.lavar = !!Number(rsp.parameters[0]['Si-Laba'])
          this.rednder.cocinar = !!Number(rsp.parameters[0]['Si-Cocinar'])
          this.rednder.ninos = !!Number(rsp.parameters[0]['Si-Ninos'])
          this.rednder.adultosMayores = !!Number(
            rsp.parameters[0]['Si-Adultos']
          )
          this.rednder.sexo = rsp.parameters[0].sexo === 'Mujer'
         
         
          
          
          this.selectedState = rsp.parameters[0].Estado_id
          this.selectedMuniciple = rsp.parameters[0].Municipio_id

          this.states = states
          this.municiples = municiples

          this.weekDays[1].value = !!Number(rsp.parameters[0].L)
          this.weekDays[2].value = !!Number(rsp.parameters[0].Ma)
          this.weekDays[3].value = !!Number(rsp.parameters[0].Mi)
          this.weekDays[4].value = !!Number(rsp.parameters[0].J)
          this.weekDays[5].value = !!Number(rsp.parameters[0].V)
          this.weekDays[6].value = !!Number(rsp.parameters[0].S)
          this.weekDays[7].value = !!Number(rsp.parameters[0].D)

          this.onStateChange(this.selectedState)
          this.loading.hide();
        
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  async getStatesAndMuniciples() {
    const states = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM estados ORDER BY description ASC`,
        `getStates`
      )
      .toPromise()

    const municiples = await this.sqlGenericService
      .excecuteQueryStringReference(
        `SELECT * FROM municipios ORDER BY name ASC`,
        `getMuniciples`
      )
      .toPromise()

    return {
      states: (states as any)?.parameters,
      municiples: (municiples as any)?.parameters,
    }
  }

  async onStateChange(stateId: string | number) {
    console.log(stateId)
    this.availableMuniciples = this.municiples.filter(
      (municiple) => municiple.id_estados === stateId
    )
  }

  saveProfile() {
    const data = {
      ...this.datos_usuario,
      ...this.rednder,
      sexo: this.rednder.sexo ? 'Mujer' : 'Hombre',
      inputSalario: this.salaryOptions.value,
      inputEstado: this.states.find(
        (state: State) => state.id === this.selectedState
      )?.id,
      inputMunicipio: this.municiples.find(
        (municiple: Municiple) => municiple.id === this.selectedMuniciple
      )?.id,
      inputFrecuencia: this.rednder.inputFrecuencia ? 1 : 0,
      inputOtra: this.rednder.inputOtra ? 1 : 0,
      inputReferecencias: this.rednder.inputReferecencias ? 1 : 0,
      lavar: this.rednder.lavar ? 1 : 0,
      limpieza: this.rednder.limpieza ? 1 : 0,
      cocinar: this.rednder.cocinar ? 1 : 0,
      ninos: this.rednder.ninos ? 1 : 0,
      adultosMayores: this.rednder.adultosMayores ? 1 : 0,
      L: this.weekDays[1].value ? 1 : 0,
      Ma: this.weekDays[2].value ? 1 : 0,
      Mi: this.weekDays[3].value ? 1 : 0,
      J: this.weekDays[4].value ? 1 : 0,
      V: this.weekDays[5].value ? 1 : 0,
      S: this.weekDays[6].value ? 1 : 0,
      D: this.weekDays[7].value ? 1 : 0,
      inputJardin: this.rednder.inputJardin ? 1 : 0,
      inputTerraza: this.rednder.inputTerraza ? 1 : 0,
    }

    const query = `
        UPDATE usuarios u, perfil p, hogar h, oferta_laboral o, actividades a
        SET
        p.nombre = '${data.inputNombre}', p.apellido = '${data.inputApellido}',
        p.celular = '${data.inputTelefono}',
        p.adultos = '${data.inputNoAdultos}', p.ninos = '${data.inputNoNinos}',
        p.mascotas = '${data.inputNoMascota}', p.fecha_nacimiento = '${
      data.fechaNacimiento
    }',
        p.sexo = '${data.sexo}', p.estado = '${data.inputEstado}',
        p.municipio = '${data.inputMunicipio}', h.habitaciones = '${
      data.inputhabitaciones
    }',
        h.banos = '${data.inputbanos}', h.jardin = '${data.inputJardin}',
        h.terraza = '${data.inputTerraza}', o.salario = '${data.inputSalario}',
        o.otra = '${data.inputOtra}', o.frecuencia = '${data.inputFrecuencia}',
        o.L = '${data.L}', o.Ma = '${data.Ma}', o.Mi = '${data.Mi}',
        o.J = '${data.J}', o.V = '${data.V}', o.S = '${data.S}',
        o.D = '${data.D}', a.limpieza = '${data.limpieza}',
        a.cocinar = '${data.cocinar}', a.ninos = '${data.ninos}',
        a.adultos = '${data.adultosMayores}', a.laba = '${data.lavar}'

        WHERE u.id = ${localStorage.getItem('id_Usuario')}
        AND p.id_usuario = u.id
        AND h.id_usuario = u.id
        AND o.id_usuario = u.id
        AND a.id_usuario = u.id
      `

    console.log(query)

    this.sqlGenericService
      .excecuteQueryStringReference(query, `update`)
      .subscribe(
        (rsp: any) => {
          this.alertService.successAlert('¡Bien hecho!', 'Perfil Actualizado')
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  addOne(key: string) {
    this.rednder[key] = Number(this.rednder[key]) + 1
  }
  removeOne(key: string) {
    this.rednder[key] = Number(this.rednder[key])
      ? Number(this.rednder[key]) - 1
      : 0
  }
  goBack() {
   this.location.back();
  }

  async openSheet(): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: 'Imagenes',
      cssClass: 'action-sheets-olam',
      buttons: [
        {
          text: 'Tomar Foto',
          handler: () => {
            this.captureImage(true)
          },
        },
        {
          text: 'Elegir de la Biblioteca',
          handler: () => {
            this.captureImage(false)
          },
        },

        {
          text: 'delete',
          role: 'destructive',
          handler: () => {
            this.b64Image = ''
          },
        },
        {
          text: 'cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    })

    await actionSheet.present()

    await actionSheet.onDidDismiss()
  }
  async fileChange(evt) {
    let file: any = evt.target.files[0]
    if (file.size > 2000000) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else if (
      file.type != 'image/png' &&
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg' &&
      file.type != 'image/svg+xml'
    ) {
      this.alertService.warnAlert('Ooops!', '', () => {})
    } else {
      let fileB64 = await this.utilService.toBase64(file)
      this.b64Image = fileB64
    }
  }

  async captureImage(isCamera: boolean = true) {
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
    })

    const fileName = `${this.userId}_${new Date().getTime()}`

    this.b64Image = 'data:image/jpeg;base64,' + image.base64String
    let requestFile: any = {
      b64: this.b64Image,
      id: this.userId,
      extension: 'png',
      table: 'perfil',
    }
    this.url=this.b64Image
    this.genericService
      .sendPostRequest(`${environment.loadFileOnly}/${fileName}`, requestFile)
      .subscribe(
        async (responseFile: any) => {
          await this.sqlGenericService
            .excecuteQueryStringReference(
              `
            UPDATE perfil
            SET url_foto = '${responseFile.parameters}'
            WHERE id_usuario = ${this.userId}
            `,
              'Update Image'
            )
            .toPromise()

          this.url = responseFile.parameters
        },
        (err: HttpErrorResponse) => {}
      )
    this.changeDetectorRef.detectChanges()
  }

  sqlDelete(){


    // this.alertService.confirmTrashAlert(()=>{
       let sqlFind: string = 
    `DELETE from usuarios WHERE id = '${localStorage.getItem('id_Usuario')}'`;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`DeleteUser`).subscribe((rsp: any) => {
      localStorage.removeItem('userSession')
      this.router.navigate(['/', 'LoginMain'])
      
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
      

    // },'Eliminar Usuario','Seguro que quiere eliminar el usuario','Aceptar')
   
  
  
  }

}
