import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Tabs } from 'src/app/components/tabs/tabs';
import { AdDirective } from 'src/app/directives/ad.directive';
import { UtilService } from 'src/app/services/util.service';
import { EventService } from '../../services/event.service';
import { Tab1Page } from './tab1/tab1.page';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit, OnDestroy{

  public tabs: Tabs[] = [{
    title: "Inicio",
    icon: "rocket",
    active: true,
    component: Tab1Page,
    url: "tab1",
    reload: true
  }];

  @ViewChild(AdDirective, { static: true }) adHost!: AdDirective;

  constructor(
    private utilService: UtilService,
    private router: Router,
    private translateService: TranslateService,
    private eventService: EventService
  ) { 
   //console.log("---->");
    
  }

  ngOnInit(){
  }

  ngOnDestroy(){
  }

  tabSelect(tab: Tabs) {
    console.log(tab);
    console.log("---------");
    
    this.router.navigateByUrl(`inicio/${tab.url}`);
    this.utilService.renderDynamicComponent(tab.component, this.adHost, tab.data);
  }
}
