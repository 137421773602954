import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { LocalStorageEncryptService } from 'src/app/services/local-storage-encrypt.service'
import { environment } from 'src/environments/environment.prod'
import { GenericService } from 'src/app/services/generic.service'
import { first, take } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import {
  Stripe,
  PaymentSheetEventsEnum,
  ApplePayEventsEnum,
} from '@capacitor-community/stripe'

Stripe.initialize({
  publishableKey: environment.st.keyPublic,
})
@Component({
  selector: 'app-busca-servcio',
  templateUrl: './busca-servcio.component.html',
  styleUrls: ['./busca-servcio.component.scss'],
})
export class BuscaServcioComponent implements OnInit {
  public servcio: any
  public pago: any[] = []
  public paymentIntent: any
  public ephemeralKey: any
  public customer: any

  constructor(
    private genericservice: GenericService,
    private router: Router,
    private localStorageEncryptService: LocalStorageEncryptService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    console.log(
      this.localStorageEncryptService.encryptBack(
        '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'
      )
    )
    console.log(
      this.localStorageEncryptService.yayirobe(
        '{"ct":"OwhNPWHW//Zs9YzQrowBU/SGVdrb1oHnG/6U7AlGAd2KIoZuZyLuZZMqMxbgxX2qr5qLVnBRIWngRyfrwE4fRvK1VkA4qU4hn4ha9WZEPIPZoLHKnCwvKQgjfPmsjkiz+W/kKSDEeh0RSqswOaL+6Q==","iv":"5cc870f6b4326638da4a8aa05245e790","s":"d02ac9c48552f0a2"}'
      )
    )

    if (window.localStorage.getItem('user') && window.localStorage.getItem('id_Usuario')) {
      this.router.navigateByUrl('/main')  
    }
  }

  goInicioMaster() {
    this.servcio = 'master'
    this.router.navigateByUrl('/LoginMain')
    localStorage.setItem('Servicio', this.servcio)
  }
  goInicioMaid() {
    this.servcio = 'maid'
    this.router.navigateByUrl('/LoginMain')
    localStorage.setItem('Servicio', this.servcio)
  }

  async SelecionarServicio() {
    this.apple()
    this.stripe()
    //     const body = {
    //       name: 'Person Name',
    //       currency: 'USD',
    //       email: 'email@gmail.com',
    //       amount: 99.99
    //   };

    //   const { paymentIntent, ephemeralKey, customer } = this.genericservice.sendPostRequest('https://inventory-app.egasystems.com/api_ecommerce/ecommerce/token',body).pipe(first()).toPromise(Promise);

    // //  await Stripe.createPaymentSheet({
    // //   paymentIntentClientSecret: this.pago['customer'],
    // //   customerId: this.pago.customer,
    // //   customerEphemeralKeySecret: this.pago.ephemeralKey,
    // //   merchantDisplayName: 'Ionic plugins expert'
    // // });

    // // const {paymentResult} = await Stripe.presentPaymentSheet();
    // // Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
    // //   console.log('PaymentSheetEventsEnum.Completed');
    // // });
  }
  stripe() {
    ;(async () => {
      // be able to get event of PaymentSheet
      Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
        console.log('PaymentSheetEventsEnum.Completed')
      })
      const body = {
        name: 'Person Name',
        currency: 'eur',
        email: 'email@gmail.com',
        amount: 1099,
      }
      // Connect to your backend endpoint, and get every key.
      const { paymentIntent, ephemeralKey, customer } = await this.http
        .post<{
          paymentIntent: string
          ephemeralKey: string
          customer: string
        }>(
          'https://inventory-app.egasystems.com/api_ecommerce/ecommerce/token',
          body
        )
        .pipe(first())
        .toPromise(Promise)

      // prepare PaymentSheet with CreatePaymentSheetOption.
      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: paymentIntent,
        customerId: customer,
        customerEphemeralKeySecret: ephemeralKey,
        merchantDisplayName: 'Ionic plugins expert',
      })

      // present PaymentSheet and get result.
      const result = await Stripe.presentPaymentSheet()
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        // Happy path
      }
    })()
  }
  apple() {
    ;(async () => {
      // Check to be able to use Apple Pay on device
      const isAvailable = Stripe.isApplePayAvailable().catch(() => undefined)
      if (isAvailable === undefined) {
        // disable to use Google Pay
        return
      }

      // be able to get event of Apple Pay
      Stripe.addListener(ApplePayEventsEnum.Completed, () => {
        console.log('ApplePayEventsEnum.Completed')
      })
      const body = {
        name: 'Person Name',
        currency: 'eur',
        email: 'email@gmail.com',
        amount: 1099,
      }
      // Connect to your backend endpoint, and get paymentIntent.
      const { paymentIntent } = await this.http
        .post<{
          paymentIntent: string
        }>(
          'https://inventory-app.egasystems.com/api_ecommerce/ecommerce/token',
          body
        )
        .pipe(first())
        .toPromise(Promise)

      // Prepare Apple Pay
      await Stripe.createApplePay({
        paymentIntentClientSecret: paymentIntent,
        paymentSummaryItems: [
          {
            label: 'Product Name',
            amount: 1099,
          },
        ],
        merchantIdentifier: 'rdlabo',
        countryCode: 'US',
        currency: 'eur',
      })

      // Present Apple Pay
      const result = await Stripe.presentApplePay()
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        // Happy path
      }
    })()
  }
}
