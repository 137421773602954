import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { Location } from '@angular/common'
import { twilio } from 'src/environments/environment.prod'
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public phone = {
    error: false,
    value: '',
  }
  public code = {
    0: '',
    1: '',
    2: '',
    3: '',
  }
  public updateData: boolean = false
  public stage: number = 3
  public newPassword: string = ''
  public confirmPassword: string = ''
  public check = {
    newPassword: false,
    confirmPassword: false,
  }
  public showPassword = {
    newPassword: false,
    confirmPassword: false,
  }
  public regExp = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s])*$/g)

  constructor(
    private alertService: AlertService,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private location: Location
  ) { }

  ngOnInit() { }

  onChange(field: string, value: string) {
    this[field].value = value
    this[field].error = !!value
  }

  goBack() {
    this.location.back()
  }

  sendMessage() {
    const sqlFind: string = `SELECT * FROM perfil WHERE celular = '${this.phone.value}'`
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `CodigoSms`)
      .subscribe(
        (rsp: any) => {
          if (rsp.parameters.length > 0) {
            const code = Math.floor(1000 + Math.random() * 9000)
            localStorage.setItem('passwordSMSCode', code.toString())
            localStorage.setItem('passwordUserId', rsp.parameters[0].id_usuario)

            fetch(
              'https://api.twilio.com/2010-04-01/Accounts/' + twilio.TWILIO_AUTH_TOKEN + '/Messages.json',
              {
                method: 'POST',
                headers: {
                  Authorization:
                    'Basic ' +
                    btoa(
                      twilio.TWILIO_ACCOUNT_SID
                    ),
                },
                body: new URLSearchParams({
                  MessagingServiceSid: twilio.MessagingServiceSid,
                  To: `+52${this.phone.value}`,
                  Body: `Mensaje SMS Muchachapp tu número de recuperación de contraseña es: ${code}`,
                }),
              }
            )

            this.stage = 2
          } else {
            this.alertService.errorAlert('SMS', 'El número no está registrado')
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  onCodeChange(value: string, index: number) {
    this.code[index] = value
    if (value.length > 0) {
      if (index < 3) {
        const next = document.getElementById(`code-${index + 2}`)
        next?.focus()
      }
    }
  }

  onKeydown(event: KeyboardEvent, index: number) {
    this.code[index] = ''
    if (event.key === 'Backspace' && index > 0) {
      const prev = document.getElementById(`code-${index}`)
      prev?.focus()
    }
  }

  comapreCode() {
    const code = `${this.code[0]}${this.code[1]}${this.code[2]}${this.code[3]}`
    if (code === localStorage.getItem('passwordSMSCode')) {
      this.stage = 3
    } else {
      this.alertService.errorAlert('SMS', 'El código no coincide')
    }
  }

  onChangePassword(field: string, value: string) {
    this[field] = value
    if (field === 'newPassword') {
      this.check.newPassword = this.regExp.test(value)
      this.check.confirmPassword = this.confirmPassword === value
      console.log('Check', this.check.newPassword, 'Value', this.regExp.test(value))
    }
    if (field === 'confirmPassword') {
      this.check.confirmPassword = this.newPassword === value && this.check.newPassword
    }
  }

  changePassword() {
    const userId = window.localStorage.getItem('passwordUserId')
    const query = `UPDATE usuarios SET contrasena = SHA2(MD5(UNHEX(SHA2('${this.newPassword}',512))),224) WHERE id = '${userId}'`
    this.sqlGenericService
      .excecuteQueryStringReference(query, 'changePassword')
      .subscribe(
        (rsp: any) => {
          this.alertService.successAlert(
            'Contraseña cambiada',
            'La contraseña se ha cambiado correctamente'
          )
          this.router.navigate(['/'])
        },
        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )

    localStorage.removeItem('passwordSMSCode')
    localStorage.removeItem('passwordUserId')
  }

  validarDatos() {
    let isValid = true

    if (this.phone.value == '') {
      isValid = false
      this.alertService.errorAlert('SMS', 'Campos Requeridos Sin llenar')
    }
    console.log(isValid)

    return isValid
  }
}
