import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  private regExp = new RegExp(
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s])*$/g
  )
  public oldPassword: string = ''
  public newPassword: string = ''
  public confirmPassword: string = ''
  public check = {
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  }
  public showPassword = {
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  }

  constructor(
    private location: Location,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  goBack() {
    this.location.back()
  }

  async changePassword() {
    if (
      this.check.oldPassword &&
      this.check.newPassword &&
      this.check.confirmPassword
    ) {
      const userId = window.localStorage.getItem('id_Usuario')
      const query = `SELECT * FROM usuarios WHERE contrasena = SHA2(MD5(UNHEX(SHA2('${this.oldPassword}',512))),224) AND id = '${userId}'`

      const { parameters } = (await this.sqlGenericService
        .excecuteQueryStringReference(query, 'checkPassword')
        .toPromise()) as any

      if (parameters.length > 0) {
				const query = `UPDATE usuarios SET contrasena = SHA2(MD5(UNHEX(SHA2('${this.newPassword}',512))),224) WHERE id = '${userId}'`

				await this.sqlGenericService
					.excecuteQueryStringReference(query, 'changePassword')
					.toPromise()

        this.alertService.successAlert(
          'Contraseña cambiada',
          'La contraseña se ha cambiado correctamente'
        )

				this.goBack()
      } else {
        this.alertService.errorAlert(
          'Error al cambiar la contraseña',
          'La contraseña actual no coincide'
        )
      }
    } else {
      this.alertService.errorAlert(
        'Error al cambiar la contraseña',
        'Todos los campos son obligatorios y deben cumplir con los requisitos'
      )
    }
  }

  onChange(field: string, value: string) {
    this[field] = value
    this.check[field] = !!value
    if (field === 'oldPassword') {
      this.check[field] = !!value
    }
    if (field === 'newPassword') {
      this.check[field] = value.length >= 6 && this.regExp.test(value)
    }
    if (field === 'confirmPassword') {
      this.check[field] = this.newPassword === value
    }
  }
}
