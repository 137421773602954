import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-no-plan-chat',
	templateUrl: './no-plan-chat.component.html',
	styleUrls: ['./no-plan-chat.component.scss'],
})
export class NoPlanChatComponent implements OnInit {
	constructor(private location: Location, private router: Router) {}

	ngOnInit() {}

	goBack() {
		this.location.back()
	}
	goSubscriptions() {
		this.router.navigate(['/subscription'])
	}
	goSaves() {
		this.router.navigate(['/saves'])
	}
}
