import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AlertService } from './../../services/alert.service'

@Component({
  selector: 'app-report-profile',
  templateUrl: './report-profile.component.html',
  styleUrls: ['./report-profile.component.scss'],
})
export class ReportProfileComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService
  ) {}

  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss()
  }
  sendReport() {
    this.modalCtrl.dismiss()
    this.alertService.successAlert(
      'Reporte enviado',
      'Gracias por ayudarnos a mantener la comunidad segura'
    )
  }
}
