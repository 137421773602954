import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
declare var $: any;
@Component({
  selector: 'app-crm-estados',
  templateUrl: './crm-estados.component.html',
  styleUrls: ['./crm-estados.component.scss'],
})
export class CrmEstadosComponent implements OnInit {
public create:boolean=true;
public datos:any[];
public estadosRender: any = 
{
inputId: {
required: true,
placeholder: 'id.....',
value: null,
error: false,
type: 'text',
length: 100
},

inputnombreEstado: {
required: true,
placeholder: 'nombreEstado.....',
value: null,
error: false,
type: 'text',
length: 100
},
inputdesEstado: {
required: true,
placeholder: 'Descripcion.....',
value: null,
error: false,
type: 'text',
length: 100
},

};
  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,
  ) { 
    setTimeout(function () {
      $(function () {
        $('#dt').DataTable(
          {
            'bDestroy': true,

            "bInfo": true,
            "bProcessing": true,
            "bDeferRender": true,
            'iDisplayLength': 10,
            'sPaginationType': 'full_numbers',
            'sDom': '<"top"i> T<"clear">lfrtip',
            'sPageButtonActive': "paginate_active",
            'sPageButtonStaticDisabled': "paginate_button",
            "scrollY": "300px",
            "scrollCollapse": true,
          }
        );
      });
    }, 1000); 
  }

  ngOnInit() {
    this.sqlSelect();
  }
  back() {
    window.history.back();
  }

  sqlInsert(){

let sqlFind: string = 
`INSERT INTO estados 
(name,description)
VALUES (
'${this.estadosRender.inputnombreEstado.value}',
'${this.estadosRender.inputdesEstado.value}'
)`;

this.sqlGenericService.excecuteQueryStringReference(sqlFind,`gethogar`).subscribe((rsp: any) => {

this.sqlSelect();
console.log(rsp.parameters[0].id );
},

(error: HttpErrorResponse) => {
if (error.status != 200) {
this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

}
})

  }



  sqlSelect(){
 let sqlFind: string = 
 `SELECT * FROM estados `;
 this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
 this.datos=rsp.parameters
console.log(this.datos)
   },
 
 (error: HttpErrorResponse) => {
 if (error.status != 200) {
 this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
 
 }
 }
 );

  }


sqlDelete(id){
  let sqlFind: string = 
  `DELETE from estados WHERE id = '${id}'`;
  this.sqlGenericService.excecuteQueryStringReference(sqlFind,`Deleteestados`).subscribe((rsp: any) => {
  this.sqlSelect();
    },
  
  (error: HttpErrorResponse) => {
  if (error.status != 200) {
  this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
  
  }
  }
  );


}


sqlUpdate(){
let sqlFind: string = 
`UPDATE  estados SET 
name = '${this.estadosRender.inputnombreEstado.value}', 
description= '${this.estadosRender.inputdesEstado.value}' 
WHERE id = '${this.estadosRender.inputId.value}'`;
this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
this.estadosRender.inputnombreEstado.value="";
this.estadosRender.inputdesEstado.value="";
this.estadosRender.inputId.value="";
this.create=true;
this.sqlSelect();
  },

(error: HttpErrorResponse) => {
if (error.status != 200) {
this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

}
}
);


}


update(){

  if(this.create==false){
    this.sqlUpdate();

  }else{
    this.sqlInsert();

  }

  

}

cambioestatus(event){

this.create=false
let sqlFind: string = 
`SELECT * FROM estados where id= ${event} `;
this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
  console.log(rsp.parameters[0]);
  this.estadosRender.inputnombreEstado.value=rsp.parameters[0].name;
  this.estadosRender.inputdesEstado.value=rsp.parameters[0].description;
  this.estadosRender.inputId.value=rsp.parameters[0].id;

  },

(error: HttpErrorResponse) => {
if (error.status != 200) {
this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

}
}
);


}





}
