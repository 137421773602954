import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-header',
  template: '<h1>Back Header</h1>',
  styleUrls: ['./back-header.component.scss'],
})
export class BackHeaderComponent implements OnInit {

  // @Input() title: string;

  constructor() { }

  ngOnInit() {}

}
