import { ModalIdComponent } from './../modal-id/modal-id.component';
import { ModalFotoComponent } from './../modal-foto/modal-foto.component';

import { Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { environment } from 'src/environments/environment.prod';
import { AlertService } from './../../services/alert.service';
import { UtilService } from 'src/app/services/util.service';
import { ChangeDetectorRef, Component, OnInit,OnDestroy } from '@angular/core';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { GenericService } from 'src/app/services/generic.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { LoaderService } from 'src/app/services/loading-service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
 public id_perfil: any
 public datos_estados:any[];
 public datos_municipios:any[];
 public visto: boolean = true;   
 public render: any = {
 inputNombre: {
 required: true,
 placeholder: 'Nombre.....',
 value: '',
 error: false,
 type: 'text',
 length: 100
 },
 inputApellido: {
 required: true,
 placeholder: 'Apellido.....',
 value: '',
 error: false,
 type: 'text',
 length: 100
 },
 inputNacimiento: {
 required: true,
 placeholder: 'Nacimiento.....',
 value: new Date().toISOString().slice(0, 10),
 error: false,
 type: 'text',
 length: 100
 },
 inputId: {
 required: true,
 placeholder: 'Id.....',
 value: "",
 error: false,
 type: 'text',
 length: 100
 },
 inputSexo: {
 required: true,
 placeholder: 'Sexo.....',
 value: "Mujer",
 error: false,
 type: 'text',
 length: 100
 },
 inputEstadoCivil: {
 required: true,
 placeholder: 'EstadoCivil.....',
 value: 1,
 error: false,
 type: 'text',
 length: 100
 },
 inputConNinos: {
 required: true,
 placeholder: 'ConNinos.....',
 value: 1,
 error: false,
 type: 'text',
 length: 100
 },
 
 
 
 
 inputTelefono: {
 required: true,
 placeholder: 'Telefono.....',
 value: null,
 error: false,
 type: 'text',
 length: 100
 },
 inputEmail: {
 required: true,
 placeholder: 'Email.....',
 value: null,
 error: false,
 type: 'text',
 length: 100
 },
 inputPass: {
 required: true,
 placeholder: 'Pass.....',
 value: null,
 error: false,
 type: 'text',
 length: 100
 },
 inputEstado: {
 required: true,
 placeholder: 'Estado.....',
 value: 'Mi Estado',
 error: false,
 type: 'text',
 length: 100
 },
 inputMunicipio: {
 required: true,
 placeholder: 'Municipio.....',
 value: 'Mi Municipio',
 error: false,
 type: 'text',
 length: 100
 },
 inputAdultos: {
 required: true,
 placeholder: 'Adultos.....',
 value: 0,
 error: false,
 type: 'text',
 length: 100
 },
 inputNinos: {
 required: true,
 placeholder: 'Ninos.....',
 value: 0,
 error: false,
 type: 'text',
 length: 100
 },
 inputMascotas: {
 required: true,
 placeholder: 'Mascota.....',
 value: 0,
 error: false,
 type: 'text',
 length: 100
 },
 inputFoto: {
 required: true,
 placeholder: 'Foto.....',
 value: '',
 error: false,
 type: 'text',
 length: 100
 },
 
 
 
 
 

};

public servcios: any;
public servicoB: boolean;
public b64Image: any = "";


  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private actionSheetController: ActionSheetController,
    private genericService:GenericService,
    private sqlGenericService:SqlGenericService,
    private loading:LoaderService,
  ) { }

  ngOnDestroy(){
    console.log("destroying child...")
  }
  ngOnInit() {
    const box = document.getElementsByClassName('mat-datepicker-popup');
    console.log(box)
    this.sqlSelectEstados();
    this.CargarDatosUsuario();
    this.servcios=localStorage.getItem("Servicio");
    if (this.servcios === "maid"){
      this.servicoB=true;
      console.log('true');
      
    }
    else{
      this.servicoB=false;
      console.log('false');
    }

  }


  async openPaypalModal(config?) {
    const modalConfig = {
      component: ModalFotoComponent,
      backdropDismiss: false,
    }

    if(config) {
      modalConfig['componentProps'] = config;

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    } else {
      //modalConfig['componentProps'] = { paypalUrl: this.paypalUrl };

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    }
  }


  async openModalId(config?) {
    const modalConfig = {
      component: ModalIdComponent,
      backdropDismiss: false,
    }

    if(config) {
      modalConfig['componentProps'] = config;

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        
      });
    } else {
      //modalConfig['componentProps'] = { paypalUrl: this.paypalUrl };

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data.data.id)
        this.render.inputId.value=data.data.id
        this.render.inputId.error=!!data.data.id
      });
    }
  }



  mostrarModal(){
    
    this.openPaypalModal()
  }
  mostrarModalId(){

  
    
    this.openModalId()
  }

  goInicio() {
    
    if (this.servcios === "maid"){
      if(this.validarDatos()){
   
      this.updateSql();
      this.router.navigateByUrl('/Experiencia');
      }
    }
    else{
      if(this.validarDatosmaster()){
      this.updateSql();
      this.router.navigateByUrl('/Hogar');
      }
  
}
  }
  back() {
    if (this.servcios === "maid"){
      if(this.validarDatos()){
   
      
      window.history.back();
      }
    }
    else{
    
      window.history.back();
    
  
}
    
  }

  async openSheet(): Promise<void> {

    const actionSheet = await this.actionSheetController.create({
      header: "Imagenes",
      cssClass: 'action-sheets-olam',
      buttons: [

        {
          text: "Tomar Foto",
          handler: () => {
            this.captureImage(true);
          },
        },
        {
          text: "Elegir de la Biblioteca",
          handler: () => {
            this.captureImage(false);
          },
        },
        
        {
          text: "delete",
          role: 'destructive',
          handler: () => {
            this.b64Image = "";
          },
        },
        {
          text: "cancel",
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          },
        },
      ],
    });


    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }
  async fileChange(evt) {
    let file: any = evt.target.files[0];
    if (file.size > 2000000) {
      this.alertService.warnAlert(
        "Ooops!",
        "",
        () => {
          ////console.log("hola");
        }
      );
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/svg+xml"
    ) {
      this.alertService.warnAlert(
        "Ooops!",
       "",
        () => {
          ////console.log("hola");
        }
      );
    } else {
      let fileB64 = await this.utilService.toBase64(file);
      this.b64Image = fileB64;
    }
  }


  async captureImage(isCamera: boolean = true) {
   // this.loading.show();
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos
    });
    
    this.b64Image = 'data:image/jpeg;base64,' + image.base64String;
    let requestFile: any = {
      b64:  this.b64Image ,
      id: this.id_perfil, //usuario de nueva creacion
      extension: "png",
      table: "perfil",
    };

    this.genericService
      .sendPostRequest(
        `${environment.loadFileOnly}/${this.id_perfil}`,
        requestFile
      )
      .subscribe(

        (responseFile: any) => {
         
          this.render.inputFoto.value=responseFile.parameters;
          this.render.inputFoto.error=!!responseFile.parameters;
          console.log(this.render.inputFoto.value)
          this.loading.hide();
          this.changeDetectorRef.detectChanges();
        },
        (err: HttpErrorResponse) => {
       
        }
      );
  

  }

  updateSql(){


    //const dateObject = new Date(value);
   // console.log(dateObject.toISOString().slice(0, 10))

    const dateObject = new Date(this.render.inputNacimiento.value);
     if(!dateObject){
      console.log(dateObject.toISOString().slice(0, 10))
      

     }   
    
      let sqlFind: string = 
      `UPDATE perfil SET 

          nombre = '${this.render.inputNombre.value}', 
          apellido = '${this.render.inputApellido.value}', 
          estado = '${this.render.inputEstado.value}',
          municipio = '${this.render.inputMunicipio.value}', 
          adultos = '${this.render.inputAdultos.value}',
          ninos = '${this.render.inputNinos.value}',
          mascotas = '${this.render.inputMascotas.value}', 
          
          fecha_nacimiento ='${dateObject.toISOString().slice(0, 10)}',
          sexo='${this.render.inputSexo.value}',
          identificacion='${this.render.inputId.value}',
          estado_civil='${this.render.inputEstadoCivil.value}',
          con_ninos='${this.render.inputConNinos.value}',
          url_foto='${this.render.inputFoto.value}'
          
          
          WHERE id_usuario = '${localStorage.getItem('id_Usuario')}'`;
    console.log(sqlFind)
      this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getperfil`).subscribe((rsp: any) => { },

      (error: HttpErrorResponse) => {
      if (error.status != 200) {
      this.alertService.errorAlert('Opps....', 'Tenemos un Problema');

      }
      })

  }


  addNumeros(ngmodel){
    if(ngmodel=== 3){
    this.render.inputMascotas.value=this.render.inputMascotas.value+1;
    }
    if(ngmodel=== 2){
      this.render.inputNinos.value=this.render.inputNinos.value+1;
      }
      if(ngmodel=== 1){
        this.render.inputAdultos.value=this.render.inputAdultos.value+1;
        }
  }
  removeNumeros(ngmodel){
    if(this.render.inputMascotas.value>0 && ngmodel==3){
    this.render.inputMascotas.value=this.render.inputMascotas.value-1;
    }
    if(this.render.inputNinos.value>0 && ngmodel==2){
      this.render.inputNinos.value=this.render.inputNinos.value-1;
      }
    if(this.render.inputAdultos.value>0 && ngmodel==1){
      this.render.inputAdultos.value=this.render.inputAdultos.value-1;
      }  
  }

  
  CargarDatosUsuario(){

   this.render.inputPass.value= localStorage.getItem('id_password');
   this.render.inputTelefono.value=localStorage.getItem('celular');
   let sqlFind: string = 
   `SELECT
			 
      U.id as 'Id_Usuario',
      U.email as 'Email',
      U.contrasena as 'Pass',
     
      P.id_usuario as 'id',
      P.nombre as 'Nombre',
      P.celular as 'Telefono'
     
     
      FROM usuarios U 
      INNER JOIN perfil P
      ON U.id = id_usuario
      WHERE U.id = ${localStorage.getItem('id_Usuario')}
      `;
   
   this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getusuario`).subscribe((rsp: any) => {
    
    this.render.inputEmail.value=rsp.parameters[0].Email;
    this.render.inputTelefono.value=rsp.parameters[0].Telefono;
    this.id_perfil=rsp.parameters[0].Id_Usuario
    ;
   
   
   },
   
   (error: HttpErrorResponse) => {
   if (error.status != 200) {
   this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
   
   }
   })

  }
  Onclick(event:any){
    console.log(event.target);

    (document.getElementById('pass') as HTMLInputElement).type = "text";  

  }

  change() {
    //
    let id: any = (document.getElementById("pass") as HTMLInputElement);
    let Span: any = (document.getElementById("iconpass") as HTMLInputElement);
    console.log(Span.innerText);
    if (this.visto==true) {
      id.type = "text";
      Span.innerText="visibility"
      this.visto = false;
      console.log(this.visto);
    } 
    else  {
      id.type = "password";
      Span.innerText="visibility_off"
      this.visto = true;
      console.log(this.visto);
    } 
   
  }


  datos(event:any,ngmodel){
    if(event.target.checked==true && ngmodel==1){
     this.render.inputSexo.value="Mujer"
     console.log("Sexo:",this.render.inputSexo.value);
    }
    if(event.target.checked==false && ngmodel==1){
     this.render.inputSexo.value="Hombre"
     console.log("Sexo:",this.render.inputSexo.value);
    }


    if(event.target.checked==true && ngmodel==2){
      this.render.inputEstadoCivil.value=1
      console.log("EstadoCivil:",this.render.inputEstadoCivil.value);
     }
     if(event.target.checked==false && ngmodel==2){
      this.render.inputEstadoCivil.value=0
      console.log("Estado Civil:",this.render.inputEstadoCivil.value);
     }

     if(event.target.checked==true && ngmodel==3){
      this.render.inputConNinos.value=1
      console.log("ConNinos:",this.render.inputConNinos.value);
     }
     if(event.target.checked==false && ngmodel==3){
      this.render.inputConNinos.value=0
      console.log("ConNinos:",this.render.inputConNinos.value);
     }
 
 
  
 
     
   }

   sqlSelectEstados(){
    let sqlFind: string = 
    `SELECT * FROM estados `;
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getestados`).subscribe((rsp: any) => {
    this.datos_estados=rsp.parameters
   console.log(this.datos_estados)
      },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    }
    );
   
     }

     sqlSelectMunicipios(texto){
      let sqlFind: string = 
      ` SELECT
         
      M.id as 'id',
      M.name as 'name',
      M.description as 'description',
        
      E.name as 'estado'
    
     
     
      FROM municipios M
      INNER JOIN estados E
      ON E.id =M.id_estados
      WHERE E.id = ${texto}
      `;
      this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getmunicipios`).subscribe((rsp: any) => {
        this.datos_municipios=rsp.parameters
        console.log(this.datos_municipios)
      
        },
      
      (error: HttpErrorResponse) => {
      if (error.status != 200) {
      this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
      
      }
      }
      );
  
  
    }

    moveToNext(event:any) {
   
      
      //console.log(event.target.selectedIndex )
      let index= event.target.selectedIndex

      console.log(event.target.options[index].innerHTML);
      console.log(event.target.options[index].value);
    this.sqlSelectMunicipios(event.target.options[index].value);
   }


   validarDatosmaster(){

    let isValid=true
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
  
   if(this.render.inputNombre.value==''|| this.render.inputApellido.value==''|| this.render.inputEstado.value=='Mi Estado'
   || this.render.inputMunicipio.value=='Mi Municipio'||  this.render.inputFoto.value==''
   ){
    isValid = false;
    this.alertService.successAlert('Datos','Por favor llena todos los campos')

   }
   else{
        return isValid;

   }
    }

  validarDatos(){

    let isValid=true
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
  
   if(this.render.inputNombre.value==''|| this.render.inputApellido.value==''|| this.render.inputEstado.value=='Mi Estado'
   || this.render.inputMunicipio.value=='Mi Municipio' ||  this.render.inputNacimiento.value==''||  this.render.inputId.value==''
   ||  this.render.inputFoto.value==''
   ){
    isValid = false;
    this.alertService.successAlert('Datos','Por favor llena todos los campos')
    return isValid;
   }
   else{
    return isValid;

   }
  
   
  }
  onChange(field: string, value: string) {

  

  

   
    if (field === 'Nombre') {
      this.render.inputNombre.error = !!value
    }
    if (field === 'Apellido') {
      this.render.inputApellido.error = !!value
    }
    if (field === 'Mi Estado') {
      this.render.inputEstado.error = !!value
    }
    if (field === 'Mi Municipio') {
      this.render.inputMunicipio.error = !!value
    }
    if (field === 'Fecha de Nacimiento') {
      this.render.inputNacimiento.error = !!value
    }
  }

}
