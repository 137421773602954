import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SqlGenericService } from 'src/app/services/sqlGenericService'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
	public userData: any = {}

  constructor(
    private router: Router,
    private location: Location,
    private sqlGenericService: SqlGenericService
  ) {}

  ngOnInit() {
    this.getCurrentUser()
  }

  async getCurrentUser() {
    const userId = localStorage.getItem('id_Usuario')
    const query = `
		SELECT * FROM usuarios U
		JOIN perfil P ON U.id = P.id_usuario
		WHERE U.id = ${userId}
		`

    const [user] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(query, 'getUser')
        .toPromise()) as any
    )?.parameters

    this.userData = user
  }

  goInformation() {
    this.router.navigate(['/information'])
  }
  goNotifications() {
    this.router.navigate(['/notifications'])
  }
  goFAQs() {
    this.router.navigate(['/FAQs'])
  }
  goContact() {
    this.router.navigate(['/contact'])
  }
  goChangePassword() {
    this.router.navigate(['/change-password'])
  }
  goBack() {
    this.location.back()
  }
}
