import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
	constructor(private location: Location, private router: Router) {}

	ngOnInit() {}

	goBack() {
		this.location.back()
	}
	goHelp() {
		this.router.navigate(['/help'])
	}
}
