import { ModalAyudaIdComponent } from './../modal-ayuda-id/modal-ayuda-id.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { LoaderService } from 'src/app/services/loading-service';
import { AlertService } from './../../services/alert.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
import { GenericService } from 'src/app/services/generic.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-modal-id',
  templateUrl: './modal-id.component.html',
  styleUrls: ['./modal-id.component.scss'],
})
export class ModalIdComponent implements OnInit {
  public b64Image: any = "";
  public rutab64Image: any = "";
  public id_perfil: any;
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private actionSheetController: ActionSheetController,
    private sqlGenericService:SqlGenericService,
    private loading:LoaderService,
    private utilService: UtilService,
    private genericService:GenericService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.id_perfil=localStorage.getItem('id_Usuario')
  }
  
  closeModal() {
    this.modalCtrl.dismiss();
  }
  async openModalId(config?) {
    const modalConfig = {
      component: ModalAyudaIdComponent,
      backdropDismiss: false,
    }

    if(config) {
      modalConfig['componentProps'] = config;

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    } else {
      //modalConfig['componentProps'] = { paypalUrl: this.paypalUrl };

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    }
  }



  mostrarModalId(){
    
    this.openModalId()
    this.closeModal()
  }



  async openSheet(): Promise<void> {

    const actionSheet = await this.actionSheetController.create({
      header: "Imagenes",
      cssClass: 'action-sheets-olam',
      buttons: [

        {
          text: "Tomar Foto",
          handler: () => {
            this.captureImage(true);
          },
        },
        {
          text: "Elegir de la Biblioteca",
          handler: () => {
            this.captureImage(false);
          },
        },
        
        {
          text: "delete",
          role: 'destructive',
          handler: () => {
            this.b64Image = "";
          },
        },
        {
          text: "cancel",
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          },
        },
      ],
    });


    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }
  async fileChange(evt) {
    let file: any = evt.target.files[0];
    if (file.size > 2000000) {
      this.alertService.warnAlert(
        "Ooops!",
        "",
        () => {
          ////console.log("hola");
        }
      );
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/svg+xml"
    ) {
      this.alertService.warnAlert(
        "Ooops!",
       "",
        () => {
          ////console.log("hola");
        }
      );
    } else {
      let fileB64 = await this.utilService.toBase64(file);
      this.b64Image = fileB64;
    }
  }


  async captureImage(isCamera: boolean = true) {
   // this.loading.show();
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos
    });
    
    this.b64Image = 'data:image/jpeg;base64,' + image.base64String;
    let requestFile: any = {
      b64:  this.b64Image ,
      id: this.id_perfil, //usuario de nueva creacion
      extension: "png",
      table: "perfil",
    };

    this.genericService
      .sendPostRequest(
        `${environment.loadFileOnly}/${this.id_perfil}`,
        requestFile
      )
      .subscribe(

        (responseFile: any) => {
          this.modalCtrl.dismiss({
            id: responseFile.parameters
          })
          // this.render.inputFoto.value=responseFile.parameters;
          // console.log(this.render.inputFoto.value)
          this.loading.hide();
          this.changeDetectorRef.detectChanges();
        },
        (err: HttpErrorResponse) => {
       
        }
      );
  

  }

}
