import { EventService } from 'src/app/services/event.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app'
//import { FCM } from '@ionic-native/fcm/ngx';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { emulado, remastered } from './../environments/environment.prod';
import { Menu } from './interfaces/menu.interface';
import { FcmService } from './services/fcm.service';
import { LoaderService } from './services/loading-service';
import { LocalStorageEncryptService } from './services/local-storage-encrypt.service';
import { SqlGenericService } from './services/sqlGenericService';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';

declare var FB: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {


  constructor(
    private router:Router,
    private localStorageEncryptService: LocalStorageEncryptService,
    private loadingService: LoaderService,
    private sqlGenericService: SqlGenericService,
    private fcmService: FcmService,
    private platform: Platform
  ) {
    const hideStatusBar = async () => {
      await StatusBar.hide();
    };
    window.addEventListener('statusTap', function () {
      console.log('statusbar tapped');
    });

    
     console.log(window)
    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('Handler was called!');
    });
   App.addListener('backButton',({ canGoBack }) => {
    if(!canGoBack){
     
    } else {
      
    }
  });

    if (remastered) {
   
    }

  }
 
  ngOnInit() {

    
console.log("---------")
console.log(this.localStorageEncryptService.getFromLocalStorage("token-muchachapp"));

    if (!emulado) {
      this.registerToken();
    } else {
      this.registerTokenFake();
    }
  }

  registerToken() {
    let registerToken: any = this.localStorageEncryptService.getFromLocalStorage("token-muchachapp");
    
    if (!registerToken) {
      this.fcmService.initPush();
    } else {
      //this.listenNotifications();
      this.fcmService.listenNotifications();
    }
  }

  registerTokenFake() {
    let registerToken: any = this.localStorageEncryptService.getFromLocalStorage("token-muchachapp");
    if (!registerToken) {
      //token is token of device
      let uuid: any = "fakeUuidConecTandem";
      let token: string = "fakeTokenConecTandem";
      let sql: string = `INSERT INTO usuario (uuid, token) VALUES ('${uuid}', '${token}')`;
      let sqlChecking: string = `SELECT * FROM usuario WHERE uuid = '${uuid}'`;

      this.loadingService.show();

      //consultar uuid en base de datos antes de registrar nuevo token 
      //Si encuentra el uuid se actualizará el token pero no creará nuevo usuario

      this.sqlGenericService.excecuteQueryString(sqlChecking).subscribe((resp: any) => {
        //console.log(resp);

        if (resp.parameters.length <= 0) {
          this.sqlGenericService.excecuteQueryString(sql, 3).subscribe((resp: any) => {
            //Se registra correctamente nuevo usuario
            this.loadingService.hide();
            this.localStorageEncryptService.setToLocalStorage("token-muchachapp", token);
            //this.fcm.subscribeToTopic('myGymGlobal');//se suscribe a notificaciones globales de la app
            //this.listenNotifications();
          }, (err: HttpErrorResponse) => {
            this.loadingService.hide();
          });
        } else {
          this.loadingService.hide();
        }
      }, (err: HttpErrorResponse) => {
        this.loadingService.hide();
      });
    } else {
     this.listenNotifications();
    }
  }

  listenNotifications() {
    /* this.fcm.onNotification().subscribe(data => {
     console.log(data);
      if(data.wasTapped){
       console.log("Received in background");
      } else {
       console.log("Received in foreground");
      };
    }); */
  }






}