import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
@Component({
  selector: 'app-sig-experiencia',
  templateUrl: './sig-experiencia.component.html',
  styleUrls: ['./sig-experiencia.component.scss'],
})
export class SigExperienciaComponent implements OnInit {
  public suma: any = {
    total: 0,
    validar: false,
  }

  public salaryOptions = {
    value: 0,
    options: {
      floor: 0,
      ceil: 6000,
      step: 100,
      showTicks: true,
      translate: (value: number): string => {
        return new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value)
      },
    },
  }
  public usuario: boolean = false
  public render: any = {
    inputFrecuencia: {
      required: true,
      placeholder: 'Fecuencia.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaL: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaMa: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaM: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaJ: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaV: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaS: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDiaD: {
      required: true,
      placeholder: 'Días.....',
      value: '',
      binario: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDias: {
      required: true,
      placeholder: 'Días.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },

    inputSalario: {
      required: true,
      placeholder: 'Salario.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100,
    },
    inputOtra: {
      required: true,
      placeholder: 'Otra.....',
      value: 0,
      error: false,
      type: 'text',
      length: 100,
    },
  }

  public servcios: any
  constructor(
    private sqlGenericService: SqlGenericService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.servcios = localStorage.getItem('Servicio')
    this.quitarclass()
    if (localStorage.getItem('Servicio') == 'master') {
      this.usuario = true
      console.log('master')
    }
  }

  quitarclass() {
    const radio = document.getElementById('flexRadioDefault1') as HTMLElement
    radio.style.removeProperty('background-image')
  }
  goInicio() {
    localStorage.setItem('registrado', 'true')
    if (this.servcios === 'maid') {
      if (this.validarDatos()) {
        this.sql()
        this.router.navigateByUrl('/Actividades')
      }
    } else {
      if (this.validarDatos()) {
        this.sql()
        this.router.navigateByUrl('/main')
      }
    }
  }
  back() {
    window.history.back()
  }
  datos(event: any, ngmodel) {
    //console.log(event.target.checked);

    if (event.target.checked == true && ngmodel == 1) {
      this.render.inputDiaL.value = 'L'
      this.render.inputDiaL.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaL:', this.render.inputDiaL.value)
    }
    if (event.target.checked == false && ngmodel == 1) {
      this.render.inputDiaL.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaL:', this.render.inputDiaL.value)
    }

    if (event.target.checked == true && ngmodel == 2) {
      this.render.inputDiaMa.value = 'Ma'
      this.render.inputDiaMa.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaMa:', this.render.inputDiaMa.value)
    }
    if (event.target.checked == false && ngmodel == 2) {
      this.render.inputDiaMa.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaMa:', this.render.inputDiaMa.value)
    }

    if (event.target.checked == true && ngmodel == 3) {
      this.render.inputDiaM.value = 'Mi'
      this.render.inputDiaM.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaMi:', this.render.inputDiaM.value)
    }
    if (event.target.checked == false && ngmodel == 3) {
      this.render.inputDiaM.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaMi:', this.render.inputDiaM.value)
    }

    if (event.target.checked == true && ngmodel == 4) {
      this.render.inputDiaJ.value = 'J'
      this.render.inputDiaJ.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaJ:', this.render.inputDiaJ.value)
    }
    if (event.target.checked == false && ngmodel == 4) {
      this.render.inputDiaJ.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaJ:', this.render.inputDiaJ.value)
    }

    if (event.target.checked == true && ngmodel == 5) {
      this.render.inputDiaV.value = 'V'
      this.render.inputDiaV.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaV:', this.render.inputDiaV.value)
    }
    if (event.target.checked == false && ngmodel == 5) {
      this.render.inputDiaV.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaV:', this.render.inputDiaV.value)
    }

    if (event.target.checked == true && ngmodel == 6) {
      this.render.inputDiaS.value = 'S'
      this.render.inputDiaS.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaS:', this.render.inputDiaS.value)
    }
    if (event.target.checked == false && ngmodel == 6) {
      this.render.inputDiaS.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaS:', this.render.inputDiaS.value)
    }

    if (event.target.checked == true && ngmodel == 7) {
      this.render.inputDiaD.value = 'D'
      this.render.inputDiaD.binario = 1
      this.suma.total = this.suma.total + 1
      console.log('DiaD:', this.render.inputDiaD.value)
    }
    if (event.target.checked == false && ngmodel == 7) {
      this.render.inputDiaD.value = ''
      this.suma.total = this.suma.total - 1
      console.log('DiaD:', this.render.inputDiaD.value)
    }

    if (event.target.checked == true && ngmodel == 8) {
      this.render.inputFrecuencia.value = 1
      console.log('Frecuencia:', this.render.inputFrecuencia.value)
    }
    if (event.target.checked == false && ngmodel == 8) {
      this.render.inputFrecuencia.value = 0
      console.log('Frecuencia:', this.render.inputFrecuencia.value)
    }

    if (event.target.checked == true && ngmodel == 9) {
      this.render.inputOtra.value = 1
      console.log('otra:', this.render.inputOtra.value)
    }
    if (event.target.checked == false && ngmodel == 9) {
      this.render.inputOtra.value = 0
      console.log('otra:', this.render.inputOtra.value)
    }

   // console.log(this.suma.total)
  //  console.log(event.target.checked, ngmodel, this.suma.total)

    // if(this.suma.total===1)
    //  this.suma.validar=!this.suma.total
    if (this.suma.total >= 0 && this.suma.total <= 1)
      this.suma.validar = !!this.suma.total

    if (this.suma.total <= 0 && this.suma.total >= 1)
      this.suma.validar = !this.suma.total
  }

  sql() {
    this.render.inputDias.value = ''
    this.render.inputDias.value =
      this.render.inputDiaL.value +
      ' ' +
      this.render.inputDiaMa.value +
      ' ' +
      this.render.inputDiaM.value +
      ' ' +
      this.render.inputDiaJ.value +
      ' ' +
      this.render.inputDiaV.value +
      ' ' +
      this.render.inputDiaS.value +
      ' ' +
      this.render.inputDiaD.value

    let sqlFind: string = `INSERT INTO oferta_laboral 
(id_usuario,frecuencia,dias,salario,otra,L,Ma,Mi,J,V,S,D)
VALUES (
'${localStorage.getItem('id_Usuario')}',
'${this.render.inputFrecuencia.value}',
'${this.render.inputDias.value}',
'${this.render.inputSalario.value}',
'${this.render.inputOtra.value}',
'${this.render.inputDiaL.binario}',
'${this.render.inputDiaMa.binario}',
'${this.render.inputDiaM.binario}',
'${this.render.inputDiaJ.binario}',
'${this.render.inputDiaV.binario}',
'${this.render.inputDiaS.binario}',
'${this.render.inputDiaD.binario}'
)`

    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getoferta_laboral`)
      .subscribe(
        (rsp: any) => {
          console.log(rsp.parameters[0].id)
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  validarDatos() {
    let isValid = true
    // console.log(this.dataRender[1].fieldset[1].defaultValue)

    if (this.render.inputSalario.value == 0) {
      isValid = false
      this.alertService.successAlert(
        'Datos',
        'Por favor llena todos los campos'
      )
    }
    console.log(isValid)

    return isValid
  }
}
