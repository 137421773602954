import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { Router } from '@angular/router'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
import { HttpErrorResponse } from '@angular/common/http'
import { LoaderService } from '../../services/loading-service';
import moment from 'moment';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, {  SwiperOptions, Virtual } from "swiper/core";
declare var Swiper: any
declare var $:any
SwiperCore.use([Virtual]);



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewInit  {

  public swiper: any =   
  new Swiper('.mySwiper', {
    virtual: {
    enabled: true,
    }})
  public status: boolean
  public tipoUsuario: boolean
  public url: any

  public isMenuOpen: boolean = false
  public isFiltersOpen: boolean = false
  public AsOnlinePerson: any
  public AsOnlineUser: any
  public people = []
  public total: number
  public userData: any = {}
  public isMaid: boolean = false
  public hasSubscription = false

  public rednder: any = {
    inputExp: {
      required: true,
      placeholder: 'Exp.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputReferecencias: {
      required: true,
      placeholder: 'Rerecencias.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputFrecuencia: {
      required: true,
      placeholder: 'Frecuencia.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputDias: {
      required: true,
      placeholder: 'Días.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputSalario: {
      required: true,
      placeholder: 'Salario.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputOtra: {
      required: true,
      placeholder: 'Otra.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputFoto: {
      required: true,
      placeholder: 'Foto.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputNoNinos: {
      required: true,
      placeholder: 'NoNinos.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputNoAdultos: {
      required: true,
      placeholder: 'NoAdultos.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputNoMascota: {
      required: true,
      placeholder: 'NoAdultos.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputhabitaciones: {
      required: true,
      placeholder: 'habitaciones.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputbanos: {
      required: true,
      placeholder: 'habitaciones.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputTerraza: {
      required: true,
      placeholder: 'Terraza.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
  }

  constructor(
    private sqlGenericService: SqlGenericService,
    private router: Router,
    private alertService: AlertService,
    private loadingService: LoaderService,
    private cd: ChangeDetectorRef,
  ) {}
  @ViewChild('.mySwiper',) swip?: SwiperComponent;
  swiperRef?: SwiperComponent;
  ngOnInit() {
    this.isMaid = localStorage.getItem('Servicio') === 'maid'
    this.checkSubscription()
    this.StatusLogin()
    this.getCurrentUser()
    this.getPeople()
    
  }
  
  
  

  ngAfterViewInit() {
   this.cd.detectChanges;
  }

  goInicio() {
    this.router.navigateByUrl('/BuscarServicio')
  }

  goMessages() {
    this.router.navigateByUrl('/messages')
  }

  StatusLogin() {
    if (localStorage.getItem('Status') === 'Login') {
      this.status = true
      if (localStorage.getItem('Servicio') === 'maid') {
        this.tipoUsuario = true
        this.url = 'assets/imgs/Home Maid Search.png'
      } else {
        this.tipoUsuario = false
        this.url = 'assets/imgs/limpieza.jpg'
      }
    } else {
      this.status = false
      if (localStorage.getItem('Servicio') === 'maid') {
        this.tipoUsuario = false
        this.url = 'assets/imgs/limpieza.jpg'
      } else {
        this.tipoUsuario = true
        this.url = 'assets/imgs/Home Maid Search.png'
      }
    }
  }

  getPeople(init = true) {
    this.loadingService.show()
    const userId = localStorage.getItem('id_Usuario')

    const query: string = this.isMaid
      ? `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', P.ninos as 'ninos',P.adultos as 'adultos',
      O.otra as 'another_city',P.mascotas as 'mascotas',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.dias as 'dias',
      E.name as 'state', M.name as 'municiple',
      TH.name as 'tipo_hogar',H.habitaciones as 'habitaciones',
      H.banos as 'banos',H.jardin as 'jardin',H.terraza as 'terraza',
      P.fecha_nacimiento as 'birthdate',P.conexion as'conexion', P.url_foto as 'imageURL'
			
      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN hogar H ON U.id = H.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
      JOIN tipo_hogar TH ON TH.id = H.tipo_hogar


      WHERE U.tipo_usuario = 2
      `
      : `
      SELECT
      U.id as 'user_id', P.nombre as 'name',
      P.apellido as 'lastname', O.otra as 'another_city',
      O.salario as 'salary', O.frecuencia as 'frecuence',
      O.L as 'L', O.Ma as 'Ma', O.Mi as 'Mi', O.J as 'J',
      O.V as 'V', O.S as 'S', O.D as 'D',Ex.experiencia_anos as 'experience',
      Ex.referencias as 'reference', E.name as 'state',
      M.name as 'municiple', TIMESTAMPDIFF(year, P.fecha_nacimiento, CURDATE()) as 'birthdate',
			P.url_foto as 'imageURL', A.limpieza as 'cleaning',P.conexion as'conexion',
      A.laba as 'washing', A.cocinar as 'cooking',
      A.ninos as 'children', A.adultos as 'adults'

      FROM usuarios U
      JOIN perfil P ON U.id = P.id_usuario
      JOIN oferta_laboral O ON U.id = O.id_usuario
      JOIN experiencia Ex ON U.id = Ex.id_usuario
      JOIN estados E ON E.id = P.estado
      JOIN municipios M ON M.id = P.municipio
			JOIN actividades A ON A.id_usuario = P.id_usuario
      WHERE U.tipo_usuario = 1
      `

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getPeople')
      .subscribe(async (res: any) => {
        const savesQuery = `
        SELECT * FROM guardados
        WHERE id_usuario = ${userId}
        `

        const savesRes = (await this.sqlGenericService
          .excecuteQueryStringReference(savesQuery, 'getSaves')
          .toPromise()) as any

        this.people = res.parameters
        this.people = this.people.map((person) => {
          let days = ''

          if (Number(person.L)) days += 'Lu '
          if (Number(person.Ma)) days += 'Ma '
          if (Number(person.Mi)) days += 'Mi '
          if (Number(person.J)) days += 'Ju '
          if (Number(person.V)) days += 'Vi '
          if (Number(person.S)) days += 'Sa '
          if (Number(person.D)) days += 'Do '
          days = days.trim()
          //console.log(days)

          return {
            ...person,
            age: this.calculateAge(person.birthdate),
            days,
            activo: this.calculateActivoPerson(person.conexion),
            saved: savesRes.parameters.some(
              (save) => save.id_guardado === person.user_id
            ),
          }
        })

        this.total = this.people.length

        setTimeout(() => {
          if (init) this.init()
          
        }, 1000)
      })
  }

  async getCurrentUser() {
    const userId = localStorage.getItem('id_Usuario')
    const query = `
		SELECT * FROM usuarios U
		JOIN perfil P ON U.id = P.id_usuario
		WHERE U.id = ${userId}
		`

    const [user] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(query, 'getUser')
        .toPromise()) as any
    )?.parameters

    this.userData = user
    this.AsOnlinePerson = this.calculateActivoPerson(this.userData.conexion)

    if (localStorage.getItem('token-muchachapp')) {
      console.log('Sin Token')
      let sqlFind: string = `UPDATE perfil SET 
      notificar_token='${localStorage
        .getItem('token-muchachapp')
        .replace(/['"]+/g, '')}'
          WHERE id_usuario = '${localStorage.getItem('id_Usuario')}'`
      this.sqlGenericService
        .excecuteQueryStringReference(sqlFind, `getperfil`)
        .subscribe(
          (rsp: any) => {},

          (error: HttpErrorResponse) => {
            if (error.status != 200) {
              this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
            }
          }
        )
    }
  }

  removeSession() {
    localStorage.removeItem('userSession')
    this.router.navigate(['/', 'BuscarServicio'])
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  toggleFilters() {
    this.isFiltersOpen = !this.isFiltersOpen
  }

  async setResults(results: any) {
    this.loadingService.show()
    if (results.length === 0) {
      this.alertService.errorAlert(
        'Sin resultados',
        'No se encontraron resultados para tu búsqueda'
      )
    } else {
      const userId = localStorage.getItem('id_Usuario')
      const savesQuery = `
        SELECT * FROM guardados
        WHERE id_usuario = ${userId}
        `

      const savesRes = (await this.sqlGenericService
        .excecuteQueryStringReference(savesQuery, 'getSaves')
        .toPromise()) as any

      this.people = results
      this.people =  this.people.map((person) => {
        let days = ''

        if (Number(person.L)) days += 'Lu '
        if (Number(person.Ma)) days += 'Ma '
        if (Number(person.Mi)) days += 'Mi '
        if (Number(person.J)) days += 'Ju '
        if (Number(person.V)) days += 'Vi '
        if (Number(person.S)) days += 'Sa '
        if (Number(person.D)) days += 'Do '
        days = days.trim()
        //console.log(days)

        return {
          ...person,
          age: this.calculateAge(person.birthdate),
          days,
          activo: this.calculateActivoPerson(person.conexion),
          saved: savesRes.parameters.some(
            (save) => save.id_guardado === person.user_id
          ),
        }
      })
      ///Test
     // this.people.length
   
      setTimeout(() => {
        this.reinit();
   
        // this.loadingService.hide()
       
   
 },5000);
    }
  }

  calculateAge(_birthdate: string): number {
    const today = new Date()
    const birthDate = new Date(_birthdate)
    return today.getFullYear() - birthDate.getFullYear()
  }

  calculateActivoPerson(_online: any) {
    
    const dateNow = new Date()
    const dateObject = new Date(_online)
   
    var fecha1 = moment(dateNow);
    var fecha2 = moment(dateObject);

    const diff= moment.duration(fecha1.diff(fecha2))
   // console.log((diff.hours()).toString())

    const d = dateNow.getDay() - dateObject.getDay()


    if (diff.days() > 1) {
      this.AsOnlinePerson = diff.days() + ' dias'
   //   console.log(this.AsOnlinePerson)
      return this.AsOnlinePerson
    }
    else
    return diff.hours() + ' Horas'
    
  
  }

  async savePerson(person: { user_id: number; saved: boolean }) {
    if (person.saved) {
      const query = `
      DELETE FROM guardados
      WHERE id_usuario = ${localStorage.getItem('id_Usuario')}
      AND id_guardado = ${person.user_id}
      `

      await this.sqlGenericService
        .excecuteQueryStringReference(query, 'deleteSave')
        .toPromise()

      this.alertService.successAlert(
        'Eliminado correctamente',
        'Se ha eliminado correctamente de tus guardados'
      )
    } else {
      const userId = localStorage.getItem('id_Usuario')
      const query = `
		INSERT INTO guardados (id_usuario, id_guardado) VALUES (${userId}, ${person.user_id})
		`
      await this.sqlGenericService
        .excecuteQueryStringReference(query, 'savePerson')
        .toPromise()

      this.alertService.successAlert(
        'Guardado correctamente',
        'Se ha añadido correctamente a tus guardados'
      )
    }

    this.getPeople(false)
  }

  async createChat(person: { user_id: number }) {
    const userId = localStorage.getItem('id_Usuario')

    console.log(userId)

    const subscriptionQuery = `
      SELECT * FROM subscriptions
      WHERE id_user = ${userId} AND
      status = 1
    `

    const subs = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(subscriptionQuery, 'checkSubscription')
        .toPromise()) as any
    ).parameters

    if (!subs.length && !Number(this.userData.test)) {
      return this.router.navigate(['subscription'])
    }

    const checkQuery = `
    SELECT * FROM chats
    WHERE (id_usuario_1 = ${userId} AND id_usuario_2 = ${person.user_id})
    OR (id_usuario_1 = ${person.user_id} AND id_usuario_2 = ${userId})
    `

    this.sqlGenericService
      .excecuteQueryStringReference(checkQuery, 'checkChat')
      .subscribe(async (res: any) => {
        if (res.parameters.length > 0) {
          console.log(!subs.length && !Number(this.userData.test))
          if (!subs.length && !Number(this.userData.test)) {
            window.sessionStorage.setItem('chat', JSON.stringify(person))
            return this.router.navigate(['subscription'])
          } else {
            return this.router.navigate(['chat', person.user_id])
          }
        }
        const createQuery = `
        INSERT INTO chats
        (id_usuario_1, id_usuario_2) VALUES (${userId}, ${person.user_id})
        `

        await this.sqlGenericService
          .excecuteQueryStringReference(createQuery, 'createChat')
          .toPromise()

        if (!subs.length && !Number(this.userData.test)) {
          window.sessionStorage.setItem('chat', JSON.stringify(person))
          return this.router.navigate(['subscription'])
        }

        this.router.navigate(['chat', person.user_id])
      })
  }

  async checkSubscription() {
    
    const userId = localStorage.getItem('id_Usuario')

    const subscriptionQuery = `
      SELECT * FROM subscriptions
      WHERE id_user = ${userId} AND
      status = 1
    `

    const [sub] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(subscriptionQuery, 'checkSubscription')
        .toPromise()) as any
    ).parameters
     
    if (!sub) {
      return (this.hasSubscription = false)
    }
    const startDate = new Date(`${sub.start_date}`)
    console.log(startDate);
    const endDate = new Date(
      startDate.setDate(startDate.getDate() + Number(sub.validity))
    )
    const today = new Date()

    if (today > endDate) {
      this.hasSubscription = false

      const updateQuery = `
        UPDATE subscriptions
        SET status = 0
        WHERE id = ${sub.id} 
      `

      await this.sqlGenericService
        .excecuteQueryStringReference(updateQuery, 'updateSubscription')
        .toPromise()
    } else {
      this.hasSubscription = true
    }
  }

  init() {
    setTimeout(() => {
      this.swiper.init();
      this.loadingService.hide()
    }, 1000);
   
  }



  reinit() {
    

    setTimeout(() => {
    
   
     
 
       
        this.swiper.destroy(true,true);
        this.swiper =new Swiper('.mySwiper', {
          virtual: {
          enabled: true,
          }}


        );
       // this.swiper.reinit()
       this.cd.detectChanges();
        this.loadingService.hide()
         
    }

    
    , 3000);
   
  }

  async conexion() {
    const d = new Date()
    d.toString()
    let sqlFind: string = `UPDATE perfil SET            
    conexion='${d}'
     WHERE id_usuario = '${localStorage.getItem('id_Usuario')}'`
    console.log(sqlFind)
    await this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, 'updateSubscription')
      .toPromise()
  }
}
