import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss'],
})
export class ActividadesComponent implements OnInit {
  public servcios: any;
  public suma:any={
    total:0,
    validar:false
  }
   public render: any = {
    inputLimpieza: {
    required: true,
    placeholder: 'Limpieza.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputLaba: {
    required: true,
    placeholder: 'Laba.....',
    value:0,
    error: false,
    type: 'text',
    length: 100
    },
    inputCocinar: {
    required: true,
    placeholder: 'Cocinar.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputNinos: {
    required: true,
    placeholder: 'Ninos.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    inputAdultos: {
    required: true,
    placeholder: 'Adultos.....',
    value: 0,
    error: false,
    type: 'text',
    length: 100
    },
    


    
   };
  
  constructor(
    private sqlGenericService:SqlGenericService,
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.servcios=localStorage.getItem("Servicio");

  }
  goInicio() {
    if (this.servcios === "maid"){
      this.sql();
      this.router.navigateByUrl('/main');

    }
    else{
      this.sql();
     this.router.navigateByUrl('/SigExperiencia');
    }




   
    
  }
  back() {
    window.history.back();
  }

  sql(){
    let sqlFind: string = 
    `INSERT INTO actividades 
    (id_usuario,limpieza,laba,cocinar,ninos,adultos)
    VALUES (
      '${localStorage.getItem('id_Usuario')}',
      '${this.render.inputLimpieza.value}',
      '${this.render.inputLaba.value}',
      '${this.render.inputCocinar.value}',
      '${this.render.inputNinos.value}',
      '${this.render.inputAdultos.value}'
    )`;
    
    this.sqlGenericService.excecuteQueryStringReference(sqlFind,`getTabla`).subscribe((rsp: any) => {
    
    
    //console.log(rsp.parameters[0].id );
    },
    
    (error: HttpErrorResponse) => {
    if (error.status != 200) {
    this.alertService.errorAlert('Opps....', 'Tenemos un Problema');
    
    }
    })
  }


  datos(event:any,ngmodel){


    if(event.target.checked==true && ngmodel==1){
     this.render.inputLimpieza.value=1
     this.suma.total = this.suma.total+1
    }
    if(event.target.checked==false && ngmodel==1){
     this.render.inputLimpieza.value=0
     this.suma.total = this.suma.total-1
    }
 
 
    if(event.target.checked==true && ngmodel==2){
     this.render.inputLaba.value=1
     this.suma.total = this.suma.total+1
    }
    if(event.target.checked==false && ngmodel==2){
     this.render.inputLaba.value=0
     this.suma.total = this.suma.total-1
  
    }



    if(event.target.checked==true && ngmodel==3){
      this.render.inputCocinar.value=1
      this.suma.total= this.suma.total+1
     }
     if(event.target.checked==false && ngmodel==3){
      this.render.inputCocinar.value=0
      this.suma.total = this.suma.total-1
     }


     if(event.target.checked==true && ngmodel==4){
      this.render.inputNinos.value=1
      this.suma.total = this.suma.total+1
     
   
     }
     if(event.target.checked==false && ngmodel==4){
      this.render.inputNinos.value=0
      this.suma.total = this.suma.total-1
    
    
     }

     if(event.target.checked==true && ngmodel==5){
      this.render.inputAdultos.value=1
      this.suma.total= this.suma.total+1
      
  
      
     }
     if(event.target.checked==false && ngmodel==5){
      this.render.inputAdultos.value=0
      this.suma.total= this.suma.total-1
     }
     console.log(this.suma.total)
     console.log(event.target.checked,ngmodel,this.suma.total)
     
      // if(this.suma.total===1)
      //  this.suma.validar=!this.suma.total
      if(this.suma.total>=0&&this.suma.total<=1)
      this.suma.validar=!!this.suma.total


      if(this.suma.total<=0&&this.suma.total>=1)
      this.suma.validar=!this.suma.total

     

   }


   onChange(field: string, value: string) {
    this[field] = value
   
    if (field === 'Limpieza') {
      this.render.inputNombre.error = !!value
    }
   
  }

}
