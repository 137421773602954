import { remastered } from './../../../../environments/environment.prod';
import { LocalStorageEncryptService } from './../../../services/local-storage-encrypt.service';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { Router } from '@angular/router';
import { ThemeService } from '../../../services/theme.service';
import { idEmpresa } from '../../../../environments/environment.prod';
import { SqlGenericService } from '../../../services/sqlGenericService';
import { HttpErrorResponse } from '@angular/common/http';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
/* import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual, Zoom, Autoplay, Thumbs, Controller
} from 'swiper/core';
 */

@Component({
  selector: 'app-tab1',
  templateUrl: 'tab1.page.html',
  styleUrls: ['tab1.page.scss']
})
export class Tab1Page implements OnInit, OnDestroy {

  @Input() data: any;

  public config = {
    loop: true,
    initialSlide: 0, //this one accept a number according to docs
    slidesPerView: 1, //number or 'auto'
    slidesPerColumn: 1, //number

    slidesPerGroup: 1,
    spaceBetween: 15,
    preloadImages: false,
    navigation: {
      nextEl: '.btn-right',
      prevEl: '.btn-left',
    },
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 50
      },
      350: {
        slidesPerView: 1.1,
        spaceBetween: 50
      },
      450: {
        slidesPerView: 1.2,
        spaceBetween: 50
      },
      550: {
        slidesPerView: 1.5,
        spaceBetween: 50
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      740: {
        slidesPerView: 2.2,
        spaceBetween: 40
      },
      840: {
        slidesPerView: 2.5,
        spaceBetween: 40
      },
      980: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1020: {
        slidesPerView: 3.2,
        spaceBetween: 40
      },
      1200: {
        slidesPerView: 3.4,
        spaceBetween: 40
      },
    }
  };

  public temas: any[] = [];

  public categorias: any[] = [];

  public suscription: Subscription;

  public imgUrl: any = environment.getImagenIndividual;

  public temaGlobal: any = 0;

  public sus: Subscription = null;

  constructor(
    private sqlGenericService: SqlGenericService,
    private themeService: ThemeService,
    private router: Router,
    private eventService: EventService,
    private alertService: AlertService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private menu: MenuController,
  ) {
  }

  ngOnInit() {
    console.log("ejemplo");
    

  }

  ngOnDestroy() {
    this.sus.unsubscribe();
  }

  select() {
    this.localStorageEncryptService.setToLocalStorage("temaGlobal", this.temaGlobal);
  }

  onSwiper(swiper) {
    //console.log(swiper);
  }

  onSlideChange() {
    //console.log('slide change');
  }
}
